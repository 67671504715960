import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	createTheme,
	IconButton,
	InputAdornment,
	ThemeProvider,
	TextField,
	Checkbox,
	withStyles,
	Tooltip,
	makeStyles,
} from '@material-ui/core';
import {
	AutocompleteInput as ReactAutocompleteInput,
	ReferenceInput,
	setSidebarVisibility,
} from 'react-admin';
import {
	insightViewStyle,
	modalFormStyle,
	ellipsisStyle,
} from '../../../Layout/styles';
import {
	customAutoCompleteSearchBarTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { Form } from 'react-final-form';

import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import dayjs from 'dayjs';
import { useGetProjectListQuery } from '../../../generated/graphql';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { START_DATE_ERROR } from '../constant';
import { useDispatch } from 'react-redux';
import { UserProfileContext } from '../../../App';
import { autocompleteMultiSelectLabelStyle } from '../style';
import { TimesheetSummaryReport } from './TimesheetSummaryReport.component';
import {
	autoCompleteFilterOption,
	formatDecimal,
	isFloat,
} from '../../../Utils/string.util';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '70%',
				height: '20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});
const useStyles = makeStyles((theme) => ({
	reducedValueContainer: {
		width: '95px',
		height: '48px',
		background: '#F7F9FA',
		borderRadius: '4px',
		padding: '6px 0px 6px 6px',
		marginRight: '16px',
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
}));
interface Hours {
	totalHours: any;
	billableHours: any;
	nonBillableHours: any;
}
interface Option {
	id: string;
	name: string;
}
interface TimeSheet {
	id: string;
	working_hours: string;
	is_billable: boolean;
}
interface FormValues {
	start_date: string;
	end_date: string;
}

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);
const CountCard = ({
	label,
	value,
}: {
	label: string;
	value: number | string;
}) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.reducedValueContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};

export const TimesheetSummaryReportContainer = () => {
	const [projectId, setProjectId] = useState<string[]>([]);
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const [totalHours, setTotalHours] = useState<Hours>();
	const [companyId, setCompanyId] = useState<string>();
	const classes = insightViewStyle();
	const dispatch = useDispatch();
	const style = useStyles();

	const { data: projectList } = useGetProjectListQuery({
		variables: {
			filters: {
				company_id: companyId ? { _eq: companyId } : {},
			},
		},
	});

	const styles = modalFormStyle();
	const ellipsisstyle = ellipsisStyle();
	const inlinestyle = autocompleteMultiSelectLabelStyle();
	const [startDateError, setStartDateError] = useState(false);
	const [project, setProject] = useState<any>([]);

	const [selectedProjectOptionValue, setSelectedProjectOptionValue] =
		useState<(any | null)[]>();
	const { dateFormat } = useContext<any>(UserProfileContext);

	const allSelected =
		project.length === selectedProjectOptionValue?.length || 0 ? true : false;
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	const handleClearOptions = () => {
		setSelectedProjectOptionValue([]);
		setProjectId([]);
	};

	const handleToggleOption = (selectedOptions: any) => {
		const selectedProjectIds = selectedOptions.map(
			(project: any) => project?.value || ''
		);
		setProjectId(selectedProjectIds);
		setSelectedProjectOptionValue(selectedOptions);
	};

	const handleSelectAll = (isSelected: boolean) => {
		if (!projectList) {
			return;
		}
		if (isSelected) {
			if (companyId) {
				const record = [...projectList?.project_list];
				const selectedProjectIds = record.map(
					(project) => project?.value || ''
				);
				setProjectId(selectedProjectIds);
				setSelectedProjectOptionValue(record);
				return;
			}
			const record = [...projectList?.project_list, ...projectList?.org_tasks];
			const selectedProjectIds = record.map((project) => project?.value || '');
			setProjectId(selectedProjectIds);
			setSelectedProjectOptionValue(record);
		} else {
			setProjectId([]);
			handleClearOptions();
		}
	};

	const handleToggleSelectAll = () => {
		handleSelectAll && handleSelectAll(!allSelected);
	};

	const onProjectChange = (event: any, value: (any | null)[], reason: any) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option) => option.value === 'All')) {
				handleToggleSelectAll();
				return;
			} else {
				handleToggleOption && handleToggleOption(value);
				return;
			}
		} else if (reason === 'clear') {
			handleClearOptions && handleClearOptions();
			return;
		}
	};

	useEffect(() => {
		if (!projectList) {
			return;
		}
		if (!companyId) {
			const record = [...projectList?.project_list, ...projectList?.org_tasks];
			setProject(record);
			return;
		}
		const record = [...projectList?.project_list];
		setProject(record);
	}, [projectList, companyId]);

	const getLimits = (value: Number) => {
		return (
			<div className={`${style.dropDownValue} ${ellipsisstyle.ellipsis}`}>
				{selectedProjectOptionValue && selectedProjectOptionValue?.length === 1
					? selectedProjectOptionValue[0].label
					: `Selected ${value} items`}
			</div>
		);
	};

	return (
		<div>
			<Box className={classes.reducedCountContainer}>
				<Box className={classes.countContainerWithoutGrey}>
					<Box display='flex'>
						<Box
							display='block'
							marginTop='-15px'
							marginRight='15px'
							width='max-content'
						>
							<Box className={styles.label}>Search Customer</Box>
							<Box marginTop={'4px'}>
								<Form onSubmit={() => {}}>
									{() => (
										<ThemeProvider theme={modalFormTheme}>
											<ReferenceInput
												source='crm_company'
												label=''
												reference='crm_company'
												sort={{ field: 'name', order: 'ASC' }}
												filterToQuery={(searchText: any) => ({
													name: searchText,
												})}
												filter={{
													name: true,
												}}
												onChange={(event) => {
													if (!event) {
														setCompanyId('');
														setProjectId([]);
														setSelectedProjectOptionValue([]);
														return;
													}
													if (selectedProjectOptionValue) {
														setProjectId([]);
														setSelectedProjectOptionValue([]);
														setCompanyId(event || null);
														return;
													}
													setCompanyId(event || null);
												}}
											>
												<ReactAutocompleteInput
													resettable={true}
													optionText={(name: any) => `${name?.name}`}
													shouldRenderSuggestions={(name: string) =>
														name.trim().length > 0
													}
												/>
											</ReferenceInput>
										</ThemeProvider>
									)}
								</Form>
							</Box>
						</Box>
						<Box display='block' marginTop='-15px'>
							<Box className={styles.label}>Search Project</Box>
							<Autocomplete
								multiple
								placeholder='Select project'
								limitTags={0}
								fullWidth={false}
								selectOnFocus={true}
								onChange={onProjectChange}
								options={project?.length > 0 ? project : []}
								value={
									selectedProjectOptionValue &&
									selectedProjectOptionValue?.length > 0
										? selectedProjectOptionValue
										: []
								}
								style={{ width: 210, marginTop: '4px' }}
								ChipProps={{ style: { display: 'none' } }}
								disableCloseOnSelect={true}
								getLimitTagsText={getLimits}
								filterOptions={(options, params) => {
									const filtered = autoCompleteFilterOption(options, params);
									return [{ label: 'All', value: 'All' }, ...filtered];
								}}
								classes={{
									tag: inlinestyle.label,
								}}
								renderOption={(option: any | null, { selected }) => {
									const selectAllProps =
										option.value === 'All' // To control the state of 'select-all' checkbox
											? { checked: allSelected }
											: {};
									return (
										<>
											<Checkbox
												icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
												checkedIcon={<CheckBoxIcon fontSize='small' />}
												style={{ marginRight: 8 }}
												checked={selected || false}
												color='primary'
												{...selectAllProps}
											/>
											<Box
												style={{ fontSize: '12px' }}
											>{`${option?.label}`}</Box>
										</>
									);
								}}
								getOptionLabel={(option: any | null) => `${option?.label}`}
								renderInput={(params) => (
									<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
										<TextField {...params} label='' />
									</ThemeProvider>
								)}
							/>
						</Box>
						<ThemeProvider theme={dateTimePickerTheme}>
							<Box display='flex'>
								<ErrorTooltip
									title={startDateError ? START_DATE_ERROR : ''}
									placement='top'
									arrow={true}
								>
									<Box width='188px' marginLeft='20px'>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												variant='inline'
												onChange={(date: any) => {
													setStartDate(date);
													if (dayjs(date).isAfter(dayjs(endDate))) {
														setStartDateError(true);
													} else {
														setStartDateError(false);
													}
												}}
												autoOk={true}
												value={startDate}
												format={dateFormat}
												label='Start Date'
												InputLabelProps={{ shrink: true }}
												InputProps={{
													endAdornment: (
														<InputAdornment position='start'>
															<IconButton>
																<EventIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
												error={startDateError}
											/>
										</MuiPickersUtilsProvider>
									</Box>
								</ErrorTooltip>
								<Box width='188px'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											variant='inline'
											onChange={(date: any) => {
												setEndDate(date);
												if (dayjs(startDate).isAfter(dayjs(date))) {
													setStartDateError(true);
												} else {
													setStartDateError(false);
												}
											}}
											autoOk={true}
											value={endDate}
											format={dateFormat}
											label='End Date'
											InputLabelProps={{ shrink: true }}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton>
															<EventIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</Box>
						</ThemeProvider>
					</Box>
				</Box>
				<Box display='flex'>
					<CountCard
						label='Total(hrs)'
						value={
							projectId && totalHours?.totalHours
								? isFloat(Number(totalHours?.totalHours))
									? formatDecimal(Number(totalHours?.totalHours), 1)
									: totalHours?.totalHours
								: '--'
						}
					/>
					<CountCard
						label='Billable(hrs)'
						value={
							projectId && totalHours?.billableHours
								? isFloat(Number(totalHours?.billableHours))
									? formatDecimal(Number(totalHours?.billableHours), 1)
									: totalHours?.billableHours
								: '--'
						}
					/>
					<div>
						<Box className={style.reducedValueContainer}>
							<Box className={classes.countLabel}>{'Non-billable(hrs)'}</Box>
							<Box className={classes.countValue}>
								{projectId && totalHours?.nonBillableHours
									? isFloat(Number(totalHours?.nonBillableHours))
										? formatDecimal(Number(totalHours?.nonBillableHours), 1)
										: totalHours?.nonBillableHours
									: '--'}
							</Box>
						</Box>
					</div>
				</Box>
			</Box>
			{projectId.length > 0 && startDate && endDate && (
				<Box className={classes.largeContainer}>
					<TimesheetSummaryReport
						projectId={projectId}
						startDate={startDate}
						endDate={endDate}
						setTotalHours={(value) => setTotalHours(value)}
					/>
				</Box>
			)}
		</div>
	);
};

export default TimesheetSummaryReportContainer;
