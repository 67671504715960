import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { modalFormTheme } from '../../Layout/Theme.component';
import { modalFormStyle } from '../../Layout/styles';
import { uniq } from 'lodash';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '558px',
		height: '359px',
		margin: '21px',
	},
	contentContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '100%',
		height: '75%',
	},
	confirmationContext: {
		fontFamily: 'Manrope-medium',
		fontSize: '20px !important',
		color: '#292929',
		marginBottom: '15px',
	},
	confirmationMessageText: {
		fontFamily: 'Manrope-medium',
		fontSize: '14px !important',
		color: '#5C5C5C',
	},
	submitButton: {
		width: '126px',
		height: '36px',
		background: '#3FA543 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #3FA54340',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#3FA543',
		},
	},
	rejectButton: {
		background: '#ED3D3D',
		fontFamily: 'Manrope-bold',
		color: '#FFFFFF',
		fontSize: '12px',
		textTransform: 'none',
		width: '126px',
		height: '36px',
		'&:hover': {
			background: '#ED3D3D',
		},
	},
}));

interface Props {
	open: boolean;
	leaveSubmittedId: string[];
	message: string;
	submit: () => void;
	onClose: () => void;
	isApproveModalOpen?: boolean;
	loader: boolean;
}

const ApproveRejectModal = (props: Props) => {
	const {
		open,
		onClose,
		submit,
		leaveSubmittedId,
		message,
		isApproveModalOpen,
		loader = false,
	} = props;
	const leaveId = uniq(leaveSubmittedId);
	const formStyles = modalFormStyle();
	const approveRejectStyle = useStyles();
	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				disableBackdropClick
				open={open}
				onClose={onClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
			>
				<Box className={approveRejectStyle.container}>
					<Box className={approveRejectStyle.contentContainer}>
						<Typography className={approveRejectStyle.confirmationContext}>
							Are you sure you want to proceed?
						</Typography>
						<Typography className={approveRejectStyle.confirmationMessageText}>
							{`${message} ${leaveId?.length} item(s)`}
						</Typography>
					</Box>
					<Box display={'flex'} justifyContent='flex-end'>
						<Button
							variant='contained'
							className={formStyles?.cancelButton}
							id='button_cancel'
							onClick={onClose}
						>
							Cancel
						</Button>
						<Box marginLeft='20px'>
							<Button
								variant='contained'
								id='button_confirm'
								disabled={loader}
								className={
									isApproveModalOpen
										? approveRejectStyle.submitButton
										: approveRejectStyle?.rejectButton
								}
								onClick={submit}
								startIcon={
									loader && (
										<CircularProgress
											style={{
												height: '20px',
												width: '20px',
												color: '#FFFFFF',
											}}
										/>
									)
								}
							>
								{isApproveModalOpen ? 'Approve' : 'Reject'}
							</Button>
						</Box>
					</Box>
				</Box>
			</Dialog>
		</ThemeProvider>
	);
};

export default ApproveRejectModal;
