import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Loading } from 'react-admin';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import {
	useGetAppraisalDetailsQuery,
	useGetTeamAppraisalUsersDetailsQuery,
} from '../../../generated/graphql';
import { APPRAISAL_CYCLE_PATH, PMS_DATE_FORMAT } from '../constant';
import { appraisalDetailsStyle, pmsDatagridStyle } from '../pms.styles';

import dayjs from 'dayjs';
import {
	Box,
	ThemeProvider,
	TextField as TextComponent,
	Button,
	Tooltip
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { DataGridTheme } from '../pmsTheme';
import { searchBarTheme } from '../../../Layout/Theme.component';
import { SearchIcon } from '../../../assets/SvgIcons';
import Loader from '../SharedComponents/Loader.component';
import { viewGoalsInitialState } from '../constant';
import PMSBreadcrumb from '../SharedComponents/PMSBreadcrumb.component';
import GoalMappingForm from '../SharedComponents/GoalMappingForm.component';
import { head } from 'lodash';

import { getExperience } from '../../../Utils/date-time.util';
import ViewGoals from '../SharedComponents/ViewGoals.component';
import { UserProfileContext } from '../../../App';
import { ViewGoalsDetails } from '../pms.module';
import { modalFormStyle } from '../../../Layout/styles';

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Employees'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

interface AppraisalUserList {
	id: any;
	name: string;
	email: string;
	reviewer: string;
	review_status: string;
	userId: any;
	assignedGoalIds: string[];
	allocated_weight: number;
}

function TeamPMSAppraisalDetails() {
	const { dateFormat, id: userId } = useContext<any>(UserProfileContext);
	const { appraisalCycleId }: { appraisalCycleId: string } = useParams();
	const appraisalDetailsStyles = appraisalDetailsStyle();
	const dataGridStyles = pmsDatagridStyle();
	const formStyles = modalFormStyle();
	const history = useHistory();

	// States
	const [searchedText, setSearchedText] = useState<string>('');
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [page, setPage] = useState<number>(0);
	const [data, setData] = useState<AppraisalUserList[]>([]);
	const [selectedRowData, setSelectedRowData] = useState<AppraisalUserList[]>(
		[]
	);
	const [selectedRowIndexes, setSelectedRowIndexes] = useState<Number[]>([]);
	const [isGoalMappingFormShown, setIsGoalMappingFormShown] = useState(false);
	const [selectedViewGoals, setSelectedViewGoals] = useState<ViewGoalsDetails>(
		viewGoalsInitialState
	);
	const [isViewGoalsModalShown, setIsViewGoalsModalShown] = useState(false);

	// API CALLS
	const {
		data: appraisalDetail,
		loading: isAppraisalDetailLoading,
		refetch: refetchAppraisalDetails,
	} = useGetAppraisalDetailsQuery({
		variables: {
			appraisalCycleId: appraisalCycleId,
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: appraisalUsers,
		loading: isUserListLoading,
		refetch: refetchAppraisalUsers,
	} = useGetTeamAppraisalUsersDetailsQuery({
		variables: {
			appraisalCycleId: appraisalCycleId,
			searchText: `%${searchedText}%`,
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			userId: userId,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!appraisalUsers) return;
		const userData = appraisalUsers.pms_pms_appraisal_user_mapping.map(
			(pmsUserMapping) => {
				return {
					id: pmsUserMapping.id,
					name: pmsUserMapping.user.full_name || '- -',
					email: pmsUserMapping.user.email,
					reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
					review_status: pmsUserMapping.pms_user_appraisal_status.label,
					userId: pmsUserMapping.user.id,
					assignedGoalIds:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.map((user) => user.goal_id),
					allocated_weight:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.reduce((sum, value) => {
								return value.weightage + sum;
							}, 0) || 0,
					goals: {
						goals:
							pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							),
						user: {
							user: pmsUserMapping.user,
							reviewer: pmsUserMapping.userByReviewer,
							userAppraisalStatus:
								pmsUserMapping.pms_user_appraisal_status.value,
						},
					},
					data: {
						id: pmsUserMapping.id,
						name: pmsUserMapping.user.full_name || '- -',
						email: pmsUserMapping.user.email,
						reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
						review_status: pmsUserMapping.pms_user_appraisal_status.label,
					},
				};
			}
		);
		setData(userData);
	}, [appraisalUsers]);

	const columns = [
		{
			name: 'data',
			label: 'data',
			options: {
				filter: true,
				viewColumns: false,
				display: false,
			},
		},
		{ name: 'name', label: 'NAME',options: {
			customBodyRender: (
				value: any,
				tableMeta: any,
				updateValue: any
			) => {
				return (
					<Tooltip title={value}>
						<Box
							className={`${dataGridStyles.text} ${formStyles.ellipsis}`}
						>
							{value || '- -'}
						</Box>
					</Tooltip>
				);
			},
		}, },
		{ name: 'email', label: 'EMAIL',options: {
			customBodyRender: (
				value: any,
				tableMeta: any,
				updateValue: any
			) => {
				return (
					<Tooltip title={value}>
						<Box
							className={`${dataGridStyles.text} ${formStyles.ellipsis}`}
						>
							{value || '- -'}
						</Box>
					</Tooltip>
				);
			},
		}, },
		{
			name: 'reviewer',
			label: 'REVIEWER',
			options: {
				customBodyRender: (
					value: any,
					tableMeta: any,
					updateValue: any
				) => {
					return (
						<Tooltip title={value}>
							<Box
								className={`${dataGridStyles.text} ${formStyles.ellipsis}`}
							>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			}
		},
		{
			name: 'review_status',
			label: 'REVIEW STATUS',
			options: {
				customBodyRender: (
					value: any,
					tableMeta: any,
					updateValue: any
				) => {
					return (
						<Tooltip title={value}>
							<Box
								className={`${dataGridStyles.text} ${formStyles.ellipsis}`}
							>
								{value || '- -'}
							</Box>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'goals',
			label: 'GOALS',
			options: {
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const joinDate =
						value.user?.user.user_type === 'employee'
							? value.user?.user?.employee?.join_date
							: value.user?.user.contractors[0].join_date;

					const priorExperience =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.prior_experience
							: '00:00:00';

					const jobLevel =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.job_level?.level || '- -'
							: value.user?.user.contractors[0]?.job_level?.level || '- -';

					const department =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.department?.name || '- -'
							: value.user?.user.contractors[0]?.department?.name || '- -';

					const status =
						value.user?.user.user_type === 'employee'
							? value.user?.user.employee?.employee_status?.label || '- -'
							: value.user?.user.contractors[0]?.contractorStatus?.label ||
							  '- -';

					const userDetails = {
						userName: value.user?.user.full_name || '- -',
						experience: joinDate
							? getExperience({
									join_date: joinDate,
									prior_experience: priorExperience,
							  }).totalExp
							: '0',
						reviewer: value.user.reviewer?.full_name || '- -',
						jobLevel: jobLevel || '- -',
						department: department || '- -',
						status: status || '- -',
						profilePic: value.user.user?.profile_pic || '',
						userAppraisalStatus: value.user.userAppraisalStatus || '',
					};

					const goalsDetails = value.goals.map((goal: any) => {
						return {
							id: goal.id,
							name: goal.pms_master_goal.name || '- -',
							description: goal.pms_master_goal.description || '- -',
							weightage: goal.weightage,
							startDate: dayjs(goal.start_date).format(dateFormat),
							endDate: dayjs(goal.end_date).format(dateFormat),
						};
					});

					return (
						<p
							className={dataGridStyles.link}
							onClick={() => {
								setSelectedViewGoals({
									userDetails: userDetails,
									goals: goalsDetails,
								});

								setIsViewGoalsModalShown(true);
							}}
						>{`View Goals(${value.goals.length})`}</p>
					);
				},
			},
		},
		{
			name: 'allocated_weight',
			label: 'ALLOCATED WEIGHT',
			options: {
				sort: false,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <p>{`${value}%`}</p>;
				},
			},
		},
	];

	const options: MUIDataTableOptions = {
		filter: false,
		search: false,
		selectableRowsHeader: true,
		selectableRowsHideCheckboxes: false,
		rowsSelected: selectedRowIndexes,
		print: false,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [5, 10, 20, 30],
		download: false,
		page: page,
		serverSide: true,
		viewColumns: false,
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},
		count: appraisalUsers?.usersCount.aggregate?.count || 0,
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			// tableState.data.length === 0 ? setPage(0) : setPage(tableState.page);
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
		onRowClick: (row: any, rowMeta) => {
			history.push(
				`/team-pms/${appraisalCycleId}/${data[rowMeta.dataIndex].userId}`
			);
		},
		onRowSelectionChange: (
			currentRowsSelected: any,
			allRowsSelected: any,
			rowsSelected: any
		) => {
			handleRowSelect(currentRowsSelected, allRowsSelected, rowsSelected);
		},
	};

	const handleRowSelect = (
		currentRowsSelected: any,
		allRowsSelected: any,
		rowsSelectedData: any
	) => {
		const selected = allRowsSelected.map((item: any) => item.index);
		setSelectedRowIndexes(selected);
		const displayData = getUsers();
		if (displayData) {
			let selectedRows = rowsSelectedData.map((dataIndex: any) => {
				return displayData[dataIndex];
			});

			setSelectedRowData(selectedRows);
		}
	};

	const getUsers = () => {
		if (!appraisalUsers) return;
		const userData = appraisalUsers.pms_pms_appraisal_user_mapping.map(
			(pmsUserMapping) => {
				return {
					id: pmsUserMapping.id,
					name: pmsUserMapping.user.full_name || '- -',
					email: pmsUserMapping.user.email,
					reviewer: pmsUserMapping.userByReviewer?.full_name || '- -',
					review_status: pmsUserMapping.pms_user_appraisal_status.label,
					userId: pmsUserMapping.user.id,
					assignedGoalIds:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.map((user) => user.goal_id),
					allocated_weight:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings
							.filter(
								(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
							)
							.reduce((sum, value) => {
								return value.weightage + sum;
							}, 0) || 0,
					goals:
						pmsUserMapping.pms_appraisal_cycle.pms_appraisal_goal_mappings.filter(
							(goalMapping) => goalMapping.user_id === pmsUserMapping.user.id
						).length || 0,
				};
			}
		);
		return userData;
	};

	function customToolbar() {
		return (
			<div className={dataGridStyles.toolbarContainer}>
				<>
					<p>{selectedRowIndexes.length} Row(s) selected</p>
				</>
				<Box display={'flex'} alignItems={'center'}>
					<Button
						className={dataGridStyles.buttonSm}
						onClick={() => {
							setIsGoalMappingFormShown(true);
						}}
					>
						Add Goals
					</Button>
				</Box>
			</div>
		);
	}

	return (
		<>
			<PMSBreadcrumb
				data={[
					{ ...APPRAISAL_CYCLE_PATH },
					{
						name:
							appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.name ||
							'Appraisal Cycle Detail',
					},
				]}
				icon={DoubleArrowIcon}
			/>

			<>
				{isAppraisalDetailLoading ? (
					<Loading />
				) : (
					<div className={appraisalDetailsStyles.root}>
						{/* Header */}
						<div className={appraisalDetailsStyles.headerContainer}>
							<div>
								<p className={appraisalDetailsStyles.header}>
									{appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.name ||
										'- -'}
								</p>
							</div>
						</div>
						<div className={appraisalDetailsStyles.headerContainer}>
							<div
								className={appraisalDetailsStyles.flex}
								style={{
									width: '100%',
									justifyContent: 'space-evenly',
								}}
							>
								<div>
									<p className={appraisalDetailsStyles.headerLabel}>Period</p>
									<p className={appraisalDetailsStyles.headerValue}>{`${dayjs(
										appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.start_date
									).format(PMS_DATE_FORMAT)} - ${dayjs(
										appraisalDetail?.pms_pms_appraisal_cycle_by_pk?.end_date
									).format(PMS_DATE_FORMAT)}`}</p>
								</div>
								<div>
									<p className={appraisalDetailsStyles.headerLabel}>Status</p>
									<p className={appraisalDetailsStyles.headerValue}>
										{appraisalDetail?.pms_pms_appraisal_cycle_by_pk
											?.pms_appraisal_status.label || '- -'}
									</p>
								</div>
								<div>
									<p className={appraisalDetailsStyles.headerLabel}>
										No.Of Employees
									</p>
									<p className={appraisalDetailsStyles.headerValue}>
										{appraisalUsers?.usersCount.aggregate?.count || 0}
									</p>
								</div>
							</div>
						</div>

						{/* List */}
						<div className={appraisalDetailsStyles.listContainer}>
							<p className={appraisalDetailsStyles.listHeading}>Employees</p>
							<Box
								display='flex'
								justifyContent='space-between'
								marginBottom='20px'
								marginTop='20px'
							>
								<Box display='flex' alignItems='center' sx={{ gridGap: '8px' }}>
									<Box>
										<CustomSearchRender
											searchText={searchedText}
											setSearchedText={setSearchedText}
										/>
									</Box>
									{isUserListLoading && (
										<Box>
											<Loader />
										</Box>
									)}
								</Box>

								<Box display='flex' sx={{ gridGap: '8px' }}>
									<Box>
										<Button
											className={dataGridStyles.exportButton}
											endIcon={<ArrowUpwardIcon />}
										>
											Export
										</Button>
									</Box>
								</Box>
							</Box>
							<>{selectedRowData.length > 0 && customToolbar()}</>
							<ThemeProvider theme={DataGridTheme}>
								<MUIDataTable
									title='Appraisal Users'
									data={data}
									columns={columns}
									options={options}
								/>
							</ThemeProvider>
						</div>
						<GoalMappingForm
							open={isGoalMappingFormShown}
							onClose={() => {
								setIsGoalMappingFormShown(false);
							}}
							onSuccess={() => {
								setSearchedText('');
								setSelectedRowIndexes([]);
								setSelectedRowData([]);
								refetchAppraisalDetails();
								refetchAppraisalUsers();
								setIsGoalMappingFormShown(false);
							}}
							selectedUserIds={selectedRowData.map((usr) => usr.userId)}
							appraisalCycleId={appraisalCycleId}
							assignedGoalIds={selectedRowData
								.map((row) => row.assignedGoalIds)
								.flat()}
							allocatedGoalWeights={selectedRowData
								.map((row) => row.allocated_weight)
								.flat()}
							initialGoalStatus={head(appraisalUsers?.goalInitialStatus)?.id}
						/>
						<ViewGoals
							open={isViewGoalsModalShown}
							onClose={() => setIsViewGoalsModalShown(false)}
							userDetails={selectedViewGoals.userDetails}
							goals={selectedViewGoals.goals || []}
							refetch={() => {
								refetchAppraisalUsers();
							}}
							disabled={false}
						/>
					</div>
				)}
			</>
		</>
	);
}

export default TeamPMSAppraisalDetails;
