import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	ThemeProvider,
	TextField as TextComponent,
	makeStyles,
	Tooltip
} from '@material-ui/core';
import { SearchIcon } from '../../../assets/SvgIcons';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import { DataGridTheme } from '../pmsTheme';
import { searchBarTheme } from '../../../Layout/Theme.component';
import NoOfUsersIndicator from '../SharedComponents/NoOfUsersIndicator.component';
import { useGetTeamsAppraisalCyclesQuery } from '../../../generated/graphql';
import dayjs from 'dayjs';
import { AppraisalRow } from '../pms.module';
import Loader from '../SharedComponents/Loader.component';
import { useHistory } from 'react-router-dom';
import { UserProfileContext } from '../../../App';
import { pmsDatagridStyle } from '../pms.styles';
import { PMS_DATE_FORMAT } from '../constant';
import { getEndDate } from '../pms.utils';
import { modalFormStyle } from '../../../Layout/styles';


const dataGridStyle = makeStyles(() => ({
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	statusChip: {
		width: '150px',
		padding: '10px',
		background: '#ebf3ff',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		cursor: 'pointer',
	},
	statusLabel: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	text: {
		fontFamily: 'Manrope-medium',
		fontSize: '11px',
		cursor: 'pointer',
		fontWeight: 400,
	},
}));

const CustomSearchRender = (props: any) => {
	const { searchText, setSearchedText } = props;
	return (
		<ThemeProvider theme={searchBarTheme}>
			<Box width='344px'>
				<TextComponent
					placeholder='Search Cycles'
					label={false}
					fullWidth
					InputLabelProps={{ style: { fontSize: 0 } }}
					InputProps={{
						startAdornment: <SearchIcon />,
					}}
					onChange={(event) => {
						setSearchedText(event?.target?.value);
					}}
					value={searchText || ''}
				/>
			</Box>
		</ThemeProvider>
	);
};

function TeamsAppraisalCycleList() {
	const { id: userId } = useContext<any>(UserProfileContext);
	const [searchedText, setSearchedText] = useState<string>('');
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);
	const [appraisalRows, setAppraisalRows] = useState<AppraisalRow[]>([]);
	const [page, setPage] = useState<number>(0);

	const history = useHistory();
	const formStyles = modalFormStyle();
	const dataGridStyles = dataGridStyle();
	const listStyles = pmsDatagridStyle();

	// API CALLS
	const { data: appraisalCycles, loading: isAppraisalLoading } =
		useGetTeamsAppraisalCyclesQuery({
			variables: {
				limit: rowsPerPage,
				offset: rowsPerPage * page,
				name: `%${searchedText}%`,
				userId: userId,
			},
			fetchPolicy: 'network-only',
		});

	useEffect(() => {
		if (!appraisalCycles) return;
		const data = appraisalCycles.pms_pms_appraisal_cycle.map(
			(appraisalCycle) => {
				const {
					enabled_modules,
					pms_appraisal_rating_scales,
					pms_appraisal_user_mappings_aggregate,
					pms_appraisal_status,
					...restAppraisalValues
				} = appraisalCycle;
				return {
					id: appraisalCycle.id,
					name: appraisalCycle.name,
					period: `${dayjs(appraisalCycle.start_date).format(
						PMS_DATE_FORMAT
					)} - ${dayjs(appraisalCycle.end_date).format(PMS_DATE_FORMAT)}`,
					reviewEndDate: getEndDate(appraisalCycle.review_end_date),
					resources:
						appraisalCycle.pms_appraisal_user_mappings_aggregate.aggregate
							?.count || 0,
					status: appraisalCycle.pms_appraisal_status.label,
					action: {
						enable_goal: appraisalCycle.enabled_modules.goal,
						enable_kra: appraisalCycle.enabled_modules.kra,
						...restAppraisalValues,
						ratingAndScoreRange: appraisalCycle.pms_appraisal_rating_scales.map(
							(rating) => {
								const { __typename, ...ratingValues } = rating;
								return { ...ratingValues, isEditable: false };
							}
						),
					},
				};
			}
		);
		setAppraisalRows(data);
	}, [appraisalCycles]);

	const columns = [
		{ name: 'name', label: 'CYCLE',options: {
			customBodyRender: (
				value: any,
				tableMeta: any,
				updateValue: any
			) => {
				return (
					<Tooltip title={value}>
						<Box
							className={`${dataGridStyles.text} ${formStyles.ellipsis}`}
						>
							{value || '- -'}
						</Box>
					</Tooltip>
				);
			},
		}, },
		{ name: 'period', label: 'PERIOD',options: {
			customBodyRender: (
				value: any,
				tableMeta: any,
				updateValue: any
			) => {
				return (
					<Tooltip title={value}>
						<Box
							className={`${dataGridStyles.text} ${formStyles.ellipsis}`}
						>
							{value || '- -'}
						</Box>
					</Tooltip>
				);
			},
		}, },
		{
			name: 'resources',
			label: 'RESOURCES',
			options: {
				customBodyRender: (value: any) => <NoOfUsersIndicator count={value} />,
			},
		},
		{
			name: 'status',
			label: 'STATUS',
			options: {
				customBodyRender: (value: any) => (
					<Tooltip title={value}>
						<div className={dataGridStyles.statusChip}>
							<p className={dataGridStyles.statusLabel}>
								{value}
							</p>
						</div>
					</Tooltip>
				),
			},
		},
		{ name: 'reviewEndDate', label: 'REVIEW END DATE',options: {
			customBodyRender: (
				value: any,
				tableMeta: any,
				updateValue: any
			) => {
				return (
					<Tooltip title={value}>
						<Box
							className={`${dataGridStyles.text} ${formStyles.ellipsis}`}
						>
							{value || '- -'}
						</Box>
					</Tooltip>
				);
			},
		}, },
	];

	const options: MUIDataTableOptions = {
		filter: false,
		print: false,
		rowsPerPage: 10,
		download: false,
		page: 0,
		serverSide: true,
		rowsPerPageOptions: [5, 10, 20, 30],
		search: false,
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectableRowsHeader: false,
		count:
			appraisalCycles?.pms_pms_appraisal_cycle_aggregate.aggregate?.count || 0,
		textLabels: {
			body: {
				noMatch: 'No data found',
			},
		},
		onRowClick: (row: any, rowMeta) => {
			history.push(`/team-pms/${appraisalRows[rowMeta.dataIndex].id}`);
		},
		onChangeRowsPerPage: (numberOfRows: number) => {
			setRowsPerPage(numberOfRows);
			setPage(0);
		},
		onTableChange: (action: string, tableState: any) => {
			tableState.data.length === 0 ? setPage(0) : setPage(tableState.page);
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				default:
					return;
			}
		},
	};

	return (
		<div>
			<div>
				<div>
					<p className={listStyles.heading}>Appraisal Cycles</p>
					<Box
						display='flex'
						justifyContent='space-between'
						marginBottom='20px'
						marginTop='20px'
					>
						<Box display='flex' alignItems='center' sx={{ gridGap: '8px' }}>
							<Box>
								<CustomSearchRender
									searchText={searchedText}
									setSearchedText={setSearchedText}
								/>
							</Box>
							{isAppraisalLoading && (
								<Box>
									<Loader />
								</Box>
							)}
						</Box>
					</Box>
					<div>
						<ThemeProvider theme={DataGridTheme}>
							<MUIDataTable
								title='Appraisal Cycles'
								data={appraisalRows}
								columns={columns}
								options={options}
							/>
						</ThemeProvider>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TeamsAppraisalCycleList;
