import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	createTheme,
	IconButton,
	InputAdornment,
	ThemeProvider,
	TextField,
	Checkbox,
	withStyles,
	Tooltip,
	makeStyles,
} from '@material-ui/core';
import {
	AutocompleteInput as ReactAutocompleteInput,
	ReferenceInput,
} from 'react-admin';
import { insightViewStyle, modalFormStyle } from '../../../Layout/styles';
import {
	customAutoCompleteSearchBarTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import { autocompleteMultiSelectLabelStyle } from '../style';
import { TimesheetTaskWiseReport } from './TimesheetTaskWiseReport.component';

import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { Form } from 'react-final-form';

import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import dayjs from 'dayjs';
import {
	useGetDraftAndUnSubmittedTimesheetHoursByProjectIdQuery,
	useGetGeneralDraftAndUnSubmittedTimesheetHoursByProjectIdQuery,
	useGetGeneralOvertimeHoursQuery,
	useGetGeneralTimesheetHoursQuery,
	useGetOvertimeHoursByProjectIdQuery,
	useGetProjectListQuery,
	useGetTimesheetHoursByProjectIdQuery,
} from '../../../generated/graphql';
import { Duration } from 'dayjs/plugin/duration';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { START_DATE_ERROR, uuidValidator } from '../constant';
import { UserProfileContext } from '../../../App';
import { autoCompleteFilterOption, isFloat } from '../../../Utils/string.util';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
const dateTimePickerTheme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				width: '70%',
				height: '20px',
			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '12px',
				transformOrigin: 'top left',
				fontFamily: 'Manrope-extrabold',
				color: '#292929',
			},
		},
		MuiInput: {
			root: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiInputBase: {
			root: {
				height: '36px',
				'& > input': {
					height: '23px',
					paddingLeft: '0px',
					paddingTop: '0px',
					paddingBottom: '0px',
					fontSize: '12px',
					fontFamily: 'Manrope-medium',
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#4285F4', // Primary color for datetime picker
		},
	},
});

const useStyles = makeStyles((theme) => ({
	reducedValueContainer: {
		width: '95px',
		height: '48px',
		background: '#F7F9FA',
		borderRadius: '4px',
		padding: '6px 0px 6px 6px',
		marginRight: '16px',
	},
}));
interface Hours {
	totalHours: any;
	billableHours: any;
	nonBillableHours: any;
}
interface Option {
	label?: string;
	value?: string;
	company_id?: string;
}
interface TimeSheet {
	id: string;
	working_hours: string;
	is_billable: boolean;
}
interface FormValues {
	start_date: string;
	end_date: string;
}

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);
const CountCard = ({
	label,
	value,
}: {
	label: string;
	value: number | string;
}) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.reducedValueContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};

export const TimesheetTaskWiseReportContainer = () => {
	const [projectId, setProjectId] = useState<string[]>();
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const [totalHours, setTotalHours] = useState<Hours>();
	const [companyId, setCompanyId] = useState<string>();
	const classes = insightViewStyle();
	const { data: projectList } = useGetProjectListQuery({
		variables: {
			filters: {
				company_id: companyId ? { _eq: companyId } : {},
			},
		},
	});
	const style = useStyles();
	const styles = modalFormStyle();
	const inlinestyle = autocompleteMultiSelectLabelStyle();
	const [startDateError, setStartDateError] = useState(false);
	const [projectIdList, setProjectIdList] = useState<any>();
	const [project, setProject] = useState<any>([]);
	const [selectedProjectOptionValue, setSelectedProjectOptionValue] =
		useState<any>();
	const { dateFormat, isOvertimeEnabled } = useContext<any>(UserProfileContext);
	const allSelected =
		project.length === selectedProjectOptionValue?.length || 0 ? true : false;

	const [choosedResourceIdList, setChoosedResourceIdList] = useState<
		string[] | null
	>([]);
	const [billableType, setBillableType] = useState<string | null>();
	const [dayType, setDayType] = useState<string[] | null>();
	const [taskType, setTaskType] = useState<string | null>();
	const [timesheetStatus, setTimesheetStatus] = useState<string[]>([
		'submitted',
		'approved',
	]);
	const handleClearOptions = () => {
		setSelectedProjectOptionValue([]);
		setProjectId([]);
		setProjectIdList([]);
	};

	const handleToggleOption = (selectedOptions: any) => {
		const selectedProjectIds = selectedOptions.map(
			(project: any) => project?.value || ''
		);
		setProjectId(selectedProjectIds);
		const idList = selectedProjectIds?.filter((item: any) =>
			uuidValidator.test(item)
		);
		setProjectIdList(idList);
		setSelectedProjectOptionValue(selectedOptions);
	};

	const handleSelectAll = (isSelected: boolean) => {
		if (!projectList) {
			return;
		}
		if (isSelected) {
			if (companyId) {
				const record = [...projectList?.project_list];
				const selectedProjectIds = record.map(
					(project) => project?.value || ''
				);
				setProjectId(selectedProjectIds);
				const idList = selectedProjectIds?.filter((item: any) =>
					uuidValidator.test(item)
				);
				setProjectIdList(idList);
				setSelectedProjectOptionValue(record);
				return;
			}
			const record = [...projectList?.project_list, ...projectList?.org_tasks];
			const selectedProjectIds = record.map((project) => project?.value || '');
			setProjectId(selectedProjectIds);
			const idList = selectedProjectIds?.filter((item: any) =>
				uuidValidator.test(item)
			);
			setProjectIdList(idList);
			setSelectedProjectOptionValue(record);
		} else {
			setProjectId([]);
			setProjectIdList([]);
			handleClearOptions();
		}
	};

	const handleToggleSelectAll = () => {
		handleSelectAll && handleSelectAll(!allSelected);
	};

	const onProjectChange = (event: any, value: (any | null)[], reason: any) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option) => option.value === 'All')) {
				handleToggleSelectAll();
				return;
			} else {
				handleToggleOption && handleToggleOption(value);
				return;
			}
		} else if (reason === 'clear') {
			handleClearOptions && handleClearOptions();
			return;
		}
	};

	const { data: timeSheetHoursList } = useGetTimesheetHoursByProjectIdQuery({
		variables: {
			filters: {
				project_id: projectIdList ? { _in: projectIdList } : {},
				timesheet_submission: {
					timesheet_status: {
						value: {
							_in:
								timesheetStatus && timesheetStatus?.length > 0
									? timesheetStatus?.filter((status) => status !== 'draft')
									: ['submitted', 'approved'],
						},
					},
				},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				user_id:
					choosedResourceIdList && choosedResourceIdList?.length > 0
						? { _in: choosedResourceIdList }
						: {},
				is_billable:
					billableType === 'All'
						? {}
						: billableType === 'true'
						? { _eq: true }
						: billableType === 'false'
						? { _eq: false }
						: {},
				day_type_id: dayType && dayType?.length > 0 ? { _in: dayType } : {},
			},
		},
	});

	const { data: generalTimeSheetHoursList } = useGetGeneralTimesheetHoursQuery({
		variables: {
			filters: {
				org_task: {
					task_category: { _in: projectId || [] },
				},
				timesheet_submission: {
					timesheet_status: {
						value: {
							_in:
								timesheetStatus && timesheetStatus?.length > 0
									? timesheetStatus?.filter((status) => status !== 'draft')
									: ['submitted', 'approved'],
						},
					},
				},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				user_id:
					choosedResourceIdList && choosedResourceIdList?.length > 0
						? { _in: choosedResourceIdList }
						: {},
				is_billable:
					billableType === 'All'
						? {}
						: billableType === 'true'
						? { _eq: true }
						: billableType === 'false'
						? { _eq: false }
						: {},
				day_type_id: dayType && dayType?.length > 0 ? { _in: dayType } : {},
			},
		},
	});

	const { data: overtimeSheetHoursList } = useGetOvertimeHoursByProjectIdQuery({
		variables: {
			filters: {
				project_id: projectIdList ? { _in: projectIdList } : {},
				timesheet_overtime_submission: {
					timesheet_status: {
						value: {
							_in:
								timesheetStatus && timesheetStatus?.length > 0
									? timesheetStatus?.filter((status) => status !== 'draft')
									: ['submitted', 'approved'],
						},
					},
				},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				user_id:
					choosedResourceIdList && choosedResourceIdList?.length > 0
						? { _in: choosedResourceIdList }
						: {},
				is_billable:
					billableType === 'All'
						? {}
						: billableType === 'true'
						? { _eq: true }
						: billableType === 'false'
						? { _eq: false }
						: {},
			},
		},
		skip: !isOvertimeEnabled,
	});

	const { data: generalOvertimeHoursList } = useGetGeneralOvertimeHoursQuery({
		variables: {
			filters: {
				org_task: {
					task_category: { _in: projectId || [] },
				},
				timesheet_overtime_submission: {
					timesheet_status: {
						value: {
							_in:
								timesheetStatus && timesheetStatus?.length > 0
									? timesheetStatus?.filter((status) => status !== 'draft')
									: ['submitted', 'approved'],
						},
					},
				},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				user_id:
					choosedResourceIdList && choosedResourceIdList?.length > 0
						? { _in: choosedResourceIdList }
						: {},
				is_billable:
					billableType === 'All'
						? {}
						: billableType === 'true'
						? { _eq: true }
						: billableType === 'false'
						? { _eq: false }
						: {},
			},
		},
		skip: !isOvertimeEnabled,
	});
	//For Draft
	// Get timesheet entries which are in draft and unsubmitted status
	const { data: draftAndUnSubmittedEntryList } =
		useGetDraftAndUnSubmittedTimesheetHoursByProjectIdQuery({
			variables: {
				filters: {
					project_id: projectIdList ? { _in: projectIdList } : {},
					timesheet_submission: {
						timesheet_status: {
							value: {
								_in: ['draft'],
							},
						},
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						choosedResourceIdList && choosedResourceIdList?.length > 0
							? { _in: choosedResourceIdList }
							: {},
					is_billable:
						billableType === 'All'
							? {}
							: billableType === 'true'
							? { _eq: true }
							: billableType === 'false'
							? { _eq: false }
							: {},
					day_type_id: dayType && dayType?.length > 0 ? { _in: dayType } : {},
				},
				unSubmittedFilters: {
					project_id: projectIdList ? { _in: projectIdList } : {},
					timesheet_submission_id: {
						_is_null: true,
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						choosedResourceIdList && choosedResourceIdList?.length > 0
							? { _in: choosedResourceIdList }
							: {},
					is_billable:
						billableType === 'All'
							? {}
							: billableType === 'true'
							? { _eq: true }
							: billableType === 'false'
							? { _eq: false }
							: {},
					day_type_id: dayType && dayType?.length > 0 ? { _in: dayType } : {},
				},
			},
			skip: timesheetStatus.length > 0 && !timesheetStatus.includes('draft'),
		});
	// Get timesheet entries which are in draft and unsubmitted status
	const { data: generalDraftAndUnSubmittedEntryList } =
		useGetGeneralDraftAndUnSubmittedTimesheetHoursByProjectIdQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission: {
						timesheet_status: {
							value: {
								_in: ['draft'],
							},
						},
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						choosedResourceIdList && choosedResourceIdList?.length > 0
							? { _in: choosedResourceIdList }
							: {},
					is_billable:
						billableType === 'All'
							? {}
							: billableType === 'true'
							? { _eq: true }
							: billableType === 'false'
							? { _eq: false }
							: {},
					day_type_id: dayType && dayType?.length > 0 ? { _in: dayType } : {},
				},
				unSubmittedFilters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					timesheet_submission_id: {
						_is_null: true,
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					user_id:
						choosedResourceIdList && choosedResourceIdList?.length > 0
							? { _in: choosedResourceIdList }
							: {},
					is_billable:
						billableType === 'All'
							? {}
							: billableType === 'true'
							? { _eq: true }
							: billableType === 'false'
							? { _eq: false }
							: {},
					day_type_id: dayType && dayType?.length > 0 ? { _in: dayType } : {},
				},
			},
			skip: timesheetStatus.length > 0 && !timesheetStatus.includes('draft'),
		});
	useEffect(() => {
		if (!projectList) {
			return;
		}
		if (!companyId) {
			const record = [...projectList?.project_list, ...projectList?.org_tasks];
			setProject(record);
			return;
		}
		const record = [...projectList?.project_list];
		setProject(record);
	}, [projectList, companyId]);

	useEffect(() => {
		if (!timeSheetHoursList && !generalTimeSheetHoursList) {
			return;
		}
		const generalTimesheetData =
			generalTimeSheetHoursList?.org_tasks?.map((task) => {
				const timeSheetData = task?.timesheets?.map((timeSheet) => {
					return {
						id: timeSheet?.id,
						is_billable: timeSheet?.is_billable,
						working_hours: timeSheet?.working_hours,
					};
				});
				return timeSheetData;
			}) || [];
		const generalDraftStatusTimesheetEntryList =
			generalDraftAndUnSubmittedEntryList?.draftTimesheetEntries
				? generalDraftAndUnSubmittedEntryList?.draftTimesheetEntries?.map(
						(task) => {
							const timeSheetData = task?.timesheets?.map((timeSheet) => {
								return {
									id: timeSheet?.id,
									is_billable: timeSheet?.is_billable,
									working_hours: timeSheet?.working_hours,
								};
							});
							return timeSheetData;
						}
				  )
				: [];
		const generalUnSubmittedStatusTimesheetEntryList =
			generalDraftAndUnSubmittedEntryList?.unSubmittedTimesheetEntries
				? generalDraftAndUnSubmittedEntryList?.unSubmittedTimesheetEntries?.map(
						(task) => {
							const timeSheetData = task?.timesheets?.map((timeSheet) => {
								return {
									id: timeSheet?.id,
									is_billable: timeSheet?.is_billable,
									working_hours: timeSheet?.working_hours,
								};
							});
							return timeSheetData;
						}
				  )
				: [];
		const timeSheetHours = timeSheetHoursList?.timesheet || [];
		const draftStatusTimesheetEntryList =
			draftAndUnSubmittedEntryList?.draftTimesheetEntries || [];
		const unSubmittedStatusTimesheetEntryList =
			draftAndUnSubmittedEntryList?.unSubmittedTimesheetEntries || [];
		const generalOvertimeData = generalOvertimeHoursList
			? generalOvertimeHoursList?.org_tasks?.map((task) => {
					const timeSheetData = task?.timesheet_overtime?.map((timeSheet) => {
						return {
							id: timeSheet?.id,
							is_billable: timeSheet?.is_billable,
							working_hours: timeSheet?.working_hours,
						};
					});
					return timeSheetData;
			  })
			: [];
		const overtimeHours = overtimeSheetHoursList
			? overtimeSheetHoursList?.timesheet_overtime
			: [];
		const aggregateTotalHours = isOvertimeEnabled
			? taskType === 'overtime'
				? [...overtimeHours, ...generalOvertimeData.flat()]
				: taskType === 'timesheet'
				? [
						...timeSheetHours,
						...generalTimesheetData?.flat(),
						...generalUnSubmittedStatusTimesheetEntryList.flat(),
						...generalDraftStatusTimesheetEntryList.flat(),
						...draftStatusTimesheetEntryList,
						...unSubmittedStatusTimesheetEntryList,
				  ]
				: [
						...timeSheetHours,
						...generalTimesheetData?.flat(),
						...overtimeHours,
						...generalOvertimeData.flat(),
						...generalUnSubmittedStatusTimesheetEntryList.flat(),
						...generalDraftStatusTimesheetEntryList.flat(),
						...draftStatusTimesheetEntryList,
						...unSubmittedStatusTimesheetEntryList,
				  ]
			: [
					...timeSheetHours,
					...generalTimesheetData?.flat(),
					...generalUnSubmittedStatusTimesheetEntryList.flat(),
					...generalDraftStatusTimesheetEntryList.flat(),
					...draftStatusTimesheetEntryList,
					...unSubmittedStatusTimesheetEntryList,
			  ];
		const totalHours = aggregateTotalHours
			.map((timeSheet) => timeSheet?.working_hours?.split(':'))
			.map((hoursAndMinutes: string[]) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total: any, durations: Duration) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);

		const billableHours = aggregateTotalHours
			?.filter((timeSheet) => timeSheet?.is_billable)
			.map((timeSheet) => timeSheet?.working_hours?.split(':'))
			.map((hoursAndMinutes: string[]) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total: Duration, durations: Duration) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);

		const nonBillableHours = aggregateTotalHours
			?.filter((timeSheet) => !timeSheet.is_billable)
			.map((timeSheet) => timeSheet?.working_hours?.split(':'))
			.map((hoursAndMinutes: string[]) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total: Duration, durations: Duration) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);
		setTotalHours({
			totalHours,
			billableHours,
			nonBillableHours,
		});
	}, [
		draftAndUnSubmittedEntryList,
		generalDraftAndUnSubmittedEntryList,
		generalOvertimeHoursList,
		generalTimeSheetHoursList,
		isOvertimeEnabled,
		overtimeSheetHoursList,
		taskType,
		timeSheetHoursList,
	]);

	const getLimits = (value: Number) => {
		return (
			<div
				style={{
					marginBottom: '7px',
					color: '#4e4e4e',
					marginLeft: '10px',
				}}
			>
				{selectedProjectOptionValue && selectedProjectOptionValue?.length === 1
					? selectedProjectOptionValue[0].label
					: `Selected ${value} items`}
			</div>
		);
	};

	const projectAutoCompleteInput = () => (
		<Autocomplete
			multiple
			placeholder='Select Project'
			limitTags={0}
			fullWidth={false}
			selectOnFocus={true}
			onChange={onProjectChange}
			options={project?.length > 0 ? project : []}
			value={
				selectedProjectOptionValue && selectedProjectOptionValue?.length > 0
					? selectedProjectOptionValue
					: []
			}
			style={{ width: 210, marginTop: '4px' }}
			ChipProps={{ style: { display: 'none' } }}
			disableCloseOnSelect={true}
			getLimitTagsText={getLimits}
			filterOptions={(options, params) => {
				const filtered = autoCompleteFilterOption(options, params);
				return [{ label: 'All', value: 'All' }, ...filtered];
			}}
			classes={{
				tag: inlinestyle.label,
			}}
			renderOption={(option: any | null, { selected }) => {
				const selectAllProps =
					option.value === 'All' // To control the state of 'select-all' checkbox
						? { checked: allSelected }
						: {};
				return (
					<>
						<Checkbox
							icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
							checkedIcon={<CheckBoxIcon fontSize='small' />}
							style={{ marginRight: 8 }}
							checked={selected || false}
							color='primary'
							{...selectAllProps}
						/>
						<Box style={{ fontSize: '12px' }}>{`${option?.label}`}</Box>
					</>
				);
			}}
			getOptionLabel={(option: any | null) => `${option?.label}`}
			renderInput={(params) => (
				<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
					<TextField {...params} label='' />
				</ThemeProvider>
			)}
		/>
	);

	return (
		<Box>
			<Box className={classes.reducedCountContainer}>
				<Box className={classes.countContainerWithoutGrey}>
					<Box display='flex'>
						<Box
							display='block'
							marginTop='-15px'
							marginRight='15px'
							width='max-content'
						>
							<Box className={styles.label}>Search Customer</Box>
							<Box marginTop={'4px'}>
								<Form onSubmit={() => {}}>
									{() => (
										<ThemeProvider theme={modalFormTheme}>
											<ReferenceInput
												source='crm_company'
												label=''
												reference='crm_company'
												sort={{ field: 'name', order: 'ASC' }}
												filterToQuery={(searchText: any) => ({
													name: searchText,
												})}
												filter={{
													name: true,
												}}
												onChange={(event) => {
													if (!event) {
														setCompanyId('');
														setProjectId([]);
														setProjectIdList([]);
														setSelectedProjectOptionValue([]);
														return;
													}
													if (selectedProjectOptionValue) {
														setProjectId([]);
														setProjectIdList([]);
														setSelectedProjectOptionValue([]);
														setCompanyId(event || null);
														return;
													}
													setCompanyId(event || null);
												}}
											>
												<ReactAutocompleteInput
													resettable={true}
													optionText={(name: any) => `${name?.name}`}
													shouldRenderSuggestions={(name: string) =>
														name.trim().length > 0
													}
												/>
											</ReferenceInput>
										</ThemeProvider>
									)}
								</Form>
							</Box>
						</Box>
						<Box display='block' marginTop='-15px'>
							<Box className={styles.label}>Search Project</Box>
							{projectAutoCompleteInput()}
						</Box>
						<ThemeProvider theme={dateTimePickerTheme}>
							<Box display='flex'>
								<ErrorTooltip
									title={startDateError ? START_DATE_ERROR : ''}
									placement='top'
									arrow={true}
								>
									<Box width='188px' marginLeft='20px'>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												variant='inline'
												onChange={(date: any) => {
													setStartDate(date);
													if (dayjs(date).isAfter(dayjs(endDate))) {
														setStartDateError(true);
													} else {
														setStartDateError(false);
													}
												}}
												autoOk={true}
												value={startDate}
												format={dateFormat}
												label='Start Date'
												InputLabelProps={{ shrink: true }}
												InputProps={{
													endAdornment: (
														<InputAdornment position='start'>
															<IconButton>
																<EventIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
												error={startDateError}
											/>
										</MuiPickersUtilsProvider>
									</Box>
								</ErrorTooltip>
								<Box width='188px'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											variant='inline'
											onChange={(date: any) => {
												setEndDate(date);
												if (dayjs(startDate).isAfter(dayjs(date))) {
													setStartDateError(true);
												} else {
													setStartDateError(false);
												}
											}}
											value={endDate}
											autoOk={true}
											format={dateFormat}
											label='End Date'
											InputLabelProps={{ shrink: true }}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton>
															<EventIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</Box>
						</ThemeProvider>
					</Box>
				</Box>
				<Box display='flex'>
					<CountCard
						label='Total(hrs)'
						value={
							projectId && totalHours?.totalHours
								? isFloat(Number(totalHours?.totalHours.asHours()))
									? Number(totalHours?.totalHours.asHours()).toFixed(1)
									: totalHours?.totalHours.asHours()
								: '--'
						}
					/>
					<CountCard
						label='Billable(hrs)'
						value={
							projectId && totalHours?.billableHours
								? isFloat(Number(totalHours?.billableHours.asHours()))
									? Number(totalHours?.billableHours.asHours()).toFixed(1)
									: totalHours?.billableHours.asHours()
								: '--'
						}
					/>
					<div>
						<Box className={style.reducedValueContainer}>
							<Box className={classes.countLabel}>{'Non-billable(hrs)'}</Box>
							<Box className={classes.countValue}>
								{projectId && totalHours?.nonBillableHours
									? isFloat(Number(totalHours?.nonBillableHours.asHours()))
										? Number(totalHours?.nonBillableHours.asHours()).toFixed(1)
										: totalHours?.nonBillableHours.asHours()
									: '--'}
							</Box>
						</Box>
					</div>
				</Box>
			</Box>
			{projectId &&
				(dayjs(startDate).isSame(dayjs(endDate)) ||
					dayjs(startDate).isBefore(dayjs(endDate))) && (
					<Box className={classes.largeContainer}>
						<TimesheetTaskWiseReport
							projectId={projectId}
							startDate={startDate}
							endDate={endDate}
							choosedResourceId={(value) => {
								setChoosedResourceIdList(value);
							}}
							billableType={(value) => setBillableType(value)}
							taskType={(value) => setTaskType(value)}
							dayType={(value) => setDayType(value)}
							choosedTimesheetStatus={(value) => {
								setTimesheetStatus(value);
							}}
						/>
					</Box>
				)}
		</Box>
	);
};

export default TimesheetTaskWiseReportContainer;
