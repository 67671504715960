import gql from 'graphql-tag';

export const GET_ATTENDANCE = gql`
	query getAttendance($userId: uuid!, $date: date) {
		attendance(where: { user_id: { _eq: $userId }, date: { _eq: $date } }) {
			org_id
			user_id
			out_time
			location
			in_time
			id
			date
		}
	}
`;

export const UPSERT_ATTENDANCE = gql`
	mutation upsertAttendance($attendance: attendance_insert_input!) {
		insert_attendance_one(
			object: $attendance
			on_conflict: {
				constraint: attendance_pkey
				update_columns: [in_time, out_time, location]
			}
		) {
			id
		}
	}
`;

export const GET_TOTAL_ATTENDANCE = gql`
	query getAttendanceTotal($userId: uuid!, $startDate: date, $endDate: date) {
		attendance(
			where: {
				user_id: { _eq: $userId }
				date: { _gte: $startDate, _lte: $endDate }
				out_time: { _is_null: false }
			}
		) {
			date
			id
			in_time
			location
			org_id
			out_time
		}
	}
`;

export const GET_CURRENT_MONTH_ATTENDANCE = gql`
	query getCurrentMonthAttendance(
		$userId: uuid!
		$startDate: date
		$endDate: date
	) {
		attendance(
			where: {
				user_id: { _eq: $userId }
				date: { _gte: $startDate, _lte: $endDate }
			}
		) {
			date
			id
			in_time
			location
			org_id
			out_time
		}
	}
`;

export const GET_LEAVE_APPROVER = gql`
	query getLeaveApprover($userId: uuid!) {
		user_by_pk(id: $userId) {
			employees {
				reportsTo {
					first_name
					last_name
					full_name
				}
			}
		}
	}
`;

export const GET_ORGANIZATION_UPCOMING_HOLIDAY = gql`
	query getUpcomingHolidays($date: date) {
		org_holidays(
			where: { date: { _gt: $date } }
			order_by: { date: asc }
			limit: 3
		) {
			name
			date
		}
	}
`;

export const GET_EMPLOYEE_JOINING_DATE = gql`
	query getEmployeeJoiningDate($userId: uuid!) {
		employee_leaving_mapping_aggregate(
			where: { employee_id: { _eq: $userId } }
		) {
			aggregate {
				min {
					from_date
				}
				max {
					to_date
				}
			}
		}
	}
`;

export const GET_WORKING_HOURS = gql`
	query getWorkingHours($userId: uuid!, $startDate: date, $endDate: date) {
		attendance(
			where: {
				user_id: { _eq: $userId }
				out_time: { _is_null: false }
				date: { _gte: $startDate, _lt: $endDate }
			}
		) {
			in_time
			date
			out_time
		}
	}
`;

export const GET_EMPLOYEE_CONSULTANT_SEARCH = gql`
	query getEmployeeConsultant {
		user(where: { is_active: { _eq: true } }, order_by: { full_name: asc }) {
			first_name
			last_name
			full_name
			id
			user_type
			employee {
				id
			}
		}
	}
`;

export const GET_ATTENDANCE_YEAR = gql`
	query getAttendanceYear {
		attendance_aggregate {
			aggregate {
				max {
					date
				}
				min {
					date
				}
			}
		}
	}
`;

export const GET_EMPLOYEE_OR_CONSULTANT_JOINING_DATE_BY_ID = gql`
	query getEmployeeJoinDateByUserId($user_id: uuid!) {
		user(where: { id: { _eq: $user_id } }) {
			user_type
			employee {
				join_date
			}
			contractors {
				join_date
			}
		}
	}
`;

export const GET_LEAVE_BALANCE = gql`
	query getLeaveBalance($employeeId: uuid!, $startDate: date, $endDate: date) {
		org_leave_group(where: { employees: { id: { _eq: $employeeId } } }) {
			is_holidays_and_weekends_excluded
			org_leave_types {
				leave_increments
				leave_type
				id
				default_amount
				is_negative_bal_allowed
				is_leave_sandwich_type
				employee_leave_type_mappings(
					where: { employee_id: { _eq: $employeeId } }
				) {
					opening_balance
					id
					renewal_date
				}
				accrued_leave_type(where: { employee_id: { _eq: $employeeId } }) {
					id
					count
				}
				employee_leaving_mappings(
					where: {
						employee_leave_status: { value: { _in: ["approved", "submitted"] } }
						from_date: { _gte: $startDate }
						to_date: { _lte: $endDate }
						employee_id: { _eq: $employeeId }
					}
				) {
					leave_count
					leave_type_id
					employee_id
				}
			}
		}
	}
`;

export const GET_EMPLOYEE_REPORTEE = gql`
	query getEmployeeReportee($userId: uuid!) {
		user(where: { id: { _eq: $userId }, is_active: { _eq: true } }) {
			employee {
				reportsTo {
					first_name
					last_name
					full_name
					id
				}
			}
		}
	}
`;

export const UPSERT_LEAVE = gql`
	mutation upsertLeave($leave: employee_leaving_mapping_insert_input!) {
		insert_employee_leaving_mapping_one(
			object: $leave
			on_conflict: {
				constraint: leaves_pkey
				update_columns: [
					is_half_day
					reason
					leave_type_id
					from_date
					to_date
					leave_count
				]
			}
		) {
			id
		}
	}
`;

export const EMPLOYEE_LEAVE_STATUS = gql`
	query employeeLeaveStatus {
		employee_leave_status {
			id
			label
			value
		}
	}
`;

export const GET_EMPLOYEE_LEAVE_APPROVED_SUBMITTED_COUNT = gql`
	query getEmployeeLeaveApprovedSubmiteedCount(
		$userId: uuid!
		$startDate: date
		$endDate: date
	) {
		employee_leaving_mapping(
			where: {
				_or: [
					{ from_date: { _lte: $endDate }, to_date: { _gte: $startDate } }
					{ from_date: { _lte: $endDate }, to_date: { _gte: $startDate } }
					{ from_date: { _gte: $startDate, _lte: $endDate } }
				]
				employee_id: { _eq: $userId }
				employee_leave_status: { value: { _in: ["approved", "submitted"] } }
			}
		) {
			id
		}
	}
`;

export const UPDATE_LEAVE_BALANCE = gql`
	mutation updateLeaveBalances(
		$leaveTypeId: uuid!
		$leaveTypeData: employee_leave_type_mappings_set_input!
	) {
		update_employee_leave_type_mappings(
			where: { id: { _eq: $leaveTypeId } }
			_set: $leaveTypeData
		) {
			affected_rows
		}
	}
`;

export const GET_SUBMITTED_LEAVE = gql`
	query getAppliedLeavesForApproval(
		$userId: uuid!
		$statusId: uuid_comparison_exp = {}
		$employeeStatus: user_bool_exp = {}
		$employeeId: uuid!
		$startDate: date
		$endDate: date
	) {
		employee(
			where: { reportsTo: { id: { _eq: $userId } }, user: $employeeStatus }
		) {
			id
			user {
				first_name
				last_name
				full_name
				id
				profile_pic
			}
			employee_leaving_mappings(
				where: { employee_leave_status: { id: $statusId } }
				order_by: { from_date: desc }
			) {
				id
				reason
				leave_count
				is_half_day
				from_date
				to_date
				employee_id
				org_leave_type {
					id
					leave_type
				}
				employee_leave_status {
					id
					label
					value
				}
			}
		}
		leaveBalanceData: org_leave_group(
			where: { employees: { id: { _eq: $employeeId } } }
		) {
			org_leave_types {
				leave_increments
				leave_type
				id
				default_amount
				is_negative_bal_allowed
				employee_leave_type_mappings(
					where: { employee_id: { _eq: $employeeId } }
				) {
					opening_balance
					id
					renewal_date
				}
				accrued_leave_type(where: { employee_id: { _eq: $employeeId } }) {
					id
					count
				}
				employee_leaving_mappings(
					where: {
						employee_leave_status: { value: { _in: ["approved", "submitted"] } }
						from_date: { _gte: $startDate }
						to_date: { _lte: $endDate }
						employee_id: { _eq: $employeeId }
					}
				) {
					leave_count
					leave_type_id
					employee_id
				}
			}
		}
	}
`;

export const APPROVE_LEAVE = gql`
	mutation approveLeave(
		$leaveId: [uuid!]!
		$approverId: uuid!
		$leaveStatusId: uuid!
	) {
		update_employee_leaving_mapping(
			_set: {
				approver_id: $approverId
				employee_leave_status_id: $leaveStatusId
			}
			where: { id: { _in: $leaveId } }
		) {
			affected_rows
		}
	}
`;

export const GET_LEAVE_PENDING_COUNT = gql`
	query leavePendingCount($userId: uuid!) {
		employee(where: { reportsTo: { id: { _eq: $userId } } }) {
			id
			employee_leaving_mappings_aggregate(
				where: { employee_leave_status: { value: { _eq: "submitted" } } }
			) {
				aggregate {
					count
				}
			}
		}
	}
`;
export const GET_EMPLOYEE_LEAVE = gql`
	query getEmployeeLeave(
		$userId: uuid!
		$employeeLeaveStatusId: uuid_comparison_exp = {}
		$financialStartDate: date!
		$financialEndDate: date!
		$employeeId: uuid!
	) {
		employee(where: { user_id: { _eq: $userId } }) {
			id
			user {
				first_name
				last_name
				full_name
				id
				profile_pic
			}
			employee_leaving_mappings(
				where: {
					employee_leave_status_id: $employeeLeaveStatusId
					_or: [
						{
							from_date: { _gte: $financialStartDate, _lte: $financialEndDate }
						}
						{
							from_date: { _lte: $financialStartDate }
							to_date: { _gte: $financialStartDate }
						}
					]
				}
				order_by: { from_date: desc }
			) {
				id
				reason
				leave_count
				from_date
				to_date
				is_half_day
				employee_id
				org_leave_type {
					id
					leave_type
				}
				employee_leave_status {
					id
					label
					value
				}
			}
		}
		leaveBalanceData: org_leave_group(
			where: { employees: { id: { _eq: $employeeId } } }
		) {
			org_leave_types {
				leave_increments
				leave_type
				id
				default_amount
				is_negative_bal_allowed
				employee_leave_type_mappings(
					where: { employee_id: { _eq: $employeeId } }
				) {
					opening_balance
					id
					renewal_date
				}
				accrued_leave_type(where: { employee_id: { _eq: $employeeId } }) {
					id
					count
				}
				employee_leaving_mappings(
					where: {
						employee_leave_status: { value: { _in: ["approved", "submitted"] } }
						_or: [
							{
								from_date: {
									_gte: $financialStartDate
									_lte: $financialEndDate
								}
							}
							{
								from_date: { _lte: $financialStartDate }
								to_date: { _gte: $financialStartDate }
							}
						]
						employee_id: { _eq: $employeeId }
					}
				) {
					leave_count
					leave_type_id
					employee_id
				}
			}
		}
	}
`;

export const PENDING_APPROVAL_LEAVE = gql`
	query getPendingApprovalLeave(
		$userId: uuid!
		$startDate: date
		$endDate: date
		$statusId: uuid_comparison_exp = {}
		$employeeId: uuid!
		$finacialStartDate: date
		$finacialEndDate: date
	) {
		employee(where: { user_id: { _eq: $userId } }) {
			id
			user {
				first_name
				last_name
				full_name
				id
				profile_pic
			}
			employee_leaving_mappings(
				where: {
					employee_leave_status: { id: $statusId }
					_or: [
						{ from_date: { _gte: $startDate, _lte: $endDate } }
						{ to_date: { _gte: $startDate, _lte: $endDate } }
						{
							_and: [
								{ from_date: { _lte: $startDate } }
								{ to_date: { _gte: $endDate } }
							]
						}
					]
				}
				order_by: { from_date: desc }
			) {
				id
				reason
				leave_count
				from_date
				to_date
				is_half_day
				employee_id
				org_leave_type {
					id
					leave_type
				}
				employee_leave_status {
					id
					label
					value
				}
			}
		}
		leaveBalanceData: org_leave_group(
			where: { employees: { id: { _eq: $employeeId } } }
		) {
			org_leave_types {
				leave_increments
				leave_type
				id
				default_amount
				is_negative_bal_allowed
				employee_leave_type_mappings(
					where: { employee_id: { _eq: $employeeId } }
				) {
					opening_balance
					id
					renewal_date
				}
				accrued_leave_type(where: { employee_id: { _eq: $employeeId } }) {
					id
					count
				}
				employee_leaving_mappings(
					where: {
						employee_leave_status: { value: { _in: ["approved", "submitted"] } }
						from_date: { _gte: $finacialStartDate }
						to_date: { _lte: $finacialEndDate }
						employee_id: { _eq: $employeeId }
					}
				) {
					leave_count
					leave_type_id
					employee_id
				}
			}
		}
	}
`;

export const MY_PENDING_COUNT = gql`
	query myPendingCount($userId: uuid!) {
		employee(where: { user_id: { _eq: $userId } }) {
			id
			employee_leaving_mappings_aggregate(
				where: { employee_leave_status: { value: { _eq: "submitted" } } }
			) {
				aggregate {
					count
				}
			}
		}
	}
`;

export const GET_TIMESHEET_LIST = gql`
	query getTimesheetList(
		$userId: uuid!
		$startDate: date
		$endDate: date
		$statusList: [String!]!
	) {
		timesheet(
			where: {
				user_id: { _eq: $userId }
				date: { _gte: $startDate, _lte: $endDate }
				timesheet_submission: {
					timesheet_status: { value: { _in: $statusList } }
				}
			}
		) {
			date
			working_hours
		}
	}
`;

export const DELETE_ATTENDANCE = gql`
	mutation deleteAttendance($attendanceId: uuid!) {
		delete_attendance(where: { id: { _eq: $attendanceId } }) {
			returning {
				id
			}
		}
	}
`;

export const GET_USER_ATTENDANCE_LEAVE_REPORT = gql`
	query getEmployeeAttendanceAndLeaveData(
		$startDate: date!
		$endDate: date!
		$filter: user_bool_exp = {}
		$orgId: uuid!
		$statusList: [String!]!
	) {
		user(where: $filter, order_by: { full_name: asc }) {
			full_name
			id
			user_type
			email
			attendance(where: { date: { _gte: $startDate, _lte: $endDate } }) {
				user_id
				date
				in_time
				out_time
			}
			timesheets(
				where: {
					date: { _gte: $startDate, _lte: $endDate }
					timesheet_submission: {
						timesheet_status: { value: { _in: $statusList } }
					}
				}
			) {
				date
				working_hours
			}
			organization {
				emp_id_prefix
				contractor_id_prefix
			}
			contractors {
				location_id
				join_date
				reportee {
					full_name
					email
				}
				org_location {
					id
					name
				}
				contractor_id
				department {
					name
				}
				contractor_prefix {
					id
					name
					value
				}
			}
			employee {
				emp_id
				location_id
				join_date
				reportsTo {
					full_name
					email
				}
				org_location {
					id
					name
				}
				department {
					name
				}
				employee_prefix {
					id
					name
					value
				}
				employee_leaving_mappings(
					where: {
						employee_leave_status: { value: { _eq: "approved" } }
						_or: [
							{ from_date: { _gte: $startDate, _lte: $endDate } }
							{ to_date: { _gte: $startDate, _lte: $endDate } }
							{
								_and: [
									{ from_date: { _lte: $startDate } }
									{ to_date: { _gte: $endDate } }
								]
							}
						]
					}
				) {
					id
					from_date
					to_date
					leave_count
					is_half_day
					org_leave_type {
						leave_type
						is_leave_sandwich_type
					}
				}
			}
			user_optional_holiday_mappings {
				org_holiday {
					date
					name
					org_location {
						id
						name
					}
				}
			}
		}
		organization(where: { id: { _eq: $orgId } }) {
			weekend
		}
		org_holidays(
			where: {
				date: { _gte: $startDate, _lte: $endDate }
				is_restricted: { _eq: false }
			}
		) {
			id
			date
			name
			org_location {
				name
				id
			}
		}
	}
`;
export const GET_USER = gql`
	query getUserDetails($userId: uuid!) {
		user(where: { id: { _eq: $userId } }) {
			full_name
		}
	}
`;

export const GET_EMPLOYE_BY_USER_ID = gql`
	query getEmployeeDetailsByUserId($userId: uuid!) {
		employee(where: { user: { id: { _eq: $userId } } }) {
			id
			reportee_id
			location_id
		}
	}
`;

export const DELETE_APPLIED_LEAVE = gql`
	mutation deleteAppliedLeave($id: uuid!) {
		delete_employee_leaving_mapping(where: { id: { _eq: $id } }) {
			affected_rows
		}
	}
`;

export const CURRENT_MONTH_ATTANDENCE_LEAVE_IN_ATTENDANCE_SUMMARY = gql`
	query currentMonthAttendanceLeaveInAttendanceSummary(
		$orgId: uuid!
		$userId: uuid!
		$startDate: date!
		$endDate: date!
		$statusList: [String!]!
	) {
		workHours: organization_by_pk(id: $orgId) {
			working_hours
		}
		leaveApprove: user_by_pk(id: $userId) {
			user_type
			employees {
				reportsTo {
					full_name
				}
			}
			contractors {
				reportee {
					full_name
				}
			}
		}
		attendance: attendance(
			where: {
				user_id: { _eq: $userId }
				out_time: { _is_null: false }
				date: { _gte: $startDate, _lt: $endDate }
			}
		) {
			in_time
			date
			out_time
		}

		timesheet: timesheet(
			where: {
				user_id: { _eq: $userId }
				date: { _gte: $startDate, _lte: $endDate }
				timesheet_submission: {
					timesheet_status: { value: { _in: $statusList } }
				}
			}
		) {
			date
			working_hours
		}
	}
`;

export const CURRENT_MONTH_ATTENDANCE_LEAVE_INFO = gql`
	query currentMonthAttendanceLeaveInfo(
		$userId: uuid!
		$startDate: date!
		$endDate: date!
	) {
		approvedLeaveCount: employee(where: { user_id: { _eq: $userId } }) {
			employee_leaving_mappings(
				where: {
					employee_leave_status: { value: { _eq: "approved" } }
					_or: [
						{ from_date: { _gte: $startDate, _lte: $endDate } }
						{ to_date: { _gte: $startDate, _lte: $endDate } }
						{
							_and: [
								{ from_date: { _lte: $startDate } }
								{ to_date: { _gte: $endDate } }
							]
						}
					]
				}
			) {
				leave_count
				from_date
				to_date
				is_half_day
				reason
				org_leave_type {
					id
					leave_type
					is_leave_sandwich_type
				}
			}
			employee_leaving_mappings_aggregate(
				where: { employee_leave_status: { value: { _eq: "approved" } } }
			) {
				aggregate {
					sum {
						leave_count
					}
				}
			}
		}
	}
`;

export const GET_APPLIED_LEAVE_REPORT_IN_ATTENDANCE = gql`
	query getAppliedLeaveReportInAttendance(
		$endDate: date!
		$startDate: date!
		$where: employee_leaving_mapping_bool_exp = {}
	) {
		employee_leaving_mapping(
			where: $where
			order_by: { employee: { user: { full_name: asc } } }
		) {
			from_date
			to_date
			reason
			leave_count
			org_leave_type {
				leave_type
				org_leave_group {
					label
				}
			}
			created_at
			employee {
				org_location {
					name
				}
				reportsTo {
					full_name
					email
				}
				department {
					name
				}
				user {
					user_type
					project_resource_mappings(
						where: {
							_and: [
								{ start_date: { _lte: $endDate } }
								{ end_date: { _gte: $startDate } }
							]
						}
						distinct_on: project_id
					) {
						project {
							name
							is_billable
						}
					}
					full_name
					email
				}
				emp_id
				employee_prefix {
					name
				}
			}
			employee_leave_status {
				label
			}
		}
	}
`;

export const GET_USER_ALL_HOLIDAYS_AND_WEEKENDS = gql`
	query getUserAllHolidaysAndWeekends(
		$orgId: uuid!
		$filter: org_holidays_bool_exp = {}
		$optionalHolidayFilter: user_optional_holiday_mapping_bool_exp = {}
	) {
		organization(where: { id: { _eq: $orgId } }) {
			weekend
		}
		org_holidays(where: $filter) {
			name
			date
			id
			org_location {
				id
				name
			}
		}
		user_optional_holiday_mapping(where: $optionalHolidayFilter) {
			id
			org_holiday {
				name
				id
				date
				is_restricted
				org_location {
					id
					name
				}
			}
		}
	}
`;

export const GET_USER_WORKING_DAYS = gql`
	query getUserWorkingDays($orgId: uuid!) {
		organization(where: { id: { _eq: $orgId } }) {
			weekend
		}
	}
`;

export const GET_EMPLOYEE_BULK_ATTENDANCE_DATA = gql`
	query getEmployeeAttendanceDetail(
		$where: attendance_bool_exp = {}
		$startDate: date!
		$endDate: date!
	) {
		attendance(where: $where, order_by: { date: asc }) {
			in_time
			out_time
			date
			user {
				employee {
					emp_id
					employee_prefix {
						name
						value
					}
					org_location {
						name
					}

					employee_leaving_mappings(
						where: {
							_or: [
								{ from_date: { _gte: $startDate, _lte: $endDate } }
								{ to_date: { _gte: $startDate, _lte: $endDate } }
								{
									_and: [
										{ from_date: { _lte: $startDate } }
										{ to_date: { _gte: $endDate } }
									]
								}
							]
						}
					) {
						id
						from_date
						to_date
						is_half_day
						reason
						leave_count
						org_leave_type {
							leave_type
						}
					}
				}
				contractors {
					contractor_id
					contractor_prefix {
						name
						value
					}
					org_location {
						name
					}
				}
				full_name
				user_type
				email
			}
		}
	}
`;

export const GET_MY_TEAM_VIEW_IN_ATTENDANCE = gql`
	query getMyTeamViewInAttendance($userId: uuid!) {
		user_aggregate(
			where: {
				_or: [
					{ employee: { reportsTo: { id: { _eq: $userId } } } }
					{ contractors: { reportee: { id: { _eq: $userId } } } }
				]
				is_active: { _eq: true }
			}
		) {
			aggregate {
				count
			}
		}
		user(
			where: {
				_or: [
					{ employee: { reportsTo: { id: { _eq: $userId } } } }
					{ contractors: { reportee: { id: { _eq: $userId } } } }
				]
				is_active: { _eq: true }
			}
			order_by: { full_name: asc }
		) {
			full_name
			first_name
			last_name

			user_type
			id

			employee {
				id
			}
		}
	}
`;
export const GET_ORGANIZATION_WEEKENDS = gql`
	query getOrganizationWeekends($orgId: uuid!) {
		organization_by_pk(id: $orgId) {
			weekend
		}
	}
`;


export const GET_REPORTING_MANAGER = gql`
	query getReportingManagerByUserId($userId: uuid!) {
		user(where: { id: { _eq: $userId }, is_active: { _eq: true } }) {
			user_type
			employee {
				reportsTo {
					full_name
					id
				}
			}
			contractors {
				reportee {
					full_name
					id
				}
			}
		}
	}
`;

export const UPSERT_ATTENDANCE_REQUEST = gql`
	mutation upsertAttendanceRequest(
		$attendance: user_attendance_request_insert_input!
	) {
		insert_user_attendance_request_one(
			object: $attendance
			on_conflict: {
				update_columns: [in_time, out_time, reason]
				constraint: user_attendance_request_pkey
			}
		) {
			id
		}
	}
`;
export const GET_ATTENDNACE_STATUS = gql`
	query getAttendanceStatus {
		attendance_status {
			id
			label
			value
		}
	}
`;

export const GET_ATTENDNACE_REGULARIZATION_HISTORY = gql`
	query getAttendanceRegularizationHistoryByUserId(
		$filter: user_attendance_request_bool_exp = {}
	) {
		user_attendance_request(where: $filter, order_by: { date: desc }) {
			id
			date
			in_time
			out_time
			reason
			submittedBy {
				full_name
				profile_pic
				id
			}
			attendanceStatus {
				label
				value
			}
		}
		user_attendance_request_aggregate(where: $filter) {
			aggregate {
				count
			}
		}
	}
`;

export const GET_ATTENDNACE_REGULARIZATION_HISTORY_FOR_ATTENDANCE_LIST = gql`
	query getAttendanceRegularizationListByUserId(
		$pendingFilter: user_attendance_request_bool_exp = {}
		$approvedFilter: user_attendance_request_bool_exp = {}
	) {
		pendingRequestList: user_attendance_request(
			where: $pendingFilter
			order_by: { date: desc }
		) {
			date
		}
		approvedRequestList: user_attendance_request(
			where: $approvedFilter
			order_by: { date: desc }
		) {
			date
		}
	}
`;

export const GET_ATTENDNACE_REGULARIZATION_HISTORY_FOR_APPROVAL = gql`
	query getAttendanceRequestForApproval(
		$where: user_attendance_request_bool_exp!
	) {
		user_attendance_request(where: $where) {
			submittedBy {
				profile_pic
				full_name
				id
				user_type
			}
			id
			date
			in_time
			out_time
			reason
			attendanceStatus {
				id
				label
				value
			}
		}
	}
`;
export const APPROVE_ATTENDACE_REQUEST = gql`
	mutation approveAttendaceRequest(
		$attendanceData: [attendance_insert_input!]!
		$userIdList: [uuid!]!
		$dateList: [date!]!
		$attendanceRequestIdList: [uuid!]!
		$attendaceStatusId: uuid!
	) {
		delete_attendance(
			where: { user_id: { _in: $userIdList }, date: { _in: $dateList } }
		) {
			affected_rows
		}
		insert_attendance(objects: $attendanceData) {
			affected_rows
		}
		update_user_attendance_request(
			where: { id: { _in: $attendanceRequestIdList } }
			_set: { attendance_status_id: $attendaceStatusId }
		) {
			affected_rows
		}
	}
`;
export const REJECT_ATTENDACE_REQUEST = gql`
	mutation deleteAttendanceRequest($attendanceIdList: [uuid!]!) {
		delete_user_attendance_request(where: { id: { _in: $attendanceIdList } }) {
			affected_rows
		}
	}
`;
export const GET_ATTENDNACE_REGULARIZATION_PENDING_COUNT_FOR_APPROVAL = gql`
	query getPendingAttendanceRequestCount($userId: uuid!) {
		reporteeRequestCount: user_attendance_request_aggregate(
			where: {
				approved_by: { _eq: $userId }
				attendanceStatus: { value: { _eq: "submitted" } }
			}
		) {
			aggregate {
				count
			}
		}
		userRequestCount: user_attendance_request_aggregate(
			where: {
				submitted_by: { _eq: $userId }
				attendanceStatus: { value: { _eq: "submitted" } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
