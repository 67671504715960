import React from 'react';

import {
	Box,
	Typography,
	Button,
	Drawer,
	Tooltip,
	ThemeProvider,
} from '@material-ui/core';
import {
	drawerFilterStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import { Form } from 'react-final-form';
import CloseIcon from '@material-ui/icons/Close';
import { pmsViewDetailsStyle } from '../pms.styles';
import { TextInput, required, useNotify } from 'react-admin';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { useUpdateManagerGoalCommentMutation } from '../../../generated/graphql';
import Loader from '../SharedComponents/Loader.component';
import { COMMENT_UPDATED_TEXT } from '../constant';

interface ReviewGoalCommentProps {
	open: boolean;
	onClose: () => void;
	goalMapping: {
		id: any;
		user_comment: string;
		reviewer_comment: string;
		isDisabled: boolean;
		goal: string;
		index: number;
	};
	onSuccess: () => void;
}

function ReviewGoalComment(props: ReviewGoalCommentProps) {
	const { open, onClose, goalMapping, onSuccess } = props;
	const drawerFilterStyles = drawerFilterStyle();
	const formStyles = modalFormStyle();
	const pmsDetailsStyles = pmsViewDetailsStyle();
	const ellipsis = ellipsisStyle();
	const notify = useNotify();

	// API
	const [updateManagerGoalComment, { loading: isUpdateCommentLoading }] =
		useUpdateManagerGoalCommentMutation();

	const handleSubmit = (formValue: {
		user_comment: string;
		reviewer_comment: string;
	}) => {
		updateManagerGoalComment({
			variables: {
				goalMappingId: goalMapping.id,
				manager_comment: formValue.reviewer_comment,
			},
		}).then((res) => {
			if (!res.errors) {
				notify(COMMENT_UPDATED_TEXT);
				onSuccess();
			}
		});
	};

	return (
		<Drawer open={open} anchor='right' onClose={onClose}>
			<Box
				className={drawerFilterStyles.drawerContainer}
				style={{
					width: '500px',
				}}
			>
				<Box display='flex' flexDirection='column'>
					<Box className={drawerFilterStyles.HeaderSection}>
						<Typography className={drawerFilterStyles.drawerHeading}>
							Comment For Goal
						</Typography>
						<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
					</Box>
				</Box>
				<ThemeProvider theme={modalFormTheme}>
					<Form
						onSubmit={handleSubmit}
						initialValues={{
							user_comment: goalMapping?.user_comment || '',
							reviewer_comment: goalMapping?.reviewer_comment || '',
						}}
					>
						{({ handleSubmit, invalid, pristine }) => (
							<form onSubmit={handleSubmit}>
								<div>
									<div
										style={{
											marginTop: '20px',
											marginBottom: '20px',
										}}
									>
										<Tooltip title={goalMapping.goal} placement='top'>
											<div
												className={`${pmsDetailsStyles.goal} ${ellipsis.ellipsis}`}
												style={{
													fontSize: '16px',
													width: '400px',
												}}
											>
												{goalMapping.goal}
											</div>
										</Tooltip>
									</div>

									<div
										style={{
											marginBottom: '20px',
										}}
									>
										<p className={formStyles.label}>Employee Comments</p>
										<TextInput
											source='user_comment'
											fullWidth={true}
											label={false}
											validate={[required()]}
											multiline
											disabled={true}
										/>
									</div>

									<div
										style={{
											marginBottom: '20px',
										}}
									>
										<p className={formStyles.label}>Manager Comments</p>
										<TextInput
											source='reviewer_comment'
											fullWidth={true}
											label={false}
											validate={[required()]}
											multiline
											disabled={goalMapping?.isDisabled}
										/>
									</div>
								</div>

								<Box className={drawerFilterStyles.buttonContainer}>
									<Box mb={'10px'}>
										<Button
											className={
												invalid ||
												pristine ||
												goalMapping?.isDisabled ||
												isUpdateCommentLoading
													? formStyles.disabledButton
													: formStyles.saveButton
											}
											variant='outlined'
											color='primary'
											type='submit'
											disabled={
												invalid ||
												pristine ||
												goalMapping?.isDisabled ||
												isUpdateCommentLoading
											}
											startIcon={isUpdateCommentLoading && <Loader />}
										>
											Save
										</Button>
									</Box>
									<Box mb={'10px'}>
										<Button
											onClick={() => onClose()}
											className={drawerFilterStyles.cancelButton}
											variant='outlined'
										>
											Cancel
										</Button>
									</Box>
								</Box>
							</form>
						)}
					</Form>
				</ThemeProvider>
			</Box>
		</Drawer>
	);
}

export default ReviewGoalComment;
