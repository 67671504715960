import React, { useContext, useRef, useState } from 'react';

import {
	APPRAISAL_CREATED,
	APPRAISAL_NAME_REQUIRED,
	APPRAISAL_PERIOD_DATE_RANGE_ERROR,
	APPRAISAL_UPDATED,
	EMPLOYEE_TYPE,
	END_DATE_ERROR,
	INITIAL_APPRAISAL_FORM_DATE_ERROR_STATE,
	REQUIRED_ERROR_TEXT,
} from '../constant';
import {
	AppraisalFormErrors,
	AppraisalFormInitialValue,
	AppraisalFormValue,
	RatingAndScoreRange,
} from '../pms.module';
import { modalFormTheme } from '../../../Layout/Theme.component';
import { CustomDateInput } from '../../../SharedComponents/CustomDateInput.component';
import RatingAndScoreRangeEditor from './RatingAndScoreRangeEditor.component';
import { UserProfileContext } from '../../../App';
import dayjs from 'dayjs';

import {
	Button,
	Dialog,
	DialogContent,
	Divider,
	IconButton,
	ThemeProvider,
	Tooltip,
	makeStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Form, Field } from 'react-final-form';
import { formStyle } from '../pms.styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import {
	TextInput,
	ReferenceArrayInput,
	AutocompleteArrayInput,
	BooleanInput,
	SelectInput,
	useNotify,
} from 'react-admin';
import {
	useGetUsersForAppraisalQuery,
	useInsertAppraisalCycleMutation,
} from '../../../generated/graphql';
import Loader from '../SharedComponents/Loader.component';
import { head } from 'lodash';
import { REGEX_FAILED_ERROR_TEXT, regexNameValidation } from './constant';

const appraisalFormStyle = makeStyles(() => ({
	outerContainer: {
		display: 'flex',
		width: '100%',
	},
	inputContainer: {
		width: '50%',
	},
	fileUploadContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '150px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'pointer',
		color: '#4285F4',
	},
	disabledFileUploadContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '150px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		color: '#a0a0a0',
	},
	fileUploadLabel: {
		fontSize: '12px',
		fontWeight: 600,
		fontFamily: 'Manrope-medium',
		marginLeft: '20px',
	},
	divider: {
		marginBottom: '10px',
	},
}));

interface AppraisalFormProps {
	onClose: () => void;
	open: boolean;
	onSuccess: () => void;
	defaultRatingScales: RatingAndScoreRange[];
	checkInOption: {
		id: any;
		name: string;
	}[];
	initialAppraisalStatus: string;
	initialUserAppraisalStatus: string;
	initialValues?: AppraisalFormInitialValue;
	appraisalStatusOptions: {
		id: any;
		name: string;
	}[];
}

interface DateRangeValidationErrors {
	endDate: {
		isError: boolean;
		errorText: string;
	};
	selfReviewEndDate: {
		isError: boolean;
		errorText: string;
	};
	selfReviewDateRangeError: {
		isError: boolean;
		errorText: string;
	};
	reviewEndDate: {
		isError: boolean;
		errorText: string;
	};
	reviewDateRangeError: {
		isError: boolean;
		errorText: string;
	};
}

function AppraisalForm(props: AppraisalFormProps) {
	const {
		open,
		onClose,
		onSuccess,
		defaultRatingScales,
		checkInOption,
		initialAppraisalStatus,
		initialUserAppraisalStatus,
		initialValues,
		appraisalStatusOptions,
	} = props;
	const { dateFormat } = useContext<any>(UserProfileContext);
	const inputRef = useRef<HTMLInputElement>(null);
	const notify = useNotify();
	const [dateRangeError, setDateRangeError] =
		useState<DateRangeValidationErrors>(
			INITIAL_APPRAISAL_FORM_DATE_ERROR_STATE
		);
	const [ratingAndScoreRange, setRatingAndScoreRange] =
		useState<RatingAndScoreRange[]>(defaultRatingScales);
	const [userQueryFilter, setUserQueryFilter] = useState({});

	const formStyles = formStyle();
	const styles = appraisalFormStyle();

	// API Call
	const [insertAppraisalCycle, { loading: isInsertAppraisalCycleLoading }] =
		useInsertAppraisalCycleMutation();

	const { data: users } = useGetUsersForAppraisalQuery({
		variables: {
			where: userQueryFilter,
		},
	});

	// Functions
	const getJoinDateFilter = (employee_type: string, joinDate?: Date) => {
		if (!joinDate) return {};
		if (employee_type === 'all') {
			return {
				_or: [
					{ employee: { join_date: { _gte: joinDate } } },
					{ contractors: { join_date: { _gte: joinDate } } },
				],
			};
		}
		if (employee_type === 'employee') {
			return { employee: { join_date: { _gte: joinDate } } };
		}
		if (employee_type === 'contract_employee') {
			return { contractors: { join_date: { _gte: joinDate } } };
		}
		return {};
	};

	const getDepartmentFilter = (
		employee_type: string,
		department_id?: any[]
	) => {
		if (!department_id || department_id?.length === 0) return {};
		if (employee_type === 'all') {
			return {
				_or: [
					{ employee: { department_id: { _in: department_id } } },
					{ contractors: { department_id: { _in: department_id } } },
				],
			};
		}
		if (employee_type === 'employee') {
			return { employee: { department_id: { _in: department_id } } };
		}
		if (employee_type === 'contract_employee') {
			return { contractors: { department_id: { _in: department_id } } };
		}
		return {};
	};

	const getLocationFilter = (employee_type: string, location_id?: any[]) => {
		if (!location_id || location_id?.length === 0) return {};
		if (employee_type === 'all') {
			return {
				_or: [
					{ employee: { location_id: { _in: location_id } } },
					{ contractors: { location_id: { _in: location_id } } },
				],
			};
		}
		if (employee_type === 'employee') {
			return { employee: { location_id: { _in: location_id } } };
		}
		if (employee_type === 'contract_employee') {
			return { contractors: { location_id: { _in: location_id } } };
		}
		return {};
	};

	const getDesignationFilter = (designation_id?: any[]) => {
		if (!designation_id || designation_id?.length === 0) return {};
		return { employee: { designation_id: { _in: designation_id } } };
	};

	const setEligibleUsers = async (formValue: {
		employee_type: string;
		join_date?: Date;
		department_id?: any[];
		designation_id?: any[];
		location_id?: any[];
	}) => {
		const employeeTypeFilter =
			formValue.employee_type === 'all'
				? EMPLOYEE_TYPE.filter((v) => v.id !== 'all').map(
						(empType) => empType.id
				  )
				: EMPLOYEE_TYPE.find((v) => v.id === formValue.employee_type)?.id;

		const joinDateFilter = getJoinDateFilter(
			formValue.employee_type,
			formValue.join_date
		);
		const departmentFilter = getDepartmentFilter(
			formValue.employee_type,
			formValue.department_id
		);
		const designationFilter = getDesignationFilter(formValue.designation_id);
		const locationFilter = getLocationFilter(
			formValue.employee_type,
			formValue.location_id
		);

		const userFilters = {
			_and: [
				{ is_active: { _eq: true } },
				{ user_type: { _in: [employeeTypeFilter].flat(Infinity) } },
				joinDateFilter,
				departmentFilter,
				designationFilter,
				locationFilter,
			],
		};
		setUserQueryFilter(userFilters);
		return userFilters;
	};

	const handleSave = async (formValue: AppraisalFormValue) => {
		let dateErrors = dateRangeError;

		if (dayjs(formValue.start_date).isAfter(dayjs(formValue.end_date))) {
			dateErrors = {
				...dateErrors,
				endDate: { isError: true, errorText: END_DATE_ERROR },
			};
		}
		if (
			dayjs(formValue.self_review_start_date).isAfter(
				dayjs(formValue.self_review_end_date)
			)
		) {
			dateErrors = {
				...dateErrors,
				selfReviewEndDate: { isError: true, errorText: END_DATE_ERROR },
			};
		}
		if (
			dayjs(formValue.review_start_date).isAfter(
				dayjs(formValue.review_end_date)
			)
		) {
			dateErrors = {
				...dateErrors,
				reviewEndDate: { isError: true, errorText: END_DATE_ERROR },
			};
		}

		if (
			dateErrors.endDate.isError ||
			dateErrors.selfReviewEndDate.isError ||
			dateErrors.reviewEndDate.isError
		) {
			setDateRangeError(dateErrors);
			return;
		}

		if (
			dayjs(formValue.start_date).isAfter(
				dayjs(formValue.self_review_start_date)
			) ||
			dayjs(formValue.end_date).isBefore(dayjs(formValue.self_review_end_date))
		) {
			setDateRangeError({
				...dateRangeError,
				selfReviewDateRangeError: {
					isError: true,
					errorText: APPRAISAL_PERIOD_DATE_RANGE_ERROR,
				},
			});
			return;
		}

		if (
			dayjs(formValue.start_date).isAfter(dayjs(formValue.review_start_date)) ||
			dayjs(formValue.end_date).isBefore(dayjs(formValue.review_end_date))
		) {
			setDateRangeError({
				...dateRangeError,
				selfReviewDateRangeError: {
					isError: true,
					errorText: APPRAISAL_PERIOD_DATE_RANGE_ERROR,
				},
			});
			return;
		}
		const trimmedName = formValue?.name?.trim();
		if (initialValues?.id) {
			await insertAppraisalCycle({
				variables: {
					data: [
						{
							id: initialValues.id,
							name: trimmedName,
							start_date: formValue.start_date,
							end_date: formValue.end_date,
							review_start_date: formValue.review_start_date,
							review_end_date: formValue.review_end_date,
							self_review_start_date: formValue.self_review_start_date,
							self_review_end_date: formValue.self_review_end_date,
							enable_check_in: formValue.enable_check_in,
							check_in_interval_id: formValue.check_in_interval_id,
							enable_salary_hike: formValue.enable_salary_hike,
							description: formValue.description,
							enabled_modules: {
								kra: formValue.enable_kra || false,
								goal: formValue.enable_goal || false,
							},
							appraisal_status_id: formValue.appraisal_status_id,
						},
					],
				},
			}).then((response: any) => {
				if (!response.error) {
					setDateRangeError(INITIAL_APPRAISAL_FORM_DATE_ERROR_STATE);
					notify(APPRAISAL_UPDATED);
					onSuccess();
					onClose();
				}
			});
			return;
		}

		await insertAppraisalCycle({
			variables: {
				data: [
					{
						name: trimmedName,
						start_date: formValue.start_date,
						end_date: formValue.end_date,
						review_start_date: formValue.review_start_date,
						review_end_date: formValue.review_end_date,
						self_review_start_date: formValue.self_review_start_date,
						self_review_end_date: formValue.self_review_end_date,
						check_in_interval_id: formValue.check_in_interval_id,
						enable_check_in: formValue.enable_check_in,
						enable_salary_hike: formValue.enable_salary_hike,
						appraisal_status_id: formValue.appraisal_status_id,
						description: formValue.description,
						enabled_modules: {
							kra: formValue.enable_kra || false,
							goal: formValue.enable_goal || false,
						},
						pms_appraisal_rating_scales: {
							data: ratingAndScoreRange.map((rating) => ({
								label: rating.label,
								min: rating.min,
								max: rating.max,
							})),
						},
						pms_appraisal_vision_mappings: {
							data:
								formValue.vision_id?.length > 0
									? formValue.vision_id.map((visionId) => ({
											vision_id: visionId,
									  }))
									: [],
						},
						pms_appraisal_user_mappings: {
							data: users?.user
								? users?.user.map((userValue) => ({
										user_appraisal_status_id: initialUserAppraisalStatus,
										user_id: userValue.id,
										reviewer:
											userValue.user_type === 'employee'
												? userValue.employee?.reportsTo?.id
												: head(userValue.contractors)?.reportee?.id,
								  }))
								: [],
						},
					},
				],
			},
		}).then((response: any) => {
			if (!response.error) {
				setDateRangeError(INITIAL_APPRAISAL_FORM_DATE_ERROR_STATE);
				notify(APPRAISAL_CREATED);
				onSuccess();
				onClose();
			}
		});
	};

	const validateFormValues = (formValue: AppraisalFormValue) => {
		const appraisalFormErrors = {} as AppraisalFormErrors;
		if (!formValue?.name || formValue.name.trim() === '') {
			appraisalFormErrors.name = APPRAISAL_NAME_REQUIRED;
		}
		if (!regexNameValidation.test(formValue?.name)) {
			appraisalFormErrors.name = REGEX_FAILED_ERROR_TEXT;
		}
		if (!formValue?.start_date) {
			appraisalFormErrors.start_date = REQUIRED_ERROR_TEXT;
		}

		if (!formValue?.end_date) {
			appraisalFormErrors.end_date = REQUIRED_ERROR_TEXT;
		}

		if (!formValue?.review_start_date) {
			appraisalFormErrors.review_start_date = REQUIRED_ERROR_TEXT;
		}

		if (!formValue?.review_end_date) {
			appraisalFormErrors.review_end_date = REQUIRED_ERROR_TEXT;
		}

		if (!formValue?.self_review_start_date) {
			appraisalFormErrors.self_review_start_date = REQUIRED_ERROR_TEXT;
		}

		if (!formValue?.self_review_end_date) {
			appraisalFormErrors.self_review_end_date = REQUIRED_ERROR_TEXT;
		}

		return appraisalFormErrors;
	};

	const handleClose = () => {
		setDateRangeError(INITIAL_APPRAISAL_FORM_DATE_ERROR_STATE);
		onClose();
	};

	return (
		<ThemeProvider theme={modalFormTheme}>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='dialog-title'
				aria-describedby='dialog-description'
				maxWidth={false}
				fullWidth
				disableBackdropClick
			>
				<div className={formStyles.container} style={{ width: '100%' }}>
					<div className={formStyles.headerContainer}>
						<p className={formStyles.heading}>
							{initialValues?.id ? `Edit Cycle` : `Create Cycle`}
						</p>
						<CloseIcon className={formStyles.closeIcon} onClick={handleClose} />
					</div>

					<Form
						onSubmit={handleSave}
						validate={validateFormValues}
						initialValues={
							initialValues?.id
								? initialValues
								: {
										employee_type: 'all',
										appraisal_status_id: initialAppraisalStatus,
								  }
						}
						mutators={{
							isCheckInEnabled: (args, state, utils) => {
								const value: { [key: string]: any } = state.formState.values;
								if (value?.enable_check_in) {
									return false;
								}
								return true;
							},
							getStartDate: (args, state, utils) => {
								const value: { [key: string]: any } = state.formState.values;
								return value?.start_date;
							},
							getEndDate: (args, state, utils) => {
								const value: { [key: string]: any } = state.formState.values;
								return value?.end_date;
							},
							setUserFilter: (args, state, utils) => {
								const value: { [key: string]: any } = state.formState.values;
								setEligibleUsers({
									employee_type: value.employee_type,
									join_date: value.join_date,
									department_id: value.department_id,
									designation_id: value.designation_id,
									location_id: value.location_id,
								});
							},
						}}
					>
						{({ handleSubmit, invalid, pristine, form }) => (
							<form onSubmit={handleSubmit}>
								<div>
									<DialogContent>
										<div className={styles.outerContainer}>
											{/* Section 1 */}
											<div
												className={styles.inputContainer}
												style={{
													borderRight: '1px solid #E0E0E0',
													paddingRight: '28px',
												}}
											>
												<p className={formStyles.label}>Appraisal Name*</p>
												<TextInput label='' source='name' />
												<div className={formStyles.multipleInputContainer}>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>Select Vision</p>
														<ReferenceArrayInput
															label=''
															reference='pms_pms_vision'
															source='vision_id'
															sort={{
																field: 'name',
																order: 'ASC',
															}}
															filterToQuery={(searchText: string) => ({
																name: searchText,
															})}
															disabled={initialValues?.id}
														>
															<AutocompleteArrayInput optionText='name' />
														</ReferenceArrayInput>
													</div>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>Status *</p>
														<SelectInput
															source='appraisal_status_id'
															label=''
															choices={appraisalStatusOptions || []}
														/>
													</div>
												</div>

												{/* Cycle Period */}
												<p className={formStyles.label}>Cycle Period</p>
												<Divider className={styles.divider} />

												<div
													className={formStyles.multipleInputContainer}
													style={{
														marginBottom: '20px',
													}}
												>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>Start Date *</p>
														<Field
															name='start_date'
															validate={(value) => {
																if (dayjs(value).isValid() === true) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props) => (
																<CustomDateInput
																	initialValue={props.input.value || null}
																	name={props.input.name}
																	onChange={(value) => {
																		props.input.onChange(value);
																		setDateRangeError({
																			...dateRangeError,
																			endDate: {
																				isError: false,
																				errorText: '',
																			},
																			selfReviewDateRangeError: {
																				isError: false,
																				errorText: '',
																			},
																			reviewDateRangeError: {
																				isError: false,
																				errorText: '',
																			},
																		});
																	}}
																	dateFormat={dateFormat}
																	isDisabled={initialValues?.id}
																/>
															)}
														</Field>
													</div>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>End Date *</p>
														<Field
															name='end_date'
															validate={(value) => {
																if (dayjs(value).isValid() === true) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props) => (
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	onChange={(value) => {
																		props.input.onChange(value);
																		setDateRangeError({
																			...dateRangeError,
																			endDate: {
																				isError: false,
																				errorText: '',
																			},
																			selfReviewDateRangeError: {
																				isError: false,
																				errorText: '',
																			},
																			reviewDateRangeError: {
																				isError: false,
																				errorText: '',
																			},
																		});
																	}}
																	dateFormat={dateFormat}
																	isDisabled={initialValues?.id}
																/>
															)}
														</Field>
														{dateRangeError.endDate.isError && (
															<p className={formStyles.errorText}>
																{dateRangeError.endDate.errorText}
															</p>
														)}
													</div>
												</div>

												{/* Self Review Period */}
												<p className={formStyles.label}>Self Review Period</p>
												<Divider className={styles.divider} />

												<div
													className={formStyles.multipleInputContainer}
													style={{
														marginBottom: '20px',
													}}
												>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>Start Date *</p>
														<Field
															name='self_review_start_date'
															validate={(value) => {
																if (dayjs(value).isValid() === true) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props) => (
																<CustomDateInput
																	initialValue={props.input.value || null}
																	name={props.input.name}
																	minDate={form.mutators.getStartDate()}
																	maxDate={form.mutators.getEndDate()}
																	onChange={(value) => {
																		props.input.onChange(value);
																		setDateRangeError({
																			...dateRangeError,
																			selfReviewEndDate: {
																				isError: false,
																				errorText: '',
																			},
																			selfReviewDateRangeError: {
																				isError: false,
																				errorText: '',
																			},
																		});
																	}}
																	isDisabled={initialValues?.id}
																	dateFormat={dateFormat}
																/>
															)}
														</Field>
														{dateRangeError.selfReviewDateRangeError
															.isError && (
															<p className={formStyles.errorText}>
																{
																	dateRangeError.selfReviewDateRangeError
																		.errorText
																}
															</p>
														)}
													</div>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>End Date *</p>
														<Field
															name='self_review_end_date'
															validate={(value) => {
																if (dayjs(value).isValid() === true) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props) => (
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	minDate={form.mutators.getStartDate()}
																	maxDate={form.mutators.getEndDate()}
																	onChange={(value) => {
																		props.input.onChange(value);
																		setDateRangeError({
																			...dateRangeError,
																			selfReviewEndDate: {
																				isError: false,
																				errorText: '',
																			},
																			selfReviewDateRangeError: {
																				isError: false,
																				errorText: '',
																			},
																		});
																	}}
																	dateFormat={dateFormat}
																	isDisabled={initialValues?.id}
																/>
															)}
														</Field>
														{dateRangeError.selfReviewEndDate.isError && (
															<p className={formStyles.errorText}>
																{dateRangeError.selfReviewEndDate.errorText}
															</p>
														)}
													</div>
												</div>

												{/* Review Period */}
												<p className={formStyles.label}>Review Period</p>
												<Divider className={styles.divider} />

												<div
													className={formStyles.multipleInputContainer}
													style={{
														marginBottom: '20px',
													}}
												>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>Start Date *</p>
														<Field
															name='review_start_date'
															validate={(value) => {
																if (dayjs(value).isValid() === true) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props) => (
																<CustomDateInput
																	initialValue={props.input.value || null}
																	name={props.input.name}
																	minDate={form.mutators.getStartDate()}
																	maxDate={form.mutators.getEndDate()}
																	onChange={(value) => {
																		props.input.onChange(value);
																		setDateRangeError({
																			...dateRangeError,
																			reviewEndDate: {
																				isError: false,
																				errorText: '',
																			},
																			reviewDateRangeError: {
																				isError: false,
																				errorText: '',
																			},
																		});
																	}}
																	isDisabled={initialValues?.id}
																	dateFormat={dateFormat}
																/>
															)}
														</Field>
														{dateRangeError.reviewDateRangeError.isError && (
															<p className={formStyles.errorText}>
																{dateRangeError.reviewDateRangeError.errorText}
															</p>
														)}
													</div>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>End Date *</p>
														<Field
															name='review_end_date'
															validate={(value) => {
																if (dayjs(value).isValid() === true) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props) => (
																<CustomDateInput
																	name={props.input.name}
																	initialValue={props.input.value || null}
																	minDate={form.mutators.getStartDate()}
																	maxDate={form.mutators.getEndDate()}
																	onChange={(value) => {
																		props.input.onChange(value);
																		setDateRangeError({
																			...dateRangeError,
																			reviewEndDate: {
																				isError: false,
																				errorText: '',
																			},
																			reviewDateRangeError: {
																				isError: false,
																				errorText: '',
																			},
																		});
																	}}
																	dateFormat={dateFormat}
																	isDisabled={initialValues?.id}
																/>
															)}
														</Field>
														{dateRangeError.reviewEndDate.isError && (
															<p className={formStyles.errorText}>
																{dateRangeError.reviewEndDate.errorText}
															</p>
														)}
													</div>
												</div>

												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
													}}
												>
													<div>
														<p className={formStyles.label}>
															Guideline Document
														</p>
														<div
															className={
																initialValues?.id
																	? styles.disabledFileUploadContainer
																	: styles.fileUploadContainer
															}
															onClick={() => {
																inputRef.current?.click();
																if (!inputRef?.current?.value) {
																	return;
																}
																inputRef.current.value = '';
															}}
														>
															<AttachFileIcon />
															<input
																type='file'
																style={{
																	display: 'none',
																}}
																ref={inputRef}
																accept='.pdf,.doc,.docx,.xls,.csv,.xlsx,.odt,.ods,.ppt,.pptx,.txt,.eml'
																disabled={initialValues?.id}
															/>
															<p className={styles.fileUploadLabel}>
																Attach File
															</p>
														</div>
													</div>
													<div>
														<p className={formStyles.label}>
															Enable Salary Hike
														</p>
														<BooleanInput
															source='enable_salary_hike'
															label=''
															disabled={initialValues?.id}
														/>
													</div>
													<div>
														<p className={formStyles.label}>Enable Check In</p>
														<BooleanInput
															source='enable_check_in'
															label=''
															disabled={initialValues?.id}
														/>
													</div>
													<div>
														<p className={formStyles.label}>
															Check-In Interval
														</p>
														<SelectInput
															source='check_in_interval_id'
															label=''
															disabled={
																form.mutators.isCheckInEnabled() ||
																initialValues?.id
															}
															choices={checkInOption || []}
														/>
													</div>
												</div>
												<p className={formStyles.label}>Description</p>
												<TextInput
													source='description'
													fullWidth={true}
													label={''}
													multiline
												/>
											</div>

											{/* Section 2 */}
											<div
												className={styles.inputContainer}
												style={{
													marginLeft: '28px',
												}}
											>
												{/* Eligibility Criteria */}
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<p className={formStyles.label}>
														Eligibility Criteria
													</p>
													<Tooltip title='' placement='right'>
														<IconButton>
															<InfoIcon />
														</IconButton>
													</Tooltip>
												</div>
												<Divider className={styles.divider} />

												<div className={formStyles.multipleInputContainer}>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>Employee Type</p>
														<SelectInput
															source='employee_type'
															label=''
															choices={EMPLOYEE_TYPE}
															onChange={() => {
																form.mutators.setUserFilter();
															}}
															disabled={initialValues?.id}
														/>
													</div>
													<div className={formStyles.multipleInput}>
														<p className={formStyles.label}>
															Date Of Joining Until
														</p>
														<Field
															name='join_date'
															validate={(value) => {
																if (dayjs(value).isValid() === true) {
																	return undefined;
																} else {
																	return 'Invalid Date';
																}
															}}
														>
															{(props) => (
																<CustomDateInput
																	initialValue={props.input.value || null}
																	name={props.input.name}
																	onChange={(value) => {
																		props.input.onChange(value);
																		form.mutators.setUserFilter();
																	}}
																	isDisabled={initialValues?.id}
																	dateFormat={dateFormat}
																/>
															)}
														</Field>
													</div>
												</div>

												<p className={formStyles.label}>Designation</p>
												<ReferenceArrayInput
													label=''
													reference='designation'
													source='designation_id'
													sort={{
														field: 'title',
														order: 'ASC',
													}}
													filterToQuery={(searchText: string) => ({
														title: searchText,
													})}
													onChange={() => {
														form.mutators.setUserFilter();
													}}
													disabled={initialValues?.id}
												>
													<AutocompleteArrayInput optionText='title' />
												</ReferenceArrayInput>

												<p className={formStyles.label}>Department</p>
												<ReferenceArrayInput
													label=''
													reference='department'
													source='department_id'
													sort={{
														field: 'name',
														order: 'ASC',
													}}
													filterToQuery={(searchText: string) => ({
														name: searchText,
													})}
													onChange={() => {
														form.mutators.setUserFilter();
													}}
													disabled={initialValues?.id}
												>
													<AutocompleteArrayInput optionText='name' />
												</ReferenceArrayInput>

												<p className={formStyles.label}>Location</p>
												<ReferenceArrayInput
													label=''
													reference='org_location'
													source='location_id'
													sort={{
														field: 'name',
														order: 'ASC',
													}}
													filterToQuery={(searchText: string) => ({
														name: searchText,
													})}
													onChange={() => {
														form.mutators.setUserFilter();
													}}
													disabled={initialValues?.id}
												>
													<AutocompleteArrayInput optionText='name' />
												</ReferenceArrayInput>

												<p className={formStyles.label}>
													Modules To Be Included
												</p>
												<Divider className={styles.divider} />
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-between',
														width: '200px',
													}}
												>
													<div>
														<p className={formStyles.label}>Goal</p>
														<BooleanInput
															source='enable_goal'
															label=''
															disabled={initialValues?.id}
														/>
													</div>
													<div>
														<p className={formStyles.label}>KRA</p>
														<BooleanInput
															source='enable_kra'
															label=''
															disabled={initialValues?.id}
														/>
													</div>
												</div>

												<p className={formStyles.label}>
													Rating and Score Range
												</p>
												<Divider className={styles.divider} />
												<RatingAndScoreRangeEditor
													onChange={(values: RatingAndScoreRange[]) => {
														setRatingAndScoreRange(values);
													}}
													values={
														initialValues?.id
															? initialValues.ratingAndScoreRange || []
															: ratingAndScoreRange
													}
													disabled={initialValues?.id}
												/>
											</div>
										</div>
										<div className={formStyles.buttonContainer}>
											<Button
												className={formStyles.cancelButton}
												onClick={handleClose}
											>
												Cancel
											</Button>
											<Button
												className={
													invalid || pristine || isInsertAppraisalCycleLoading
														? formStyles.disabledButton
														: formStyles.saveButton
												}
												disabled={
													invalid || pristine || isInsertAppraisalCycleLoading
												}
												type='submit'
												startIcon={isInsertAppraisalCycleLoading && <Loader />}
											>
												{initialValues?.id ? 'Save' : 'Create'}
											</Button>
										</div>
									</DialogContent>
								</div>
							</form>
						)}
					</Form>
				</div>
			</Dialog>
		</ThemeProvider>
	);
}

export default AppraisalForm;
