import * as React from 'react';
import { Route } from 'react-router-dom';
import { ResourcePlanner } from '../modules/ResourcePlanner/ResourcePlanner.component';
import { EmployeeDetails } from '../modules/Employees/DetailsPage/EmployeeDetails.component';
import { OpportunitiesKanbanContainer } from '../modules/Opportunities/OpportunitiesKanbanContainer.component';
import { OpportunityDetails } from '../modules/Opportunities/OpportunityDetails.component';
import CompanyDetails from '../modules/Company/DetailsPage/CompanyDetails.component';
import { Settings } from '../modules/Settings/Settings.component';
import ConsultantDetails from '../modules/Consultant/DetailsPage/ConsultantDetails.component';
import AssetDetails from '../modules/AssetManagement/AssetDetailsPage/AssetDetails.component';
import ProjectDetails from '../modules/ResourcePlanner/Details/ProjectDetails.component';
import { OrgChart } from '../Reports/OrgChart.component';
import EmployeeUtilizationReport from '../modules/ResourceUtiliztionReport/EmployeeUtilizationReport.component';
import CrmTaskListContainer from '../modules/CrmTask/CrmTaskListContainer.component';
import LeaveTypesList from '../modules/Settings/LeaveManagement/LeaveTypesList.component';
import ProjectListDetails from '../modules/Projects/Details/ProjectDetails.component';
import ProjectTaskMilestoneContainer from '../modules/ProjectTask/ProjectTaskMilestoneContainer';
import MyInfoView from '../modules/MyInfo/MyInfoView.component';
import TaskDetails from '../modules/ProjectTask/Details/TaskDetails.component';
import PipelineCard from '../modules/Opportunities/PipelineCard.component';
import PageNotFound from '../SharedComponents/PageNotFound.component';
import _ from 'lodash';
import { UserProfileContext } from '../App';
import DashboardInsightReport from '../modules/Dashboard/DashboardInsightComponent';
import TimesheetContainer from '../modules/Timesheet/TimesheetContainer.component';
import Home from '../modules/Home/Home.component';
import HomePageMobile from '../modules/Mobile/HomePageMobile';
import MyPMS from '../modules/PMS/MyPMS/MyPMS.component';
import PMSAdmin from '../modules/PMS/Admin/PMSAdmin.component';
import AppraisalDetails from '../modules/PMS/Admin/AppraisalDetails.component';
import ReporteePMSView from '../modules/PMS/TeamPMS/ReporteePMSView.component';
import MyPMSDetailsView from '../modules/PMS/MyPMS/MyPMSDetailsView.component';
import TeamPMSAppraisalDetails from '../modules/PMS/TeamPMS/TeamPMSAppraisalDetails.component';
import TeamsAppraisalCycleList from '../modules/PMS/TeamPMS/TeamsAppraisalCycleList.component';
import RequestResourceContainer from '../modules/Projects/RequestResourceContainer.component';

const WithAuthorization = (props) => {
	const { permissions: appPermissions } = React.useContext(UserProfileContext);
	const { RenderComponent, permissions, RedirectComponent } = props;

	if (
		!permissions.every((permissionKey) => {
			return _.has(appPermissions, permissionKey, false);
		})
	) {
		if (!RedirectComponent) {
			return <PageNotFound />;
		}
		return <RedirectComponent {...props} />;
	}
	return <RenderComponent {...props} />;
};

export default [
	<Route
		exact
		path='/'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={DashboardInsightReport}
				permissions={['appPermissions.ui.viewDashboard']}
				RedirectComponent={MyInfoView}
			/>
		)}
	/>,
	<Route exact path='/org-chart' component={OrgChart} />,
	<Route path='/my-info' component={MyInfoView} />,
	<Route path='/home' component={Home} />,
	<Route
		path='/employee/details/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={EmployeeDetails}
				permissions={['appPermissions.ui.ViewEmployees']}
			/>
		)}
	/>,
	<Route
		exact
		path='/contractor/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={ConsultantDetails}
				permissions={['appPermissions.ui.viewContractDetails']}
			/>
		)}
	/>,
	<Route
		exact
		path='/timesheet'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={TimesheetContainer}
				permissions={['timesheet.select_permissions']}
			/>
		)}
	/>,
	<Route
		path='/crm_company/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={CompanyDetails}
				permissions={['appPermissions.ui.viewCompanyDetails']}
			/>
		)}
	/>,
	<Route
		exact
		path='/opportunities'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={OpportunitiesKanbanContainer}
				permissions={['opportunities.select_permissions']}
			/>
		)}
	/>,
	<Route
		exact
		path='/Pipeline/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={OpportunitiesKanbanContainer}
				permissions={['opportunities.select_permissions']}
			/>
		)}
	/>,
	<Route
		exact
		path='/Pipeline'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={PipelineCard}
				permissions={['opportunities.select_permissions']}
			/>
		)}
	/>,
	<Route
		exact
		path='/opportunities/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={OpportunityDetails}
				permissions={['opportunities.select_permissions']}
			/>
		)}
	/>,
	<Route
		path='/crm_tasks'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={CrmTaskListContainer}
				permissions={['crm_tasks.select_permissions']}
			/>
		)}
	/>,
	<Route
		exact
		path='/resource-planner'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={ResourcePlanner}
				permissions={['appPermissions.ui.viewProjectAllocation']}
			/>
		)}
	/>,
	<Route
		exact
		path='/resource-planner/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={ProjectDetails}
				permissions={['appPermissions.ui.viewProjectAllocation']}
			/>
		)}
	/>,
	<Route
		path='/resource-availability'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={EmployeeUtilizationReport}
				permissions={['appPermissions.ui.viewResourceAvailability']}
			/>
		)}
	/>,
	<Route
	path='/resource-request'
	render={(props) => (
		<WithAuthorization
			{...props}
			RenderComponent={RequestResourceContainer}
			permissions={['appPermissions.ui.viewProjectResourceRequest']}
		/>
	)}
/>,
	<Route
		exact
		path='/project/detail/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={ProjectListDetails}
				permissions={['project.update_permissions']}
			/>
		)}
	/>,
	<Route
		exact
		path='/project/tasks/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={ProjectTaskMilestoneContainer}
				permissions={['project.select_permissions']}
			/>
		)}
	/>,
	<Route
		exact
		path='/project/:id/tasks/:task_id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={TaskDetails}
				permissions={['appPermissions.ui.viewProjectDetails']}
			/>
		)}
	/>,
	<Route
		path='/asset/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={AssetDetails}
				permissions={['appPermissions.ui.viewAssetDetails']}
			/>
		)}
	/>,
	<Route
		path='/settings'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={Settings}
				permissions={['master_settings']}
			/>
		)}
		exact
	/>,
	<Route
		path='/settings/leave-policy/:id'
		render={(props) => (
			<WithAuthorization
				{...props}
				RenderComponent={LeaveTypesList}
				permissions={['master_settings']}
			/>
		)}
	/>,

	// PMS Module Routes
	// TODO Need to create as a protected route once DB schema is ready
	<Route exact path='/my-pms' component={MyPMS} />,
	<Route exact path='/my-pms/:appraisalCycleId' component={MyPMSDetailsView} />,
	<Route exact path='/team-pms' component={TeamsAppraisalCycleList} />,
	<Route
		exact
		path='/team-pms/:appraisalCycleId'
		component={TeamPMSAppraisalDetails}
	/>,
	<Route
		exact
		path='/team-pms/:appraisalCycleId/:userId'
		component={ReporteePMSView}
	/>,
	<Route exact path='/admin-pms' component={PMSAdmin} />,
	<Route
		exact
		path='/admin-pms/appraisal-cycle/:id'
		component={AppraisalDetails}
	/>,

	// MOBILE VIEW ROUTES
	<Route path='/pwa/home_m' component={HomePageMobile} exact />,
];
