import React, { useState, useEffect, useContext } from 'react';
import { UserProfileContext } from '../../../App';
import EmployeePersonalDetails from './EmployeePersonalDetails.component';
import EmployeeSkillDetail from './EmployeeSkillDetail.component';
import EmployeeHistory from './EmployeeHistory.component';
import { EmployeeBankDetails } from './EmployeeBankDetails.component';
import { EmployeeChecklist } from './EmployeeChecklist.component';
import EmployeeNote from './EmployeeNote.component';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab } from '@material-ui/core';
import { EmployeeAssetDetails } from './EmployeeAssetDetilas.component';
import DocumentUploader from './DocumentUploader.component';
import EmployeeProjectList from '../../../SharedComponents/EmployeeProjectList';
import EmployeeHolidayList from '../../../SharedComponents/EmployeeHolidayList.component';

const CustomTabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#1890ff',
	},
})(Tabs);

const CustomTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginLeft: theme.spacing(1.8),
		fontFamily: 'Manrope-Medium',
		fontSize: '12px !important',
		letterSpacing: '0px',
		color: '#898989 ',
		'&$selected': {
			color: '#292929',
			fontFamily: 'Manrope-Bold',
		},
	},
}))((props) => <Tab disableRipple {...props} />);

const CustomTabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		height: '584px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
	},
	noData: {
		font: 'normal normal medium 12px/15px Manrope !important',
		letterSpacing: '0px',
		fontSize: '12px !important',
	},
}));

export const EmployeeDetailContainer = (props) => {
	const { record: employee, refetch: refetchEmployee } = props;
	const classes = useStyles();
	const {
		tabGlobalIndex: selectedTabIndex,
		setTabGlobalIndex: setSelectedTabIndex,
	} = useContext(UserProfileContext);
	const [employeeDetails, setemployeeDetails] = useState({});

	const handleChange = (event, newValue) => {
		setSelectedTabIndex(newValue);
	};
	useEffect(() => {
		setemployeeDetails({
			created_at: employee?.created_at,
			createdByUser: employee?.createdByUser,
			user: employee?.user,
		});
	}, [employee]);
	return (
		<div className={classes.root}>
			<CustomTabs value={selectedTabIndex} onChange={handleChange}>
				<CustomTab key={0} label='Employee Details' />
				<CustomTab key={1} label='Skills' />
				<CustomTab key={2} label='Compensation' />
				<CustomTab key={3} label='Documents' />
				<CustomTab key={4} label='Assets' />
				<CustomTab key={5} label='Checklist' />
				<CustomTab key={6} label='Notes' />
				<CustomTab key={7} label='Projects' />
				<CustomTab key={8} label='Holidays' />
				<CustomTab key={9} label='History' />
			</CustomTabs>

			<CustomTabPanel value={selectedTabIndex} index={0}>
				<EmployeePersonalDetails
					record={employee}
					refetch={() => {
						refetchEmployee();
					}}
				/>
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={1}>
				<EmployeeSkillDetail record={employee} />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={2}>
				<EmployeeBankDetails
					record={employee}
					refetch={() => {
						refetchEmployee();
					}}
				/>
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={3}>
				<DocumentUploader />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={4}>
				<EmployeeAssetDetails record={employee} />
			</CustomTabPanel>

			<CustomTabPanel value={selectedTabIndex} index={5}>
				<EmployeeChecklist employeeId={employee?.id} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={6}>
				<EmployeeNote />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={7}>
				<EmployeeProjectList record={employee?.user?.id} />
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={8}>
				<EmployeeHolidayList
					selectedUserId={employee?.user?.id || null}
					isManagerView={true}
					userLocationId={employee?.org_location?.id || null}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTabIndex} index={9}>
				<EmployeeHistory record={employeeDetails} />
			</CustomTabPanel>
		</div>
	);
};

export default EmployeeDetailContainer;
