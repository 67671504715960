import React, { useState, useEffect, useContext } from 'react';
import { useNotify } from 'react-admin';
import dayjs, { Dayjs } from 'dayjs';
import { compact, flatten, head, keyBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import AttendanceEditForm from './AttendanceEditForm.component';
import {
	Attendance as AttendanceList,
	Date,
	Holiday,
	SelectedMonthDates,
} from './Attendance.model';
import {
	useGetCurrentMonthAttendanceQuery,
	useDeleteAttendanceMutation,
	useGetTimesheetListQuery,
	useGetOrgHolidaysByUserQuery,
	useGetEmployeeJoinDateByUserIdQuery,
	useGetOrganizationWeekendsQuery,
	useCurrentMonthAttendanceLeaveInfoQuery,
	useGetAttendanceRegularizationListByUserIdQuery,
	useGetAttendanceRegularizationSettingsQuery,
} from '../../generated/graphql';
import duration, { Duration } from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { UserProfileContext } from '../../App';
import DeleteModal from '../../SharedComponents/DeleteModal.component';
import {
	Absent,
	holidayText,
	Leave,
	Present,
	Weekend,
	calculatedLeaveCountInSummaryInLeaveData,
	halfDay,
	ATTENDANCE_PENDING_STATUS_MESSAGE,
} from './Constant';
import {
	getSaturdaysDates,
	getSundaysBetweenDates,
} from '../../Utils/date-time.util';
import AttendanceRequestForm from './AttendanceRequestForm.component';

const Style = makeStyles(() => ({
	attendanceContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	label: {
		color: '#5C5C5C',
		fontSize: '10px',
		fontFamily: 'Manrope-extrabold',
	},
	date: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		marginLeft: '32px',
		width: '130px',
	},
	attendanceStatus: {
		width: '130px',
		cursor: 'pointer',
	},
	attendanceTime: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		width: '130px',
	},
	container: {
		display: 'flex',
		marginTop: '10px',
		color: '#5C5C5C',
		'&:hover': {
			'& $listEditIconButton': {
				display: 'block',
			},
		},
	},
	attendance: {
		backgroundColor: '#E9F7EC',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	holiday: {
		backgroundColor: '#fae4ac',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	leave: {
		backgroundColor: '#FFEAC2',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	todayDateHighlight: {
		display: 'flex',
		marginTop: '10px',
		backgroundColor: '#F7F9FA',
		color: '#5C5C5C',
		padding: '16px 0px 13px 0px',
		'&:hover': {
			'& $listEditIconButton': {
				display: 'block',
			},
		},
	},
	listEditIconContainer: {
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	pendingIconContainer: {
		width: '24px',
		height: '24px',
		marginLeft: '12px',
	},
	pendingIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	todaysDate: {
		color: '#FFFFFF',
		display: 'flex',
		marginTop: '10px',
		backgroundColor: '#4285F4',
		padding: '16px 0px 13px 0px',
		opacity: 0.8,
		'&:hover': {
			'& $listEditIconButton': {
				display: 'block',
			},
		},
	},
	absent: {
		backgroundColor: '#FFF3F2',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		color: '#EA4335',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	pending: {
		backgroundColor: '#FFF9E2',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		color: '#EF9F20',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	deleteIconButton: {
		color: '#EA4335',
		marginLeft: '6px',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	Weekend: {
		backgroundColor: '#ECF1F4',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		color: '#888888',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
		marginTop: '-4px',
	},
	infoIcon: {
		width: '12px',
		height: '12px',
	},
}));

interface WorkHours {
	in_time?: string;
	out_time?: string;
	attendanceDate?: string;
	timesheetDate?: string;
	timesheetHours?: string | null;
	id?: string;
	date: Dayjs;
}

interface AttendanceProps {
	attendanceDate?: string;
	in_time?: string;
	out_time?: string;
	id?: string;
	date: Dayjs;
}

interface Props {
	refetch: () => void;
	userId: string;
	date: Dayjs[];
	currentMonth: SelectedMonthDates;
	isManagerView?: boolean;
	isReportingManagerView?: boolean;
	userLocationId?: string | null;
	leaveData?: LeaveData[];
}

interface TimesheetProps {
	working_hours?: string;
	date?: string;
}

interface LeaveData {
	date: string | undefined | null;
	halfDay: number | undefined | null;
	reason: string | undefined | null;
	leave_type?: string | undefined | null;
}
const Attendance = (props: Props) => {
	const {
		permissions: permissionList,
		orgId,
		dateFormat,
		locationId,
		noOfLocations,
		attendanceConsiderableTimesheetStatus,
	} = useContext<any>(UserProfileContext);
	const {
		refetch,
		userId,
		date,
		currentMonth,
		isManagerView,
		isReportingManagerView,
		userLocationId,
		leaveData,
	} = props;
	dayjs.extend(duration);
	dayjs.extend(isSameOrBefore);
	dayjs.extend(isSameOrAfter);
	const [currentMonthHolidaysLookup, setCurrentMonthHolidaysLookup] =
		useState<any>({});
	const [weekendDates, setWeekendDates] = useState<string[]>([]);
	const [currentMonthAttendanceLookup, setCurrentMonthAttendanceLookup] =
		useState<Date>({});
	const [attendanceList, setAttendanceList] = useState<WorkHours[]>([]);
	const [isAttendanceEditModalOpen, setIsAttendanceEditModalOpen] =
		useState(false);
	const [isAttendanceRequestModalOpen, setIsAttendanceRequestModalOpen] =
		useState(false);
	const [attendanceId, setAttendanceId] = useState<string | undefined | null>(
		null
	);
	const [isWarningModalShown, setIsWarningModalShown] = useState(false);
	const [attendanceTime, setAttendanceTime] = useState({});
	const [leaveDates, setLeaveDates] =
		useState<({ date: string; halfDay: number } | undefined)[]>();
	const [employeeLeave, setEmployeeLeave] = useState<{ [key: string]: string }>(
		{}
	);
	const classes = Style();
	const notify = useNotify();
	const [
		attendancePendingRequestDateList,
		setAttendancePendingRequestDateList,
	] = useState<string[] | null>([]);
	const [
		attendanceApprovedRequestDateList,
		setAttendanceApprovedRequestDateList,
	] = useState<string[] | null>([]);
	const [deleteAttendance] = useDeleteAttendanceMutation();
	const { data: user } = useGetEmployeeJoinDateByUserIdQuery({
		variables: { user_id: userId },
		fetchPolicy: 'network-only',
	});
	const userJoinDate =
		head(user?.user)?.user_type === 'employee'
			? head(user?.user)?.employee?.join_date || null
			: head(head(user?.user)?.contractors)?.join_date || null;

	const { data: attendanceRegularizationSettings } =
		useGetAttendanceRegularizationSettingsQuery({
			variables: {
				organizationId: orgId,
			},
		});

	const isAttendanceRegularizationEnabled =
		attendanceRegularizationSettings?.organization_by_pk
			?.enable_attendance_regularization || false;

	const {
		data: currentMonthAttendances,
		refetch: refetchCurrentMonthAttendance,
	} = useGetCurrentMonthAttendanceQuery({
		variables: {
			userId,
			startDate: currentMonth?.startDate,
			endDate: currentMonth?.endDate,
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: userAttendanceRequestList,
		refetch: refetchAttendanceRequestList,
	} = useGetAttendanceRegularizationListByUserIdQuery({
		variables: {
			pendingFilter: {
				submitted_by: { _eq: userId },
				date: { _gte: currentMonth?.startDate, _lte: currentMonth?.endDate },
				attendanceStatus: { value: { _eq: 'submitted' } },
			},
			approvedFilter: {
				submitted_by: { _eq: userId },
				date: { _gte: currentMonth?.startDate, _lte: currentMonth?.endDate },
				attendanceStatus: { value: { _eq: 'approved' } },
			},
		},
		fetchPolicy: 'network-only',
		skip: !isAttendanceRegularizationEnabled,
	});
	const { data: timesheetList, refetch: refetchTimesheetList } =
		useGetTimesheetListQuery({
			variables: {
				userId,
				startDate: currentMonth?.startDate,
				endDate: currentMonth?.endDate,
				statusList: attendanceConsiderableTimesheetStatus,
			},
			fetchPolicy: 'network-only',
		});
	const { data: currentMonthHolidays } = useGetOrgHolidaysByUserQuery({
		variables: {
			filter:
				isManagerView || isReportingManagerView
					? userLocationId && noOfLocations && noOfLocations > 0
						? {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								location_id: { _eq: userLocationId },
								is_restricted: { _eq: false },
						  }
						: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								is_restricted: { _eq: false },
						  }
					: locationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: currentMonth?.startDate,
								_lte: currentMonth?.endDate,
							},
							location_id: { _eq: locationId },
							is_restricted: { _eq: false },
					  }
					: {
							date: {
								_gte: currentMonth?.startDate,
								_lte: currentMonth?.endDate,
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				isManagerView || isReportingManagerView
					? userLocationId && noOfLocations && noOfLocations > 0
						? {
								user_id: { _eq: userId },
								org_holiday: {
									date: {
										_gte: currentMonth?.startDate,
										_lte: currentMonth?.endDate,
									},
									location_id: { _eq: userLocationId },
								},
						  }
						: {
								user_id: { _eq: userId },
								org_holiday: {
									date: {
										_gte: currentMonth?.startDate,
										_lte: currentMonth?.endDate,
									},
								},
						  }
					: locationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
								location_id: { _eq: locationId },
							},
					  }
					: {
							user_id: { _eq: userId },
							org_holiday: {
								date: {
									_gte: currentMonth?.startDate,
									_lte: currentMonth?.endDate,
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
	});
	const { data: organizationWeekendData } = useGetOrganizationWeekendsQuery({
		variables: { orgId },
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (!organizationWeekendData) {
			setWeekendDates([]);
			return;
		}
		const isSundayAvailable =
			organizationWeekendData?.organization_by_pk?.weekend?.includes('SUN');
		const sundayAsWeekend = getSundaysBetweenDates(
			currentMonth?.startDate,
			currentMonth?.endDate
		);
		const availableSaturdays =
			organizationWeekendData?.organization_by_pk?.weekend
				?.filter((weekendDay: string) => weekendDay !== 'SUN')
				?.map((sat: string) => sat?.charAt(sat.length - 1));
		const allSaturdaysBetweenDates = getSaturdaysDates(
			currentMonth?.startDate,
			currentMonth?.endDate
		);

		const saturdayAsWeekend = allSaturdaysBetweenDates
			?.map((day) =>
				availableSaturdays?.includes(`${day?.ordinal}`) ? day?.date : ''
			)
			?.filter((date) => date !== '');
		const weekends = isSundayAvailable
			? [...saturdayAsWeekend, ...sundayAsWeekend]
			: [...saturdayAsWeekend];
		setWeekendDates(weekends);
	}, [
		currentMonth,
		locationId,
		noOfLocations,
		isManagerView,
		isReportingManagerView,
		userLocationId,
		organizationWeekendData,
	]);

	const { data: leaveSummaryData, refetch: refetchLeaveSummary } =
		useCurrentMonthAttendanceLeaveInfoQuery({
			variables: {
				userId,
				startDate: currentMonth?.startDate,
				endDate: currentMonth?.endDate,
			},
			fetchPolicy: 'network-only',
		});
	useEffect(() => {
		if (!leaveSummaryData) {
			return;
		}
		const leaveCountData = leaveSummaryData
			? head(leaveSummaryData?.approvedLeaveCount)?.employee_leaving_mappings
				? head(leaveSummaryData?.approvedLeaveCount)?.employee_leaving_mappings
				: []
			: [];
		const userLeaveDays = calculatedLeaveCountInSummaryInLeaveData(
			currentMonth,
			leaveCountData
		);

		const leaveDaysArray = flatten(userLeaveDays);
		const leaveDays = compact(leaveDaysArray);
		setLeaveDates(leaveDays);
	}, [currentMonth, leaveSummaryData]);

	useEffect(() => {
		if (!leaveData) {
			setEmployeeLeave({});
			return;
		}
		const leaveDay = leaveData?.map((item: LeaveData) => item?.date);
		const leaveDaysLookup = keyBy(leaveDay);
		setEmployeeLeave(leaveDaysLookup as { [key: string]: string });
	}, [leaveData]);

	useEffect(() => {
		if (
			!currentMonthAttendances ||
			!(currentMonthAttendances?.attendance?.length > 0)
		) {
			return;
		}
		const attendance = currentMonthAttendances?.attendance.map(
			(value: AttendanceList) => value.date
		);
		const attendanceLookup = keyBy(attendance);
		setCurrentMonthAttendanceLookup(attendanceLookup);
	}, [currentMonthAttendances]);

	useEffect(() => {
		if (!currentMonthHolidays) {
			return;
		}
		const holiday =
			currentMonthHolidays?.org_holidays?.map(
				(holiday: Holiday) => holiday?.date
			) || [];
		const optionalHoliday = currentMonthHolidays?.user_optional_holiday_mapping
			? currentMonthHolidays?.user_optional_holiday_mapping?.map(
					(holiday) => holiday?.org_holiday?.date
			  )
			: [];
		const holidayLookup = keyBy([...holiday, ...optionalHoliday]);
		isManagerView || isReportingManagerView
			? !userLocationId && noOfLocations && noOfLocations > 0
				? setCurrentMonthHolidaysLookup([])
				: setCurrentMonthHolidaysLookup(holidayLookup)
			: !locationId && noOfLocations && noOfLocations > 0
			? setCurrentMonthHolidaysLookup([])
			: setCurrentMonthHolidaysLookup(holidayLookup);
	}, [
		currentMonthHolidays,
		isManagerView,
		isReportingManagerView,
		locationId,
		noOfLocations,
		userLocationId,
	]);

	useEffect(() => {
		if (!userAttendanceRequestList) {
			return;
		}
		const pendingDateList =
			userAttendanceRequestList?.pendingRequestList?.map(
				(value) => value?.date
			) || [];
		setAttendancePendingRequestDateList(pendingDateList);
		const approvedDateList =
			userAttendanceRequestList?.approvedRequestList?.map(
				(value) => value?.date
			) || [];
		setAttendanceApprovedRequestDateList(approvedDateList);
	}, [userAttendanceRequestList]);
	useEffect(() => {
		const attendanceList = date.map((date: Dayjs) => {
			const attendance = currentMonthAttendances?.attendance?.find(
				(attendance: AttendanceList) => date.isSame(attendance?.date, 'date')
			);
			const timesheet = timesheetList?.timesheet?.find(
				(value: TimesheetProps) => date.isSame(value?.date, 'date')
			);
			const timesheetHours = timesheetList?.timesheet
				?.filter((value: TimesheetProps) => date.isSame(value?.date, 'date'))
				.map((timeSheet) => timeSheet?.working_hours?.split(':'))
				.map((hoursAndMinutes: string[]) => {
					return dayjs.duration({
						hours: Number(hoursAndMinutes[0]),
						minutes: Number(hoursAndMinutes[1]),
						seconds: Number(hoursAndMinutes[2]),
					});
				})
				.reduce(
					(total: any, durations: Duration) => total.add(durations),
					dayjs.duration({
						hours: 0,
						minutes: 0,
						seconds: 0,
					})
				);
			return {
				in_time: attendance?.in_time,
				out_time: attendance?.out_time,
				date,
				attendanceDate: attendance?.date,
				timesheetDate: timesheet?.date,
				timesheetHours: timesheet?.date
					? timesheetHours?.format('HH:mm')
					: null,
				id: attendance?.id,
			};
		});
		setAttendanceList(attendanceList);
	}, [currentMonthAttendances, date, timesheetList]);

	const getWorkHours = (date: WorkHours) => {
		if (!date?.out_time) {
			return;
		}
		const dates = date?.attendanceDate;
		const in_time = dayjs(`${dates} ${date?.in_time}`);
		const out_time = dayjs(`${dates} ${date?.out_time}`);
		const diff = dayjs.duration(out_time.diff(in_time)).format('HH:mm');
		return diff;
	};

	const getAttendance = (attendance: AttendanceProps) => {
		if (!attendance || !attendance?.id) {
			setAttendanceTime({
				date: attendance.date.format('YYYY-MM-DD'),
				in_time: null,
				out_time: null,
			});
			setIsAttendanceEditModalOpen(true);
			return;
		}
		setAttendanceTime({
			in_time: dayjs(
				`${attendance?.attendanceDate} ${attendance?.in_time}`
			).format('HH:mm'),
			out_time: attendance?.out_time
				? dayjs(`${attendance?.attendanceDate} ${attendance?.out_time}`).format(
						'HH:mm'
				  )
				: null,
			id: attendance?.id,
			date: attendance.date.format('YYYY-MM-DD'),
		});
		setIsAttendanceEditModalOpen(true);
	};

	const handleRefetech = () => {
		refetch();
		refetchCurrentMonthAttendance();
		refetchLeaveSummary();
		refetchAttendanceRequestList();
		refetchTimesheetList();
	};

	const handleDeleteAttendance = () => {
		deleteAttendance({
			variables: {
				attendanceId,
			},
		})
			.then((data: any) => {
				notify('Attendance deleted successfully');
				setAttendanceId(null);
				setIsWarningModalShown(false);
				handleRefetech();
			})
			.catch((error: any) => {
				notify(`${error.message}`);
				setAttendanceId(null);
				setIsWarningModalShown(false);
			});
	};
	const onAttendanceRegularizationEdit = (attendance: WorkHours) => {
		if (!attendance || !attendance?.id) {
			setAttendanceTime({
				date: attendance.date.format('YYYY-MM-DD'),
				in_time: null,
				out_time: null,
			});
			setIsAttendanceRequestModalOpen(true);
			return;
		}
		setAttendanceTime({
			in_time: dayjs(
				`${attendance?.attendanceDate} ${attendance?.in_time}`
			).format('HH:mm'),
			out_time: attendance?.out_time
				? dayjs(`${attendance?.attendanceDate} ${attendance?.out_time}`).format(
						'HH:mm'
				  )
				: null,
			id: attendance?.id,
			date: attendance.date.format('YYYY-MM-DD'),
		});
		setIsAttendanceRequestModalOpen(true);
	};
	return (
		<>
			<Box className={classes.attendanceContainer}>
				<Box className={classes.label} marginLeft='32px'>
					Date
				</Box>
				<Box className={classes.label} marginLeft='102px'>
					Attendance
				</Box>
				<Box className={classes.label} marginLeft='76px'>
					Check in
				</Box>
				<Box className={classes.label} marginLeft='76px'>
					Check Out
				</Box>
				<Box className={classes.label} marginLeft='76px'>
					Worked Hours
				</Box>
				<Box className={classes.label} marginLeft='76px'>
					<Box display='flex'>
						<Box>Timesheet Hours</Box>
						<Box>
							<Tooltip
								title={
									attendanceConsiderableTimesheetStatus
										? `Timesheets which are in ${(
												attendanceConsiderableTimesheetStatus || []
										  ).toString()} status will be considered for attendance`
										: ''
								}
								placement='right'
							>
								<IconButton className={classes.iconContainer}>
									<InfoIcon className={classes.infoIcon} />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box>
				{attendanceList.map((attendance: WorkHours, index: number) => {
					const calenderDate = attendance.date.format('YYYY-MM-DD');
					const optionalHolidays =
						currentMonthHolidays?.user_optional_holiday_mapping
							? currentMonthHolidays?.user_optional_holiday_mapping?.map(
									(holiday) => holiday?.org_holiday
							  )
							: [];
					const holidaysForDate =
						[
							...(currentMonthHolidays?.org_holidays || []),
							...optionalHolidays,
						]?.filter((holiday) => holiday?.date === calenderDate) || [];
					const holidayNames = holidaysForDate?.map(
						(holiday) => holiday?.name || '--'
					);
					const holidayLocation = holidaysForDate?.map(
						(holiday) => holiday?.org_location?.name || holidayText
					);
					const LeaveDate = leaveDates?.length
						? leaveDates.filter((item) => item?.date === calenderDate)
						: [];
					const Leavetype = (head(LeaveDate) as LeaveData)?.leave_type || '';
					const leaveTime =
						(head(LeaveDate) as LeaveData)?.halfDay === 0.5 ? halfDay : '';
					return (
						<Box
							key={index}
							className={
								dayjs().isSame(attendance?.date, 'day')
									? classes.todaysDate
									: index % 2 !== 0
									? classes.container
									: classes.todayDateHighlight
							}
						>
							<Box className={classes.date}>
								{attendance.date.format(dateFormat)}
							</Box>
							<Box>
								{employeeLeave[attendance?.date.format('YYYY-MM-DD')] ? (
									<Box className={classes.attendanceStatus}>
										<Tooltip
											title={`${Leavetype} ${leaveTime}`}
											placement='right'
										>
											<Box className={classes.leave} color='#f98203'>
												{Leave}
											</Box>
										</Tooltip>
									</Box>
								) : dayjs(attendance?.date).isSameOrBefore(dayjs(), 'day') &&
								  currentMonthHolidaysLookup[
										attendance?.date.format('YYYY-MM-DD')
								  ] &&
								  (attendance?.timesheetDate ||
										(attendance?.out_time &&
											currentMonthAttendanceLookup[
												attendance?.date.format('YYYY-MM-DD')
											])) ? (
									<Box className={classes.attendanceStatus}>
										<Tooltip
											title={`${Present} (${holidayLocation}:${holidayNames})`}
											placement='right'
										>
											<Box className={classes.attendance} color='#34A853'>
												{Present}
											</Box>
										</Tooltip>
									</Box>
								) : currentMonthAttendanceLookup[
										attendance?.date.format('YYYY-MM-DD')
								  ] &&
								  (attendance?.out_time || attendance?.timesheetDate) ? (
									<Box className={classes.attendanceStatus}>
										{weekendDates?.includes(calenderDate) ? (
											<Tooltip
												title={`${Present} (${Weekend})`}
												placement='right'
											>
												<Box className={classes.attendance} color='#34A853'>
													{Present}
												</Box>
											</Tooltip>
										) : employeeLeave[attendance?.date.format('YYYY-MM-DD')] ? (
											<Box className={classes.attendanceStatus}>
												<Tooltip
													title={`${Leavetype} ${leaveTime}`}
													placement='right'
												>
													<Box className={classes.leave} color='#f98203'>
														{Leave}
													</Box>
												</Tooltip>
											</Box>
										) : (
											<Tooltip title={Present} placement='right'>
												<Box className={classes.attendance} color='#34A853'>
													{Present}
												</Box>
											</Tooltip>
										)}
									</Box>
								) : (
									<Box>
										{currentMonthHolidaysLookup[
											attendance?.date?.format('YYYY-MM-DD')
										] ? (
											<Box className={classes.attendanceStatus}>
												<Tooltip
													title={`${holidayLocation}: ${holidayNames}`}
													placement='right'
												>
													<Box
														className={classes.holiday}
														color='rgb(249 181 3)'
													>
														{holidayText}
													</Box>
												</Tooltip>
											</Box>
										) : employeeLeave[attendance?.date.format('YYYY-MM-DD')] ? (
											<Box className={classes.attendanceStatus}>
												<Tooltip
													title={`${Leavetype} ${leaveTime}`}
													placement='right'
												>
													<Box className={classes.leave} color='#f98203'>
														{Leave}
													</Box>
												</Tooltip>
											</Box>
										) : (
											<Box>
												{weekendDates?.includes(calenderDate) &&
												(attendance?.timesheetDate || attendance?.out_time) ? (
													<Box className={classes.attendanceStatus}>
														<Tooltip
															title={`${Present} (${Weekend})`}
															placement='right'
														>
															<Box
																className={classes.attendance}
																color='#34A853'
															>
																{Present}
															</Box>
														</Tooltip>
													</Box>
												) : weekendDates?.includes(calenderDate) ? (
													<Box className={classes.attendanceStatus}>
														<Tooltip title={Weekend} placement='right'>
															<Box className={classes.Weekend}>{Weekend}</Box>
														</Tooltip>
													</Box>
												) : ((currentMonthAttendanceLookup[
														attendance?.date.format('YYYY-MM-DD')
												  ] &&
														attendance?.out_time) ||
														attendance?.timesheetDate) &&
												  dayjs(attendance?.date).isSameOrBefore(
														dayjs(),
														'day'
												  ) ? (
													<Box className={classes.attendanceStatus}>
														<Tooltip title={Present} placement='right'>
															<Box
																className={classes.attendance}
																color='#34A853'
															>
																{Present}
															</Box>
														</Tooltip>
													</Box>
												) : !attendance?.out_time &&
												  attendance.date.isBefore(
														dayjs().format('YYYY-MM-DD'),
														'date'
												  ) ? (
													<Box className={classes.attendanceStatus}>
														<Tooltip title={Absent} placement='right'>
															<Box className={classes.absent}>{Absent}</Box>
														</Tooltip>
													</Box>
												) : (
													<Box className={classes.attendanceStatus}>-</Box>
												)}
											</Box>
										)}
									</Box>
								)}
							</Box>
							<Box className={classes.attendanceTime}>
								{attendance?.in_time ? (
									<Box>
										{dayjs(
											`${attendance?.attendanceDate} ${attendance?.in_time}`
										).format('HH:mm A')}
									</Box>
								) : (
									<Box>-</Box>
								)}
							</Box>
							<Box className={classes.attendanceTime}>
								{attendance?.out_time ? (
									<Box>
										{dayjs(
											`${attendance?.attendanceDate} ${attendance?.out_time}`
										).format('HH:mm A')}
									</Box>
								) : (
									<Box>-</Box>
								)}
							</Box>
							<Box className={classes.attendanceTime}>
								{attendance?.out_time ? (
									<Box>{getWorkHours(attendance)}</Box>
								) : (
									<Box>-</Box>
								)}
							</Box>
							<Box className={classes.attendanceTime} marginLeft='10px'>
								{!dayjs().isSameOrBefore(attendance?.date, 'day') &&
								attendance?.timesheetHours ? (
									<Box>{attendance?.timesheetHours}</Box>
								) : dayjs().isSame(attendance?.date, 'day') &&
								  attendance?.timesheetHours ? (
									<Box>{attendance?.timesheetHours}</Box>
								) : (
									<Box>-</Box>
								)}
							</Box>
							{!isReportingManagerView ? (
								<Box>
									{isManagerView ? (
										permissionList?.appPermissions?.ui?.editOthersAttendance &&
										attendance.date.isSameOrBefore(
											dayjs().format('YYYY-MM-DD'),
											'date'
										) &&
										(!userJoinDate || calenderDate >= userJoinDate) && (
											<Box display='flex' alignItems='center'>
												<Box
													onClick={() => getAttendance(attendance)}
													className={classes.listEditIconContainer}
													id={`edit_attendance_${index}`}
												>
													<IconButton className={classes.listEditIconButton}>
														<EditIcon className={classes.editIcon} />
													</IconButton>
												</Box>
												{attendance?.id && (
													<Box className={classes.listEditIconContainer}>
														<IconButton
															id={`delete_attendance_${index}`}
															className={`${classes.listEditIconButton} ${classes.deleteIconButton}`}
															onClick={() => {
																setAttendanceId(attendance?.id);
																setIsWarningModalShown(true);
															}}
														>
															<DeleteIcon className={classes.editIcon} />
														</IconButton>
													</Box>
												)}
												{isAttendanceRegularizationEnabled &&
													attendancePendingRequestDateList?.includes(
														dayjs(attendance?.date).format('YYYY-MM-DD')
													) && (
														<Box display='flex'>
															<Box className={classes.attendanceStatus}>
																<Tooltip
																	title={ATTENDANCE_PENDING_STATUS_MESSAGE}
																	placement='right'
																>
																	<Box className={classes.pending}>
																		{'Pending'}
																	</Box>
																</Tooltip>
															</Box>
														</Box>
													)}
											</Box>
										)
									) : isAttendanceRegularizationEnabled ? (
										<Box>
											{dayjs(attendance?.date).isSameOrAfter(
												dayjs(attendance?.date).startOf('month')
											) &&
												dayjs(attendance?.date).isSameOrBefore(dayjs()) &&
												(!userJoinDate || calenderDate >= userJoinDate) && (
													<Box display='flex' alignItems='center'>
														{!attendancePendingRequestDateList?.includes(
															dayjs(attendance?.date).format('YYYY-MM-DD')
														) &&
															!attendanceApprovedRequestDateList?.includes(
																dayjs(attendance?.date).format('YYYY-MM-DD')
															) && (
																<Box
																	onClick={() => {
																		const isCurrentDateIsToday =
																			dayjs(attendance?.date).format(
																				'YYYY-MM-DD'
																			) === dayjs().format('YYYY-MM-DD');
																		isCurrentDateIsToday
																			? getAttendance(attendance)
																			: onAttendanceRegularizationEdit(
																					attendance
																			  );
																	}}
																	id={`edit_attendance_${index}`}
																	className={classes.listEditIconContainer}
																>
																	<IconButton
																		className={classes.listEditIconButton}
																	>
																		<EditIcon className={classes.editIcon} />
																	</IconButton>
																</Box>
															)}

														{attendancePendingRequestDateList?.includes(
															dayjs(attendance?.date).format('YYYY-MM-DD')
														) && (
															<Box display='flex'>
																<Box className={classes.attendanceStatus}>
																	<Tooltip
																		title={ATTENDANCE_PENDING_STATUS_MESSAGE}
																		placement='right'
																	>
																		<Box className={classes.pending}>
																			{'Pending'}
																		</Box>
																	</Tooltip>
																</Box>
															</Box>
														)}
													</Box>
												)}
										</Box>
									) : (
										<Box>
											{attendance.date.isSameOrAfter(
												dayjs().startOf('month').format('YYYY-MM-DD'),
												'date'
											) &&
												attendance.date.isSameOrBefore(
													dayjs().format('YYYY-MM-DD'),
													'date'
												) &&
												(!userJoinDate || calenderDate >= userJoinDate) && (
													<Box display='flex' alignItems='center'>
														<Box
															onClick={() => getAttendance(attendance)}
															id={`edit_attendance_${index}`}
															className={classes.listEditIconContainer}
														>
															<IconButton
																className={classes.listEditIconButton}
															>
																<EditIcon className={classes.editIcon} />
															</IconButton>
														</Box>
														{attendance?.id && (
															<Box className={classes.listEditIconContainer}>
																<IconButton
																	className={`${classes.listEditIconButton} ${classes.deleteIconButton}`}
																	onClick={() => {
																		setAttendanceId(attendance?.id);
																		setIsWarningModalShown(true);
																	}}
																	id={`delete_attendance_${index}`}
																>
																	<DeleteIcon className={classes.editIcon} />
																</IconButton>
															</Box>
														)}
													</Box>
												)}
										</Box>
									)}
								</Box>
							) : (
								isAttendanceRegularizationEnabled &&
								attendancePendingRequestDateList?.includes(
									dayjs(attendance?.date).format('YYYY-MM-DD')
								) && (
									<Box display='flex'>
										<Box className={classes.attendanceStatus}>
											<Tooltip
												title={ATTENDANCE_PENDING_STATUS_MESSAGE}
												placement='right'
											>
												<Box className={classes.pending}>{'Pending'}</Box>
											</Tooltip>
										</Box>
									</Box>
								)
							)}
						</Box>
					);
				})}
			</Box>
			<Box>
				<AttendanceEditForm
					open={isAttendanceEditModalOpen}
					onClose={() => setIsAttendanceEditModalOpen(false)}
					attendance={attendanceTime}
					refetch={handleRefetech}
					userId={userId}
				/>
				<AttendanceRequestForm
					open={isAttendanceRequestModalOpen}
					onClose={() => setIsAttendanceRequestModalOpen(false)}
					attendance={attendanceTime}
					refetch={handleRefetech}
					userId={userId}
				/>
			</Box>
			<DeleteModal
				open={isWarningModalShown}
				onClose={() => {
					setIsWarningModalShown(false);
					setAttendanceId(null);
				}}
				confirmationMessage={'Do you really want to delete this Attendance?'}
				onDelete={() => {
					handleDeleteAttendance();
				}}
			/>
		</>
	);
};

export default Attendance;
