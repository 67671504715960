import React, { useContext, useEffect, useState } from 'react';

import {
	Box,
	Checkbox,
	ThemeProvider,
	Tooltip,
	makeStyles,
	withStyles,
	TextField,
	InputAdornment,
	IconButton,
	Card,
	CardContent,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableContainer,
	Typography,
	TextField as TextComponent,
	TablePagination,
	createTheme,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { autocompleteMultiSelectLabelStyle } from '../style';
import { UserProfileContext } from '../../../App';
import {
	ReferenceInput,
	setSidebarVisibility,
	AutocompleteInput,
	TextInput,
	SelectInput,
	NumberInput,
	minValue,
	Loading,
} from 'react-admin';
import { useQuery as useApolloQuery } from '@apollo/client';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EventIcon from '@material-ui/icons/Event';
import DateFnsUtils from '@date-io/dayjs';
import { dateTimePickerTheme } from '../../Attendance/Style';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Form } from 'react-final-form';
import _, { head, orderBy, uniqBy } from 'lodash';
import { Duration } from 'dayjs/plugin/duration';

import {
	InsightReportTableGlobalStyles,
	ellipsisStyle,
	insightViewStyle,
	modalFormStyle,
	autocompleteStyle,
} from '../../../Layout/styles';
import {
	useGetGeneralAndProjectAssignedResourceListQuery,
	useGetNormalAndGeneralTimesheetEntriesForProjectWiseReportQuery,
	useGetNormalAndGeneralUnSubmittedAndDraftTimesheetEntriesForProjectWiseReportQuery,
	useGetOvertimeAndGeneralOvertimeForProjectWiseReportQuery,
	useGetProjectListQuery,
	useGetTimesheetDayTypeOptionsQuery,
	useGetTimesheetStatusQuery,
} from '../../../generated/graphql';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	autoCompleteFilterOption,
	formatEmployeeId,
	exportToCsv,
	formatDecimal,
	getPrefix,
	isFloat,
	getMaxBillRate,
} from '../../../Utils/string.util';
import { START_DATE_ERROR, timesheetTypes, uuidValidator } from '../constant';
import { CustomTableCell } from '../../../SharedComponents/CustomTableCell.component';
import { AutocompleteSearch } from '../../../SharedComponents/Autocompletesearch.component';
import {
	GET_USER_TEAMS,
	GET_USER_BY_TEAMS,
} from '../../ResourcePlanner/gqlQueries';
import { calculateTotalHoursFromTimesheet } from './insight.utils';
import ExportButtonWithDropDown from '../../../SharedComponents/ExportButton/ExportButtonWithDropDown';
import { exportTimesheetReportUsersOptions } from '../../../config/constant';

const useStyles = makeStyles((theme) => ({
	reducedValueContainer: {
		width: '95px',
		height: '48px',
		background: '#F7F9FA',
		borderRadius: '4px',
		padding: '6px 0px 6px 6px',
		marginRight: '16px',
	},
	exportButtonDiv: {
		marginTop: '23px',
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '250px',
	},
}));

const filterMetricInputTheme = createTheme({
	overrides: {
		MuiFilledInput: {
			underline: {
				'&&&:before': {
					borderBottom: 'none',
				},
				'&&:after': {
					borderBottom: 'none',
				},
			},
			root: {
				border: '1px solid #E0E0E0',
				width: '100px',
				height: '36px',
				fontFamily: 'Manrope-Medium',
				fontSize: '13px',
				paddingBottom: '13px',
				borderTopRightRadius: '0 !important',
			},
		},
		MuiFormControl: {
			marginDense: {
				marginTop: '0px',
			},
		},
	},
});

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);
const CountCard = ({
	label,
	value,
}: {
	label: string;
	value: number | string;
}) => {
	const classes = insightViewStyle();
	return (
		<Box className={classes.reducedValueContainer}>
			<Box className={classes.countLabel}>{label}</Box>
			<Box className={classes.countValue}>{value}</Box>
		</Box>
	);
};
interface TotalHrs {
	totalHours: number | null;
	billableHours: number | null;
	nonBillableHours: number | null;
}
interface Timesheet {
	id?: any;
	resourceName: string | null | undefined;
	reportTo?: string | null;
	department?: string | null;
	reportToEmail?: string | null;
	uniqueId?: string | undefined;
	projectName: string | undefined;
	projectOwner: string | undefined;
	projectOwnerEmail: string | undefined;
	projectStatus: string | undefined;
	projectStartDate: string | undefined | null;
	projectEndDate: string | undefined | null;
	totalHours: number;
	billableWorkingHours: number;
	nonBillableWorkingHours: number;
	userId: any;
	orgLocation: string | undefined;
	empId: string;
	billableStatus?: string | true;
}
export const TimesheetProjectWiseReport = () => {
	// State variables
	const [projectId, setProjectId] = useState<string[]>();
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const [startDateError, setStartDateError] = useState(false);
	const [project, setProject] = useState<any>([]);
	const [accumulatedTotalHours, setAccumulatedTotalHours] =
		useState<TotalHrs>();
	const [companyId, setCompanyId] = useState<string>();
	const [selectedProjectOptionValue, setSelectedProjectOptionValue] =
		useState<(any | null)[]>();
	const [selectedTimesheetStatusIdList, setSelectedTimesheetStatusIdList] =
		useState<string[]>([]);
	const [timeSheetTableList, setTimeSheetTableList] = useState<any[]>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [userTeamId, setUserTeamId] = useState<string | null>(null);
	const [selectedUserTeam, setSelectedUserTeam] = useState<{
		id: string;
		name: string;
	} | null>(null);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<any>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<
		string[]
	>([]);
	const [assigneeRecommendations, setAssigneeRecommendations] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [totalHoursFilterValue, setTotalHoursFiltervalue] = useState<
		number | null
	>(null);
	const [totalHoursFilterOperator, setTotalHoursFilterOperator] = useState<
		string | null
	>(null);
	const [timesheetType, setTimesheetType] = useState<string>('All');
	const [dayTypeOptions, setDayTypeOptions] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);

	const [selectedDayTypeOptionValue, setSelectedDayTypeOptionValues] =
		useState<any>([]);
	const [selectedDayTypeIdList, setSelectedDayTypeIdList] = useState<string[]>(
		[]
	);
	const [timesheetStatusOptions, setTimesheetStatusOptions] = useState<
		{ id?: string | null; name?: string | null }[]
	>([]);
	const [
		selectedTimesheetStatusOptionValue,
		setSelectedTimesheetStatusOptionValues,
	] = useState<any>([]);

	//Styles
	const classes = insightViewStyle();
	const style = useStyles();
	const styles = modalFormStyle();
	const ellipsisstyle = ellipsisStyle();
	const inlinestyle = autocompleteMultiSelectLabelStyle();
	const commonStyle = InsightReportTableGlobalStyles();
	const ellipsis = ellipsisStyle();
	const dropDownStyle = autocompleteStyle();

	// Variables
	const dispatch = useDispatch();
	const {
		dateFormat,
		featureFlag,
		isOvertimeEnabled,
		isTrackDayTypeEnabled,
		isDraftTimesheetEnabled,
		noOfLocations,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	} = useContext<any>(UserProfileContext);

	const allSelected =
		project?.length === selectedProjectOptionValue?.length || 0 ? true : false;

	// Query Functions
	const getProjectIdForProjectTimesheetFilter = () => {
		if (!projectId) {
			return;
		}
		const selectedProjectIdList = projectId?.filter((item) =>
			uuidValidator.test(item)
		);
		return selectedProjectIdList;
	};

	const getDayTypePayload = () => {
		if (selectedDayTypeIdList && selectedDayTypeIdList?.length > 0) {
			const selectedOptionList =
				selectedDayTypeOptionValue && selectedDayTypeOptionValue?.length > 0
					? selectedDayTypeOptionValue?.map(
							(option: { id: string }) => option?.id
					  )
					: [];
			if (selectedOptionList?.includes('All')) {
				return {};
			} else return { _in: selectedDayTypeIdList };
		}
		return {};
	};

	//GQL Queries

	//Get project list for dropdown options
	const { data: projectList } = useGetProjectListQuery({
		variables: {
			filters: {
				company_id: companyId ? { _eq: companyId } : {},
			},
		},
	});

	// Get timesheet status for dropdown options
	const { data: timesheetStatus } = useGetTimesheetStatusQuery({
		skip: !isDraftTimesheetEnabled,
	});

	// Get timesheet status for dropdown options
	const { data: dayTypeOptionList } = useGetTimesheetDayTypeOptionsQuery({
		fetchPolicy: 'network-only',
		skip: !isTrackDayTypeEnabled,
	});

	// Get user teams for dropdown options
	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});

	// Get resources by user teams for dropdown options
	const { data: usersByTeam } = useApolloQuery(GET_USER_BY_TEAMS, {
		variables: {
			teamId: !userTeamId
				? {}
				: {
						_eq: userTeamId,
				  },
		},
		skip: !userTeamId,
		fetchPolicy: 'network-only',
	});

	// Get resource list for resource dropdown
	const { data: generalAndProjectResourceList } =
		useGetGeneralAndProjectAssignedResourceListQuery({
			variables: {
				filters: {
					org_task: {
						task_category: { _in: projectId || [] },
					},
					date:
						startDate && endDate
							? {
									_gte: dayjs(startDate).format('YYYY-MM-DD'),
									_lte: dayjs(endDate).format('YYYY-MM-DD'),
							  }
							: {},
					timesheet_submission: {
						timesheet_status: {
							value: {
								_in: selectedTimesheetStatusIdList || ['submitted', 'approved'],
							},
						},
					},
				},
				projectResourceMappingFilter: {
					project_id: { _in: getProjectIdForProjectTimesheetFilter() },
					deleted_at: { _is_null: true },
				},
			},
			fetchPolicy: 'network-only',
			skip: !projectId,
		});

	// Get General and Project Timesheet Entries.
	const {
		data: generalAndProjectTimesheetEntries,
		loading: isGeneralAndProjectTimesheetEntries,
	} = useGetNormalAndGeneralTimesheetEntriesForProjectWiseReportQuery({
		variables: {
			filters: {
				project_id: { _in: getProjectIdForProjectTimesheetFilter() },
				timesheet_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList.filter(
											(status) => status !== 'draft'
										),
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
				day_type_id: getDayTypePayload(),
			},
			projectId: getProjectIdForProjectTimesheetFilter(),
			projectResourceMappingFilter: {
				project_id: { _in: getProjectIdForProjectTimesheetFilter() },
				deleted_at: { _is_null: true },
				employee_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
			},
			generalFilters: {
				org_task: {
					task_category: { _in: projectId || [] },
				},
				timesheet_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList,
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
				day_type_id: getDayTypePayload(),
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
			},
		},
		fetchPolicy: 'network-only',
		skip: !projectId,
	});

	// Get general and normal Overtime if enabled
	const {
		data: generalAndProjectTimesheetOvertimeEntries,
		loading: isGeneralAndProjectTimesheetOvertimeEntries,
	} = useGetOvertimeAndGeneralOvertimeForProjectWiseReportQuery({
		variables: {
			filters: {
				project_id: { _in: getProjectIdForProjectTimesheetFilter() },
				timesheet_overtime_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList.filter(
											(status) => status !== 'draft'
										),
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				day_type_id: getDayTypePayload(),
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
			},
			generalFilters: {
				org_task: {
					task_category: { _in: projectId || [] },
				},
				timesheet_overtime_submission: {
					timesheet_status: {
						value:
							selectedTimesheetStatusIdList &&
							selectedTimesheetStatusIdList.length > 0
								? {
										_in: selectedTimesheetStatusIdList.filter(
											(status) => status !== 'draft'
										),
								  }
								: { _in: ['approved', 'submitted'] },
					},
				},
				user_id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? { _in: selectedResourceIdList }
						: {},
				day_type_id: getDayTypePayload(),
				date:
					startDate && endDate
						? {
								_gte: dayjs(startDate).format('YYYY-MM-DD'),
								_lte: dayjs(endDate).format('YYYY-MM-DD'),
						  }
						: {},
			},
			projectId: getProjectIdForProjectTimesheetFilter(),
		},
		skip: !projectId || !isOvertimeEnabled,
		fetchPolicy: 'network-only',
	});

	// Get General and Project unsubmitted and draft timesheet entries.
	const {
		data: generalAndProjectUnsubmittedAndDraftStatusEntries,
		loading: isGeneralAndProjectUnsubmittedAndDraftStatusEntries,
	} =
		useGetNormalAndGeneralUnSubmittedAndDraftTimesheetEntriesForProjectWiseReportQuery(
			{
				variables: {
					projectId: getProjectIdForProjectTimesheetFilter(),
					generalUnSubmittedFilters: {
						org_task: {
							task_category: { _in: projectId || [] },
						},
						timesheet_submission_id: { _is_null: true },
						user_id:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						day_type_id: getDayTypePayload(),
						date:
							startDate && endDate
								? {
										_gte: dayjs(startDate).format('YYYY-MM-DD'),
										_lte: dayjs(endDate).format('YYYY-MM-DD'),
								  }
								: {},
					},
					generalDraftStatusFilters: {
						org_task: {
							task_category: { _in: projectId || [] },
						},
						timesheet_submission: {
							timesheet_status: {
								value: {
									_in: ['draft'],
								},
							},
						},
						user_id:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						day_type_id: getDayTypePayload(),
						date:
							startDate && endDate
								? {
										_gte: dayjs(startDate).format('YYYY-MM-DD'),
										_lte: dayjs(endDate).format('YYYY-MM-DD'),
								  }
								: {},
					},
					projectDraftStatusFilters: {
						project_id: { _in: getProjectIdForProjectTimesheetFilter() },
						timesheet_submission: {
							timesheet_status: {
								value: { _in: ['draft'] },
							},
						},
						date:
							startDate && endDate
								? {
										_gte: dayjs(startDate).format('YYYY-MM-DD'),
										_lte: dayjs(endDate).format('YYYY-MM-DD'),
								  }
								: {},
						user_id:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						day_type_id: getDayTypePayload(),
					},
					projectUnSubmittedFilters: {
						project_id: { _in: getProjectIdForProjectTimesheetFilter() },
						timesheet_submission_id: { _is_null: true },
						date:
							startDate && endDate
								? {
										_gte: dayjs(startDate).format('YYYY-MM-DD'),
										_lte: dayjs(endDate).format('YYYY-MM-DD'),
								  }
								: {},
						user_id:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? { _in: selectedResourceIdList }
								: {},
						day_type_id: getDayTypePayload(),
					},
				},
				skip:
					!projectId ||
					!isDraftTimesheetEnabled ||
					(selectedTimesheetStatusIdList &&
						selectedTimesheetStatusIdList.length > 0 &&
						!selectedTimesheetStatusIdList.includes('draft')),
				fetchPolicy: 'network-only',
			}
		);

	const handleClearOptions = () => {
		setSelectedProjectOptionValue([]);
		setProjectId([]);
	};

	const handleToggleOption = (
		selectedOptions: { project: { value: string } }[]
	) => {
		const selectedProjectIds = selectedOptions.map(
			(project: any) => project?.value || ''
		);
		setProjectId(selectedProjectIds);
		setSelectedProjectOptionValue(selectedOptions);
	};

	const handleSelectAll = (isSelected: boolean) => {
		if (!projectList) {
			return;
		}
		if (isSelected) {
			if (companyId) {
				const record = [...projectList?.project_list];
				const selectedProjectIds = record.map(
					(project) => project?.value || ''
				);
				setProjectId(selectedProjectIds);
				setSelectedProjectOptionValue(record);
				return;
			}
			const record = [...projectList?.project_list, ...projectList?.org_tasks];
			const selectedProjectIds = record.map((project) => project?.value || '');
			setProjectId(selectedProjectIds);
			setSelectedProjectOptionValue(record);
		} else {
			setProjectId([]);
			handleClearOptions();
		}
	};

	const handleToggleSelectAll = () => {
		handleSelectAll && handleSelectAll(!allSelected);
	};

	const onProjectChange = (event: any, value: (any | null)[], reason: any) => {
		if (!value) {
			return;
		}
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option) => option.value === 'All')) {
				handleToggleSelectAll();
				return;
			} else {
				handleToggleOption && handleToggleOption(value);
				return;
			}
		} else if (reason === 'clear') {
			handleClearOptions && handleClearOptions();
			return;
		}
	};

	const getLimits = (value: Number) => {
		return (
			<div className={`${style.dropDownValue} ${ellipsisstyle.ellipsis}`}>
				{selectedProjectOptionValue && selectedProjectOptionValue?.length === 1
					? selectedProjectOptionValue[0].label
					: `Selected ${value} items`}
			</div>
		);
	};

	const projectAutoCompleteInput = () => (
		<Autocomplete
			multiple
			placeholder='Select project'
			limitTags={0}
			fullWidth={false}
			selectOnFocus={true}
			onChange={onProjectChange}
			options={project?.length > 0 ? project : []}
			value={
				selectedProjectOptionValue && selectedProjectOptionValue?.length > 0
					? selectedProjectOptionValue
					: []
			}
			style={{ width: 210, marginTop: '4px' }}
			ChipProps={{ style: { display: 'none' } }}
			disableCloseOnSelect={true}
			getLimitTagsText={getLimits}
			filterOptions={(options, params) => {
				const filtered = autoCompleteFilterOption(options, params);
				return [{ label: 'All', value: 'All' }, ...filtered];
			}}
			classes={{
				tag: inlinestyle.label,
			}}
			renderOption={(option: any | null, { selected }) => {
				const selectAllProps =
					option.value === 'All' ? { checked: allSelected } : {};
				return (
					<>
						<Checkbox
							icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
							checkedIcon={<CheckBoxIcon fontSize='small' />}
							style={{ marginRight: 8 }}
							checked={selected || false}
							color='primary'
							{...selectAllProps}
						/>
						<Box style={{ fontSize: '12px' }}>{`${option?.label}`}</Box>
					</>
				);
			}}
			getOptionLabel={(option: any | null) => `${option?.label}`}
			renderInput={(params) => (
				<ThemeProvider theme={customAutoCompleteSearchBarTheme}>
					<TextField {...params} label='' />
				</ThemeProvider>
			)}
		/>
	);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const resetTeamResourceFilter = () => {
		if (!generalAndProjectResourceList) {
			return;
		}
		const projectAssignedResources =
			generalAndProjectResourceList?.projectResourceList
				? generalAndProjectResourceList?.projectResourceList?.map(
						(resource) => ({
							id: resource.user.id,
							name: resource.user.full_name,
						})
				  )
				: [];
		const generalProjectAssignedResources =
			generalAndProjectResourceList?.generalTaskResourceList
				? generalAndProjectResourceList?.generalTaskResourceList?.map(
						(timesheet) => ({
							id: timesheet?.userByEmployeeId?.id,
							name: timesheet?.userByEmployeeId?.full_name || '',
						})
				  )
				: [];
		const uniqueAssignees = uniqBy(
			[...projectAssignedResources, ...generalProjectAssignedResources],
			'id'
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	};

	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getDayTypeFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedDayTypeOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedDayTypeOptionValue && selectedDayTypeOptionValue?.length === 1
					? selectedDayTypeOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getTimesheetStatusFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedTimesheetStatusOptionValue?.filter(
			(option: { id: string; name: string }) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{selectedTimesheetStatusOptionValue &&
				selectedTimesheetStatusOptionValue?.length === 1
					? selectedTimesheetStatusOptionValue[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const generateCSV = (exportMenuItemIndex: number = 0) => {
		if (timeSheetTableList?.length === 0) {
			return;
		}
		const exportColumnHeading = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
			'REPORTING TO',
			'REPORTING TO EMAIL',
			'DEPARTMENT',
			'PROJECT NAME',
			'PROJECT OWNER',
			'PROJECT OWNER EMAIL',
			'PROJECT STATUS',
			'PROJECT START DATE',
			'PROJECT END DATE',
			'ESTIMATED HOURS',
			'PLANNED HOURS',
			'ACTUAL HOURS',
			'TOTAL HOURS',
			'BILLABLE HOURS',
			'NON BILLABLE HOURS',
			'BILL RATE',
			'TOTAL BILL RATE',
		];

		const filteredTimeSheetTableListForExport = timeSheetTableList?.filter(
			(timeSheet) => {
				if (exportMenuItemIndex === 0) {
					return true;
				}

				return exportMenuItemIndex === 1
					? timeSheet?.isUserActive
					: !timeSheet?.isUserActive;
			}
		);

		const timesheetRows = filteredTimeSheetTableListForExport?.map(
			(timeSheet) => {
				return [
					timeSheet?.empId || '--',
					timeSheet?.resourceName,
					timeSheet?.email,
					timeSheet?.employeeType,
					...(noOfLocations && noOfLocations > 0
						? [timeSheet?.orgLocation || '--']
						: []),
					timeSheet?.reportTo || '--',
					timeSheet?.reportToEmail || '--',
					timeSheet?.department || '--',
					timeSheet?.projectName,
					timeSheet?.projectOwner || '--',
					timeSheet?.projectOwnerEmail || '--',
					timeSheet?.projectStatus || '--',
					timeSheet?.projectStartDate || '--',
					timeSheet?.projectEndDate || '--',
					timeSheet?.estmatedHours || 0,
					timeSheet?.plannedHours || 0,
					timeSheet?.actualHours || 0,
					timeSheet.totalHours
						? String(formatDecimal(timeSheet?.totalHours, 1))
						: 0,
					timeSheet?.billableWorkingHours
						? String(formatDecimal(timeSheet?.billableWorkingHours, 1))
						: 0,
					timeSheet?.nonBillableWorkingHours
						? String(formatDecimal(timeSheet?.nonBillableWorkingHours, 1))
						: 0,
					`${timeSheet?.billRate ? timeSheet?.currencySymbol || '' : ''}${
						timeSheet?.billRate ? formatDecimal(timeSheet?.billRate, 2) : 0
					}`,
					`${timeSheet?.totalBillRate ? timeSheet?.currencySymbol || '' : ''}${
						timeSheet?.totalBillRate
							? formatDecimal(timeSheet?.totalBillRate, 2)
							: 0
					}`,
				];
			}
		);

		const timeSheetExportData = [exportColumnHeading, ...timesheetRows];
		exportToCsv(
			`Timesheet_Project_Wise_Report_(${
				exportTimesheetReportUsersOptions[exportMenuItemIndex]
			})_From_${dayjs(startDate).format(dateFormat)}_TO_${dayjs(endDate).format(
				dateFormat
			)}.csv`,
			timeSheetExportData
		);
	};

	const getTotalBillRate = (
		isBillable: boolean,
		workingHours: string,
		billRate: number
	) => {
		if (!isBillable) {
			return 0;
		}
		const billableHoursAndMinutes = workingHours?.split(':');
		const billableHours = parseInt(billableHoursAndMinutes[0]);
		const billableMinutes = parseInt(billableHoursAndMinutes[1]);

		const billRateForMinute = billRate / 60;
		const totalBillRate =
			billableHours * billRate + billableMinutes * billRateForMinute;
		return totalBillRate;
	};

	// Logics
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	useEffect(() => {
		if (!projectList) {
			return;
		}
		if (!companyId) {
			const record = [...projectList?.project_list, ...projectList?.org_tasks];
			setProject(record);
			return;
		}
		const record = [...projectList?.project_list];
		setProject(record);
	}, [projectList, companyId]);

	// Create timesheet status dropdown options
	useEffect(() => {
		if (!timesheetStatus) {
			return;
		}
		const statusList = timesheetStatus?.timesheet_status.map((timesheet) => {
			return {
				id: timesheet.value,
				name: timesheet.value === 'submitted' ? 'Pending' : timesheet.label,
			};
		});
		setSelectedTimesheetStatusOptionValues([
			{
				id: 'submitted',
				name: 'Pending',
			},
			{
				id: 'approved',
				name: 'approved',
			},
		]);
		setSelectedTimesheetStatusIdList(['submitted', 'approved']);
		const sortedStatusList = orderBy(
			statusList,
			[(status) => status.name?.toUpperCase()],
			['asc']
		);
		setTimesheetStatusOptions(sortedStatusList);
	}, [timesheetStatus]);

	// Create day type dropdown options
	useEffect(() => {
		if (!dayTypeOptionList) {
			return;
		}
		const dayTypeList = dayTypeOptionList?.timesheet_day_type;
		const sortedAssigneeList = orderBy(
			dayTypeList,
			[(type) => type.name?.toUpperCase()],
			['asc']
		);
		setDayTypeOptions(sortedAssigneeList);
	}, [dayTypeOptionList]);

	// Handle Resource list for reosurce dropdown
	useEffect(() => {
		if (!generalAndProjectResourceList) {
			return;
		}
		const projectAssignedResources =
			generalAndProjectResourceList?.projectResourceList
				? generalAndProjectResourceList?.projectResourceList?.map(
						(resource) => ({
							id: resource.user.id,
							name: resource.user.full_name,
						})
				  )
				: [];
		const generalProjectAssignedResources =
			generalAndProjectResourceList?.generalTaskResourceList
				? generalAndProjectResourceList?.generalTaskResourceList?.map(
						(timesheet) => ({
							id: timesheet?.userByEmployeeId?.id,
							name: timesheet?.userByEmployeeId?.full_name || '',
						})
				  )
				: [];
		const uniqueAssignees = uniqBy(
			[...projectAssignedResources, ...generalProjectAssignedResources],
			'id'
		);
		const sortedAssigneeList = orderBy(
			uniqueAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [generalAndProjectResourceList]);

	//Handle resource list from user teams
	useEffect(() => {
		if (!usersByTeam) {
			return;
		}
		const useTeamAssignees = usersByTeam?.user_team_mapping?.map(
			(user: { user: { id: string; full_name: string } }) => {
				return {
					id: user?.user?.id,
					name: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setAssigneeRecommendations(sortedAssigneeList);
	}, [usersByTeam]);

	// Logic for timesheet table list
	useEffect(() => {
		//Get project timesheet entries
		const projectTimesheetEntries =
			generalAndProjectTimesheetEntries?.timesheetEntries
				? generalAndProjectTimesheetEntries?.timesheetEntries?.map(
						(timesheet) => {
							const existingEmployeeId =
								timesheet?.user?.employee?.emp_id || '';
							const existingContractorId =
								head(timesheet?.user?.contractors)?.contractor_id || '';
							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);
							// Logic for getting maxBill rate value
							const projectId = timesheet?.project?.id || '';
							const allBillRates =
								timesheet?.user?.project_resource_mappings?.filter(
									(allocation) =>
										allocation?.project_id === projectId &&
										allocation?.client_rate !== null
								) || [];
							const maximumBillRate =
								allBillRates && allBillRates.length > 0
									? getMaxBillRate(allBillRates)
									: 0;
							const billRate = maximumBillRate;

							const employeeId =
								timesheet?.user?.user_type === 'employee'
									? timesheet?.user?.employee?.emp_id
										? `${
												getPrefix(
													timesheet?.user?.employee?.employee_prefix?.name,
													timesheet?.user?.employee?.employee_prefix?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timesheet?.user?.contractors)?.contractor_id
									? `${
											getPrefix(
												head(timesheet?.user?.contractors)?.contractor_prefix
													?.name,
												head(timesheet?.user?.contractors)?.contractor_prefix
													?.value
											) || ''
									  }${
											timesheet?.user?.contractors &&
											head(timesheet?.user?.contractors)?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							return {
								id: timesheet?.id,
								isUserActive: timesheet?.user?.is_active,
								billRate: billRate,
								totalBillRate: billRate
									? getTotalBillRate(
											timesheet?.is_billable,
											String(timesheet?.working_hours).slice(0, 5),
											billRate
									  )
									: 0,
								resourceName: timesheet?.user?.full_name,
								projectId: timesheet?.project?.id,
								projectName: timesheet?.project?.name,
								projectOwner: timesheet?.user?.full_name,
								projectOwnerEmail: timesheet?.user?.email,
								currencySymbol: timesheet?.project?.currency?.symbol || '',
								projectStatus: timesheet?.project?.ProjectStatus?.label,
								projectStartDate: timesheet?.project?.start_date
									? dayjs(timesheet?.project?.start_date).format(dateFormat)
									: '--',
								projectEndDate: timesheet?.project?.end_date
									? dayjs(timesheet?.project?.end_date).format(dateFormat)
									: '--',
								uniqueId: `${timesheet?.user?.id}-${timesheet?.project?.id}`,
								userId: timesheet?.user?.id,
								workingHours: String(timesheet?.working_hours).slice(0, 5),
								billableStatus: timesheet?.is_billable,
								estmatedHours: timesheet?.project?.effort_estimate,
								plannedHours:
									timesheet?.project?.project_resource_mappings_aggregate?.aggregate?.sum?.total_hours?.toFixed(
										1
									),

								email: timesheet?.user?.email,
								employeeType: timesheet?.user?.user_type
									? timesheet?.user?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								reportTo:
									timesheet?.user?.user_type === 'employee'
										? timesheet?.user?.employee?.reportsTo?.full_name
										: timesheet?.user?.contractors
										? head(timesheet.user.contractors)?.reportee?.full_name
										: null,
								reportToEmail:
									timesheet?.user?.user_type === 'employee'
										? timesheet?.user?.employee?.reportsTo?.email
										: timesheet?.user?.contractors
										? head(timesheet.user.contractors)?.reportee?.email
										: null,
								department:
									timesheet?.user?.user_type === 'employee'
										? timesheet?.user?.employee?.department?.name
										: timesheet?.user?.contractors
										? head(timesheet.user.contractors)?.department?.name
										: null,
								org_location:
									timesheet?.user.user_type === 'employee'
										? timesheet?.user.employee?.org_location?.name
										: head(timesheet?.user.contractors)?.org_location?.name,
							};
						}
				  )
				: [];
		//Get general timesheet entries
		const generalTimesheetEntries =
			generalAndProjectTimesheetEntries?.generalTimesheetEntries
				? generalAndProjectTimesheetEntries?.generalTimesheetEntries?.map(
						(task) => {
							const timesheetEntries = task?.timesheets
								? task?.timesheets?.map((timeSheet) => {
										const existingEmployeeId =
											timeSheet?.userByEmployeeId?.employee?.emp_id || '';
										const existingContractorId =
											head(timeSheet?.userByEmployeeId?.contractors)
												?.contractor_id || '';
										const formattedContractorId =
											isFixedEmpIdLengthEnabled && existingContractorId
												? `${existingContractorId}`.padStart(empIdLength, '0')
												: existingContractorId;

										const formattedEmployeeId =
											isFixedEmpIdLengthEnabled && existingEmployeeId
												? `${existingEmployeeId}`.padStart(empIdLength, '0')
												: existingEmployeeId;

										const employeeId =
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.emp_id
													? `${
															getPrefix(
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.name,
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.value
															) || ''
													  }${formattedEmployeeId || ''}`
													: ''
												: head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
												? `${
														getPrefix(
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.name,
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.value
														) || ''
												  }${
														timeSheet?.userByEmployeeId?.contractors &&
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_id
															? formattedContractorId
															: ''
												  }`
												: '';
										return {
											id: timeSheet?.id,
											isUserActive: timeSheet?.userByEmployeeId?.is_active,
											billRate: 0,
											totalBillRate: 0,
											resourceName: timeSheet?.userByEmployeeId?.full_name,
											projectName: task.task_category,
											currencySymbol: '',
											uniqueId: `${timeSheet?.userByEmployeeId?.id}-${task?.task_category}`,
											userId: timeSheet?.userByEmployeeId.id,
											billableStatus: timeSheet?.is_billable,
											workingHours: String(timeSheet?.working_hours).slice(
												0,
												5
											),
											email: timeSheet?.userByEmployeeId?.email || '',
											employeeType: timeSheet?.userByEmployeeId?.user_type
												? timeSheet?.userByEmployeeId?.user_type === 'employee'
													? 'Employee'
													: 'Consultant'
												: '--',
											empId: employeeId,
											reportTo:
												timeSheet.userByEmployeeId?.user_type === 'employee'
													? timeSheet.userByEmployeeId?.employee?.reportsTo
															?.full_name
													: timeSheet.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.reportee?.full_name
													: null,
											reportToEmail:
												timeSheet.userByEmployeeId?.user_type === 'employee'
													? timeSheet.userByEmployeeId?.employee?.reportsTo
															?.email
													: timeSheet.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.reportee?.email
													: null,
											department:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.department
															?.name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.department?.name
													: null,
											org_location:
												timeSheet.userByEmployeeId.user_type === 'employee'
													? timeSheet?.userByEmployeeId.employee?.org_location
															?.name
													: head(timeSheet?.userByEmployeeId.contractors)
															?.org_location?.name,
										};
								  })
								: [];

							return timesheetEntries;
						}
				  )
				: [];
		//Get project overtime entries
		const projectTimesheetOvertimeEntries =
			generalAndProjectTimesheetOvertimeEntries?.normalOvertimeEntries
				? generalAndProjectTimesheetOvertimeEntries?.normalOvertimeEntries?.map(
						(timeSheet) => {
							const existingEmployeeId =
								timeSheet?.user?.employee?.emp_id || '';
							const existingContractorId =
								head(timeSheet?.user?.contractors)?.contractor_id || '';
							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const projectId = timeSheet?.project?.id || '';
							const allBillRates =
								timeSheet?.user?.project_resource_mappings?.filter(
									(allocation) =>
										allocation?.project_id === projectId &&
										allocation?.client_rate !== null
								) || [];
							const maximumBillRate =
								allBillRates && allBillRates.length > 0
									? getMaxBillRate(allBillRates)
									: 0;
							const billRate = maximumBillRate;

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);
							const employeeId =
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.emp_id
										? `${
												getPrefix(
													timeSheet?.user?.employee?.employee_prefix?.name,
													timeSheet?.user?.employee?.employee_prefix?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timeSheet?.user?.contractors)?.contractor_id
									? `${
											getPrefix(
												head(timeSheet?.user?.contractors)?.contractor_prefix
													?.name,
												head(timeSheet?.user?.contractors)?.contractor_prefix
													?.value
											) || ''
									  }${
											timeSheet?.user?.contractors &&
											head(timeSheet?.user?.contractors)?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							return {
								id: timeSheet.id,
								isUserActive: timeSheet?.user?.is_active,
								billRate: billRate,
								totalBillRate: billRate
									? getTotalBillRate(
											timeSheet?.is_billable || false,
											String(timeSheet?.working_hours).slice(0, 5),
											billRate
									  )
									: 0,
								resourceName: timeSheet?.user?.full_name,
								projectName: timeSheet?.project?.name,
								projectId: timeSheet?.project?.id,
								projectOwner: timeSheet?.project?.user?.full_name,
								projectOwnerEmail: timeSheet?.project?.user?.email,
								currencySymbol: timeSheet?.project?.currency?.symbol || '',
								projectStatus: timeSheet?.project?.ProjectStatus?.label,
								projectStartDate: timeSheet?.project?.start_date
									? dayjs(timeSheet?.project?.start_date).format(dateFormat)
									: '--',
								projectEndDate: timeSheet?.project?.end_date
									? dayjs(timeSheet?.project?.end_date).format(dateFormat)
									: '--',
								uniqueId: `${timeSheet?.user?.id}-${timeSheet?.project?.id}`,
								userId: timeSheet.user.id,
								workingHours: String(timeSheet?.working_hours).slice(0, 5),
								billableStatus: timeSheet?.is_billable,
								estmatedHours: timeSheet?.project?.effort_estimate,
								plannedHours:
									timeSheet?.project?.project_resource_mappings_aggregate?.aggregate?.sum?.total_hours?.toFixed(
										1
									),

								email: timeSheet?.user?.email || '',
								employeeType: timeSheet?.user?.user_type
									? timeSheet?.user?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								reportTo:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.reportsTo?.full_name
										: timeSheet?.user?.contractors
										? head(timeSheet?.user.contractors)?.reportee?.full_name
										: null,
								reportToEmail:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.reportsTo?.email
										: timeSheet?.user?.contractors
										? head(timeSheet?.user.contractors)?.reportee?.email
										: null,
								department:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.department?.name
										: timeSheet?.user?.contractors
										? head(timeSheet.user.contractors)?.department?.name
										: null,
								org_location:
									timeSheet?.user.user_type === 'employee'
										? timeSheet?.user.employee?.org_location?.name
										: head(timeSheet?.user.contractors)?.org_location?.name,
							};
						}
				  )
				: [];

		//Get overtime timesheet entries
		const generalTimesheetOvertimeEntries =
			generalAndProjectTimesheetOvertimeEntries?.generalOvertimeEntries
				? generalAndProjectTimesheetOvertimeEntries?.generalOvertimeEntries?.map(
						(task) => {
							const timeSheetEntries: any = task?.timesheet_overtime?.map(
								(timeSheet) => {
									const existingEmployeeId =
										timeSheet?.userByEmployeeId?.employee?.emp_id || '';
									const existingContractorId =
										head(timeSheet?.userByEmployeeId?.contractors)
											?.contractor_id || '';
									const formattedContractorId =
										isFixedEmpIdLengthEnabled && existingContractorId
											? `${existingContractorId}`.padStart(empIdLength, '0')
											: existingContractorId;

									const formattedEmployeeId =
										isFixedEmpIdLengthEnabled && existingEmployeeId
											? `${existingEmployeeId}`.padStart(empIdLength, '0')
											: existingEmployeeId;

									const employeeId =
										timeSheet?.userByEmployeeId?.user_type === 'employee'
											? timeSheet?.userByEmployeeId?.employee?.emp_id
												? `${
														getPrefix(
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.name,
															timeSheet?.userByEmployeeId?.employee
																?.employee_prefix?.value
														) || ''
												  }${formattedEmployeeId || ''}`
												: ''
											: head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											? `${
													getPrefix(
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_prefix?.name,
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_prefix?.value
													) || ''
											  }${
													timeSheet?.userByEmployeeId?.contractors &&
													head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
														? formattedContractorId
														: ''
											  }`
											: '';
									return {
										id: timeSheet.id,
										isUserActive: timeSheet?.userByEmployeeId?.is_active,
										billRate: 0,
										totalBillRate: 0,
										currencySymbol: '',
										resourceName: timeSheet.userByEmployeeId?.full_name,
										projectName: task.task_category,
										uniqueId: `${timeSheet?.userByEmployeeId?.id}-${task?.task_category}`,
										userId: timeSheet.userByEmployeeId.id,
										billableStatus: timeSheet?.is_billable,
										email: timeSheet?.userByEmployeeId?.email || '',
										employeeType: timeSheet?.userByEmployeeId?.user_type
											? timeSheet?.userByEmployeeId?.user_type === 'employee'
												? 'Employee'
												: 'Consultant'
											: '--',
										empId: employeeId,
										reportTo:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.reportsTo
														?.full_name
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId.contractors)
														?.reportee?.full_name
												: null,
										reportToEmail:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.reportsTo
														?.email
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet?.userByEmployeeId.contractors)
														?.reportee?.email
												: null,
										department:
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.department
														?.name
												: timeSheet?.userByEmployeeId?.contractors
												? head(timeSheet.userByEmployeeId.contractors)
														?.department?.name
												: null,
										org_location:
											timeSheet?.userByEmployeeId.user_type === 'employee'
												? timeSheet?.userByEmployeeId.employee?.org_location
														?.name
												: head(timeSheet?.userByEmployeeId.contractors)
														?.org_location?.name,
										workingHours: String(timeSheet?.working_hours).slice(0, 5),
									};
								}
							);
							return timeSheetEntries;
						}
				  )
				: [];

		//Get  draft project timesheet entries
		const draftStatusProjectTimesheetEntries =
			generalAndProjectUnsubmittedAndDraftStatusEntries?.normalDraftStatusTimesheetList
				? generalAndProjectUnsubmittedAndDraftStatusEntries?.normalDraftStatusTimesheetList?.map(
						(timeSheet) => {
							const existingEmployeeId = timeSheet?.user?.employee?.emp_id
								? timeSheet?.user?.employee?.emp_id
								: '--';
							const existingContractorId = head(timeSheet?.user?.contractors)
								?.contractor_id
								? head(timeSheet?.user?.contractors)?.contractor_id
								: '--';

							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const projectId = timeSheet?.project?.id || '';
							const allBillRates =
								timeSheet?.user?.project_resource_mappings?.filter(
									(allocation) =>
										allocation?.project_id === projectId &&
										allocation?.client_rate !== null
								) || [];
							const maximumBillRate =
								allBillRates && allBillRates.length > 0
									? getMaxBillRate(allBillRates)
									: 0;
							const billRate = maximumBillRate;

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const employeeId =
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.emp_id
										? `${
												getPrefix(
													timeSheet?.user?.employee?.employee_prefix?.name,
													timeSheet?.user?.employee?.employee_prefix?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timeSheet?.user?.contractors)?.contractor_id
									? `${
											getPrefix(
												head(timeSheet?.user?.contractors)?.contractor_prefix
													?.name,
												head(timeSheet?.user?.contractors)?.contractor_prefix
													?.value
											) || ''
									  }${
											timeSheet?.user?.contractors &&
											head(timeSheet?.user?.contractors)?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							return {
								id: timeSheet?.id,
								isUserActive: timeSheet?.user?.is_active,
								billRate: billRate,
								totalBillRate: billRate
									? getTotalBillRate(
											timeSheet?.is_billable,
											String(timeSheet?.working_hours).slice(0, 5),
											billRate
									  )
									: 0,
								resourceName: timeSheet?.user?.full_name,
								projectId: timeSheet?.project?.id,
								projectName: timeSheet?.project?.name,
								projectOwner: timeSheet?.user?.full_name,
								projectOwnerEmail: timeSheet?.user?.email,
								currencySymbol: timeSheet?.project?.currency?.symbol || '',
								projectStatus: timeSheet?.project?.ProjectStatus?.label,
								projectStartDate: timeSheet?.project?.start_date
									? dayjs(timeSheet?.project?.start_date).format(dateFormat)
									: '--',
								projectEndDate: timeSheet?.project?.end_date
									? dayjs(timeSheet?.project?.end_date).format(dateFormat)
									: '--',
								uniqueId: `${timeSheet?.user?.id}-${timeSheet?.project?.id}`,
								userId: timeSheet?.user.id,
								workingHours: String(timeSheet?.working_hours).slice(0, 5),
								billableStatus: timeSheet?.is_billable,
								estmatedHours: timeSheet?.project?.effort_estimate,
								plannedHours:
									timeSheet?.project?.project_resource_mappings_aggregate?.aggregate?.sum?.total_hours?.toFixed(
										1
									),

								email: timeSheet?.user?.email || '',
								employeeType: timeSheet?.user?.user_type
									? timeSheet?.user?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								reportTo:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.reportsTo?.full_name
										: timeSheet?.user?.contractors
										? head(timeSheet?.user.contractors)?.reportee?.full_name
										: null,
								reportToEmail:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.reportsTo?.email
										: timeSheet?.user?.contractors
										? head(timeSheet?.user.contractors)?.reportee?.email
										: null,
								department:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.department?.name
										: timeSheet?.user?.contractors
										? head(timeSheet.user.contractors)?.department?.name
										: null,
								org_location:
									timeSheet?.user.user_type === 'employee'
										? timeSheet?.user.employee?.org_location?.name
										: head(timeSheet?.user.contractors)?.org_location?.name,
							};
						}
				  )
				: [];

		//Get unsubmitted project timesheet entries
		const unSubmittedProjectTimesheetEntries =
			generalAndProjectUnsubmittedAndDraftStatusEntries?.unSubmittedNormalTimesheetList
				? generalAndProjectUnsubmittedAndDraftStatusEntries?.unSubmittedNormalTimesheetList?.map(
						(timeSheet) => {
							const existingEmployeeId = timeSheet?.user?.employee?.emp_id
								? timeSheet?.user?.employee?.emp_id
								: '--';
							const existingContractorId = head(timeSheet?.user?.contractors)
								?.contractor_id
								? head(timeSheet?.user?.contractors)?.contractor_id
								: '--';

							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const projectId = timeSheet?.project?.id || '';
							const allBillRates =
								timeSheet?.user?.project_resource_mappings?.filter(
									(allocation) =>
										allocation?.project_id === projectId &&
										allocation?.client_rate !== null
								) || [];
							const maximumBillRate =
								allBillRates && allBillRates.length > 0
									? getMaxBillRate(allBillRates)
									: 0;
							const billRate = maximumBillRate;

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const employeeId =
								timeSheet?.user?.user_type === 'employee'
									? timeSheet?.user?.employee?.emp_id
										? `${
												getPrefix(
													timeSheet?.user?.employee?.employee_prefix?.name,
													timeSheet?.user?.employee?.employee_prefix?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(timeSheet?.user?.contractors)?.contractor_id
									? `${
											getPrefix(
												head(timeSheet?.user?.contractors)?.contractor_prefix
													?.name,
												head(timeSheet?.user?.contractors)?.contractor_prefix
													?.value
											) || ''
									  }${
											timeSheet?.user?.contractors &&
											head(timeSheet?.user?.contractors)?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';
							return {
								id: timeSheet?.id,
								isUserActive: timeSheet?.user?.is_active,
								billRate: billRate,
								totalBillRate: billRate
									? getTotalBillRate(
											timeSheet?.is_billable,
											String(timeSheet?.working_hours).slice(0, 5),
											billRate
									  )
									: 0,

								resourceName: timeSheet?.user?.full_name,
								projectId: timeSheet?.project?.id,
								projectName: timeSheet?.project?.name,
								projectOwner: timeSheet?.user?.full_name,
								projectOwnerEmail: timeSheet?.user?.email,
								currencySymbol: timeSheet?.project?.currency?.symbol || '',
								projectStatus: timeSheet?.project?.ProjectStatus?.label,
								projectStartDate: timeSheet?.project?.start_date
									? dayjs(timeSheet?.project?.start_date).format(dateFormat)
									: '--',
								projectEndDate: timeSheet?.project?.end_date
									? dayjs(timeSheet?.project?.end_date).format(dateFormat)
									: '--',
								uniqueId: `${timeSheet?.user?.id}-${timeSheet?.project?.id}`,
								userId: timeSheet?.user.id,
								workingHours: String(timeSheet?.working_hours).slice(0, 5),
								billableStatus: timeSheet?.is_billable,
								estmatedHours: timeSheet?.project?.effort_estimate,
								plannedHours:
									timeSheet?.project?.project_resource_mappings_aggregate?.aggregate?.sum?.total_hours?.toFixed(
										1
									),

								email: timeSheet?.user?.email || '',
								employeeType: timeSheet?.user?.user_type
									? timeSheet?.user?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								reportTo:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.reportsTo?.full_name
										: timeSheet?.user?.contractors
										? head(timeSheet?.user.contractors)?.reportee?.full_name
										: null,
								reportToEmail:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.reportsTo?.email
										: timeSheet?.user?.contractors
										? head(timeSheet?.user.contractors)?.reportee?.email
										: null,
								department:
									timeSheet?.user?.user_type === 'employee'
										? timeSheet?.user?.employee?.department?.name
										: timeSheet?.user?.contractors
										? head(timeSheet.user.contractors)?.department?.name
										: null,
								org_location:
									timeSheet?.user.user_type === 'employee'
										? timeSheet?.user.employee?.org_location?.name
										: head(timeSheet?.user.contractors)?.org_location?.name,
							};
						}
				  )
				: [];

		//Get general unsubmitted timesheet entries
		const generalUnSubmittedTimesheetEntries: any =
			generalAndProjectUnsubmittedAndDraftStatusEntries?.GeneralUnSubmittedTimesheets
				? generalAndProjectUnsubmittedAndDraftStatusEntries?.GeneralUnSubmittedTimesheets.map(
						(task) => {
							const generalUnSubmittedEntries = task?.timesheets
								? task?.timesheets?.map((timeSheet) => {
										const existingEmployeeId = timeSheet?.userByEmployeeId
											?.employee?.emp_id
											? timeSheet?.userByEmployeeId?.employee?.emp_id
											: '--';
										const existingContractorId = head(
											timeSheet?.userByEmployeeId?.contractors
										)?.contractor_id
											? head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											: '--';

										const formattedContractorId =
											isFixedEmpIdLengthEnabled && existingContractorId
												? `${existingContractorId}`.padStart(empIdLength, '0')
												: existingContractorId;

										const formattedEmployeeId =
											isFixedEmpIdLengthEnabled && existingEmployeeId
												? `${existingEmployeeId}`.padStart(empIdLength, '0')
												: existingEmployeeId;

										const employeeId =
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.emp_id
													? `${
															getPrefix(
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.name,
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.value
															) || ''
													  }${formattedEmployeeId || ''}`
													: ''
												: head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
												? `${
														getPrefix(
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.name,
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.value
														) || ''
												  }${
														timeSheet?.userByEmployeeId?.contractors &&
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_id
															? formattedContractorId
															: ''
												  }`
												: '';
										return {
											id: timeSheet?.id,
											isUserActive: timeSheet?.userByEmployeeId?.is_active,
											billRate: 0,
											totalBillRate: 0,
											currencySymbol: '',
											resourceName: timeSheet?.userByEmployeeId?.full_name,
											projectName: task?.task_category,
											uniqueId: `${timeSheet?.userByEmployeeId?.id}-${task?.task_category}`,
											userId: timeSheet?.userByEmployeeId?.id,
											email: timeSheet?.userByEmployeeId?.email || '',
											employeeType: timeSheet?.userByEmployeeId?.user_type
												? timeSheet?.userByEmployeeId?.user_type === 'employee'
													? 'Employee'
													: 'Consultant'
												: '--',
											empId: employeeId,
											reportTo:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.reportsTo
															?.full_name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet?.userByEmployeeId.contractors)
															?.reportee?.full_name
													: null,
											reportToEmail:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.reportsTo
															?.email
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet?.userByEmployeeId.contractors)
															?.reportee?.email
													: null,
											department:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.department
															?.name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.department?.name
													: null,
											org_location:
												timeSheet.userByEmployeeId.user_type === 'employee'
													? timeSheet?.userByEmployeeId.employee?.org_location
															?.name
													: head(timeSheet?.userByEmployeeId.contractors)
															?.org_location?.name,
											billableStatus: timeSheet?.is_billable,
											workingHours: String(timeSheet?.working_hours).slice(
												0,
												5
											),
										};
								  })
								: [];
							return generalUnSubmittedEntries;
						}
				  )
				: [];

		//Get general draft timesheet entries
		const generalDraftStatusTimesheetEntries =
			generalAndProjectUnsubmittedAndDraftStatusEntries?.generalDraftStatusTimesheets
				? generalAndProjectUnsubmittedAndDraftStatusEntries?.generalDraftStatusTimesheets?.map(
						(task) => {
							const generalDraftEntries = task?.timesheets
								? task?.timesheets?.map((timeSheet) => {
										const existingEmployeeId = timeSheet?.userByEmployeeId
											?.employee?.emp_id
											? timeSheet?.userByEmployeeId?.employee?.emp_id
											: '--';
										const existingContractorId = head(
											timeSheet?.userByEmployeeId?.contractors
										)?.contractor_id
											? head(timeSheet?.userByEmployeeId?.contractors)
													?.contractor_id
											: '--';

										const formattedContractorId =
											isFixedEmpIdLengthEnabled && existingContractorId
												? `${existingContractorId}`.padStart(empIdLength, '0')
												: existingContractorId;

										const formattedEmployeeId =
											isFixedEmpIdLengthEnabled && existingEmployeeId
												? `${existingEmployeeId}`.padStart(empIdLength, '0')
												: existingEmployeeId;

										const employeeId =
											timeSheet?.userByEmployeeId?.user_type === 'employee'
												? timeSheet?.userByEmployeeId?.employee?.emp_id
													? `${
															getPrefix(
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.name,
																timeSheet?.userByEmployeeId?.employee
																	?.employee_prefix?.value
															) || ''
													  }${formattedEmployeeId || ''}`
													: ''
												: head(timeSheet?.userByEmployeeId?.contractors)
														?.contractor_id
												? `${
														getPrefix(
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.name,
															head(timeSheet?.userByEmployeeId?.contractors)
																?.contractor_prefix?.value
														) || ''
												  }${
														timeSheet?.userByEmployeeId?.contractors &&
														head(timeSheet?.userByEmployeeId?.contractors)
															?.contractor_id
															? formattedContractorId
															: ''
												  }`
												: '';
										return {
											id: timeSheet?.id,
											isUserActive: timeSheet?.userByEmployeeId?.is_active,
											billRate: 0,
											totalBillRate: 0,
											currencySymbol: '',
											resourceName: timeSheet?.userByEmployeeId?.full_name,
											projectName: task?.task_category,
											uniqueId: `${timeSheet?.userByEmployeeId?.id}-${task?.task_category}`,
											userId: timeSheet?.userByEmployeeId?.id,
											email: timeSheet?.userByEmployeeId?.email || '',
											employeeType: timeSheet?.userByEmployeeId?.user_type
												? timeSheet?.userByEmployeeId?.user_type === 'employee'
													? 'Employee'
													: 'Consultant'
												: '--',
											empId: employeeId,
											reportTo:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.reportsTo
															?.full_name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet?.userByEmployeeId.contractors)
															?.reportee?.full_name
													: null,
											reportToEmail:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.reportsTo
															?.email
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet?.userByEmployeeId.contractors)
															?.reportee?.email
													: null,
											department:
												timeSheet?.userByEmployeeId?.user_type === 'employee'
													? timeSheet?.userByEmployeeId?.employee?.department
															?.name
													: timeSheet?.userByEmployeeId?.contractors
													? head(timeSheet.userByEmployeeId.contractors)
															?.department?.name
													: null,
											org_location:
												timeSheet.userByEmployeeId.user_type === 'employee'
													? timeSheet?.userByEmployeeId.employee?.org_location
															?.name
													: head(timeSheet?.userByEmployeeId.contractors)
															?.org_location?.name,
											billableStatus: timeSheet?.is_billable,
											workingHours: String(timeSheet?.working_hours).slice(
												0,
												5
											),
										};
								  })
								: [];
							return generalDraftEntries;
						}
				  )
				: [];
		// Combine all timesheet entries from above.
		const finalTimesheetEntryList = uniqBy(
			timesheetType === 'overtime'
				? [
						...projectTimesheetOvertimeEntries,
						...generalTimesheetOvertimeEntries.flat(),
				  ]
				: timesheetType === 'timesheet'
				? [
						...projectTimesheetEntries,
						...generalTimesheetEntries.flat(),
						...draftStatusProjectTimesheetEntries,
						...unSubmittedProjectTimesheetEntries,
						...generalUnSubmittedTimesheetEntries.flat(),
						...generalDraftStatusTimesheetEntries.flat(),
				  ]
				: [
						...projectTimesheetEntries,
						...generalTimesheetEntries.flat(),
						...projectTimesheetOvertimeEntries,
						...generalTimesheetOvertimeEntries.flat(),
						...draftStatusProjectTimesheetEntries,
						...unSubmittedProjectTimesheetEntries,
						...generalUnSubmittedTimesheetEntries.flat(),
						...generalDraftStatusTimesheetEntries.flat(),
				  ],
			'id'
		);

		const finalActualHours = _.chain(finalTimesheetEntryList)
			.groupBy((item) => `${item?.projectId}`)

			.map((timesheetEntry) => {
				const actualTotalHours: Duration = timesheetEntry

					.map(
						(timeSheet) =>
							timeSheet?.billableStatus === true &&
							timeSheet?.workingHours?.split(':')
					)
					.map((hoursAndMinutes: string[]) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);
				return {
					actualHours: actualTotalHours.asHours().toFixed(1),
					projectId: head(timesheetEntry)?.projectId,
				};
			})
			.value();

		const finalTimesheetEntries = _.chain(finalTimesheetEntryList)
			.groupBy((item) => `${item.uniqueId}`)
			.map((timesheetEntry) => {
				const actualHours = finalActualHours?.find(
					(value) => value?.projectId === head(timesheetEntry)?.projectId
				);
				const startDate = timesheetEntry
					?.filter((timesheet) => timesheet?.projectStartDate)
					.map((timesheet) => timesheet?.projectStartDate);

				const endDate = timesheetEntry
					?.filter((timesheet) => timesheet?.projectEndDate)
					.map((timesheet) => timesheet?.projectEndDate);

				const totalWorkingHours: Duration = timesheetEntry
					.map((timeSheet) => timeSheet?.workingHours?.split(':'))
					.map((hoursAndMinutes: string[]) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);

				const billableHours: Duration = timesheetEntry
					.map(
						(timeSheet) =>
							timeSheet?.billableStatus === true &&
							timeSheet?.workingHours?.split(':')
					)
					.map((hoursAndMinutes: any) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);
				const nonBillableHours: Duration = timesheetEntry
					.map(
						(timeSheet) =>
							timeSheet?.billableStatus === false &&
							timeSheet?.workingHours?.split(':')
					)
					.map((hoursAndMinutes: any) =>
						dayjs.duration({
							hours: Number(hoursAndMinutes[0]),
							minutes: Number(hoursAndMinutes[1]),
							seconds: Number(hoursAndMinutes[2]),
						})
					)
					.reduce(
						(total: Duration, durations: Duration) => total.add(durations),
						dayjs.duration({
							hours: 0,
							minutes: 0,
							seconds: 0,
						})
					);
				// Sum of all bill rates
				const sumAllBillRate = timesheetEntry?.reduce(
					(initialValue, timesheet) =>
						initialValue + Number(timesheet?.totalBillRate),
					0
				);

				return {
					id: head(timesheetEntry)?.id,
					isUserActive: head(timesheetEntry)?.isUserActive,
					billRate: head(timesheetEntry)?.billRate,
					totalBillRate: sumAllBillRate,
					resourceName: head(timesheetEntry)?.resourceName,
					uniqueId: head(timesheetEntry)?.uniqueId,
					projectId: head(timesheetEntry)?.projectId,
					currencySymbol: head(timesheetEntry)?.currencySymbol,
					projectName: head(timesheetEntry)?.projectName,
					projectOwner: head(timesheetEntry)?.projectOwner,
					projectOwnerEmail: head(timesheetEntry)?.projectOwnerEmail,
					projectStatus: head(timesheetEntry)?.projectStatus,
					projectStartDate: startDate ? head(startDate) : '--',
					projectEndDate: endDate ? head(endDate) : '--',
					totalHours: totalWorkingHours?.asHours(),
					billableWorkingHours: billableHours?.asHours(),
					estmatedHours: head(timesheetEntry)?.estmatedHours,
					plannedHours: head(timesheetEntry)?.plannedHours,
					actualHours: actualHours?.actualHours || 0,

					nonBillableWorkingHours: nonBillableHours?.asHours(),
					userId: head(timesheetEntry)?.userId,
					orgLocation: head(timesheetEntry)?.org_location || '',
					empId: head(timesheetEntry)?.empId || '',
					billableStatus: head(timesheetEntry)?.billableStatus || '',
					employeeType: head(timesheetEntry)?.employeeType || '',
					reportTo: head(timesheetEntry)?.reportTo || '--',
					reportToEmail: head(timesheetEntry)?.reportToEmail || '--',
					department: head(timesheetEntry)?.department || '--',
					email: head(timesheetEntry)?.email || '',
				};
			})
			.value();
		// Need to refactor below logic for finding users without timesheet entries
		const usersWithTimehsheetEntry = finalTimesheetEntryList?.map(
			(timesheet) => timesheet?.uniqueId
		);
		const usersWithoutTimesheetEntry =
			generalAndProjectTimesheetEntries?.projectResources
				? generalAndProjectTimesheetEntries?.projectResources
						?.filter(
							(resource) =>
								!usersWithTimehsheetEntry.includes(
									`${resource?.employee_id}-${resource?.project?.id}`
								)
						)
						.map((resource) => {
							const existingEmployeeId = resource?.user?.employee?.emp_id
								? resource?.user?.employee?.emp_id
								: '--';
							const existingContractorId = head(resource?.user?.contractors)
								?.contractor_id
								? head(resource?.user?.contractors)?.contractor_id
								: '--';

							const formattedContractorId = formatEmployeeId(
								existingContractorId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);

							const formattedEmployeeId = formatEmployeeId(
								existingEmployeeId,
								empIdLength,
								isFixedEmpIdLengthEnabled
							);
							const projectId = resource?.project?.id || '';
							const allBillRates =
								resource?.user?.project_resource_mappings?.filter(
									(allocation) =>
										allocation?.project_id === projectId &&
										allocation?.client_rate !== null
								) || [];
							const maximumBillRate =
								allBillRates && allBillRates.length > 0
									? getMaxBillRate(allBillRates)
									: 0;
							const billRate = maximumBillRate;
							const employeeId =
								resource?.user?.user_type === 'employee'
									? resource?.user?.employee?.emp_id
										? `${
												getPrefix(
													resource?.user?.employee?.employee_prefix?.name,
													resource?.user?.employee?.employee_prefix?.value
												) || ''
										  }${formattedEmployeeId || ''}`
										: ''
									: head(resource?.user?.contractors)?.contractor_id
									? `${
											getPrefix(
												head(resource?.user?.contractors)?.contractor_prefix
													?.name,
												head(resource?.user?.contractors)?.contractor_prefix
													?.value
											) || ''
									  }${
											resource?.user?.contractors &&
											head(resource?.user?.contractors)?.contractor_id
												? formattedContractorId
												: ''
									  }`
									: '';

							return {
								resourceName: resource?.user?.full_name,
								projectId: resource?.project?.id,
								projectName: resource?.project?.name,
								projectOwner: resource?.project?.user?.full_name,
								projectOwnerEmail: resource?.project?.user?.email,
								projectStatus: resource?.project?.ProjectStatus?.label,
								projectStartDate: resource?.project?.start_date
									? dayjs(resource?.project?.start_date).format(dateFormat)
									: '--',
								projectEndDate: resource?.project?.end_date
									? dayjs(resource?.project?.end_date).format(dateFormat)
									: '--',
								uniqueId: `${resource?.user?.id}-${resource?.project?.id}`,
								estmatedHours: resource?.project?.effort_estimate,
								plannedHours:
									resource?.project?.project_resource_mappings_aggregate?.aggregate?.sum?.total_hours?.toFixed(
										1
									),
								actualHours:
									resource?.project.id === head(finalActualHours)?.projectId
										? head(finalActualHours)?.actualHours
										: 0,
								userId: resource?.user?.id,
								isUserActive: resource?.user?.is_active,
								email: resource?.user?.email || '',
								employeeType: resource?.user?.user_type
									? resource?.user?.user_type === 'employee'
										? 'Employee'
										: 'Consultant'
									: '--',
								empId: employeeId,
								billRate: billRate,
								totalBillRate: 0,
								currencySymbol: resource?.project?.currency?.symbol || '',
								reportTo:
									resource?.user?.user_type === 'employee'
										? resource?.user?.employee?.reportsTo?.full_name
										: resource?.user?.contractors
										? head(resource.user.contractors)?.reportee?.full_name
										: null,
								reportToEmail:
									resource?.user?.user_type === 'employee'
										? resource?.user?.employee?.reportsTo?.email
										: resource?.user?.contractors
										? head(resource?.user.contractors)?.reportee?.email
										: null,
								department:
									resource?.user?.user_type === 'employee'
										? resource?.user?.employee?.department?.name
										: resource?.user?.contractors
										? head(resource?.user.contractors)?.department?.name
										: null,
								orgLocation:
									resource?.user.user_type === 'employee'
										? resource?.user.employee?.org_location?.name
										: head(resource.user.contractors)?.org_location?.name || '',
								totalHours: 0,
								billableWorkingHours: 0,
								nonBillableWorkingHours: 0,
							};
						})
				: [];
		if (totalHoursFilterOperator && totalHoursFilterValue) {
			if (totalHoursFilterValue && totalHoursFilterOperator === '<=') {
				const totalHoursFilterIdList = finalTimesheetEntries
					?.filter(
						(timeSheet) =>
							Number(timeSheet?.totalHours) <= totalHoursFilterValue
					)
					.map((timeSheet) => timeSheet);
				setTimeSheetTableList(totalHoursFilterIdList);
				const totalHour = calculateTotalHoursFromTimesheet(
					totalHoursFilterIdList
				);
				setAccumulatedTotalHours(totalHour);

				return;
			}
			if (totalHoursFilterValue && totalHoursFilterOperator === '>=') {
				const totalHoursFilterIdList = finalTimesheetEntries
					?.filter(
						(timeSheet) =>
							Number(timeSheet?.totalHours) >= totalHoursFilterValue
					)
					.map((timeSheet) => timeSheet);
				setTimeSheetTableList(totalHoursFilterIdList);
				const totalHour = calculateTotalHoursFromTimesheet(
					totalHoursFilterIdList
				);
				setAccumulatedTotalHours(totalHour);
				return;
			}
		}
		const finalTimesheetEntriesWithAllResources = [
			...finalTimesheetEntries,

			...uniqBy(usersWithoutTimesheetEntry, (user) => user?.uniqueId),
		];

		const sortedFinalTimesheetEntries = orderBy(
			finalTimesheetEntriesWithAllResources,
			['projectName'],
			['asc']
		);
		setTimeSheetTableList(sortedFinalTimesheetEntries);

		const totalHour = calculateTotalHoursFromTimesheet(
			sortedFinalTimesheetEntries
		);
		setAccumulatedTotalHours(totalHour);
	}, [
		generalAndProjectTimesheetEntries,
		generalAndProjectTimesheetOvertimeEntries,
		generalAndProjectUnsubmittedAndDraftStatusEntries,
		timesheetType,
		totalHoursFilterOperator,
		totalHoursFilterValue,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		dateFormat,
	]);

	return (
		<>
			<Box className={classes.reducedCountContainer} marginBottom='15px'>
				<Box className={classes.countContainerWithoutGrey}>
					<Box display='flex'>
						<Box
							display='block'
							marginTop='-15px'
							marginRight='15px'
							width='max-content'
						>
							<Box className={styles.label}>Search Customer</Box>
							<Box marginTop={'4px'}>
								<Form onSubmit={() => {}}>
									{() => (
										<ThemeProvider theme={modalFormTheme}>
											<ReferenceInput
												source='crm_company'
												label=''
												reference='crm_company'
												sort={{ field: 'name', order: 'ASC' }}
												filterToQuery={(searchText: any) => ({
													name: searchText,
												})}
												filter={{
													name: true,
												}}
												onChange={(event) => {
													if (!event) {
														setCompanyId('');
														setProjectId([]);
														setSelectedProjectOptionValue([]);
														return;
													}
													if (selectedProjectOptionValue) {
														setProjectId([]);
														setSelectedProjectOptionValue([]);
														setCompanyId(event || null);
														return;
													}
													setCompanyId(event || null);
												}}
											>
												<AutocompleteInput
													resettable={true}
													optionText={(name: any) => `${name?.name}`}
													shouldRenderSuggestions={(name: string) =>
														name.trim().length > 0
													}
												/>
											</ReferenceInput>
										</ThemeProvider>
									)}
								</Form>
							</Box>
						</Box>
						<Box display='block' marginTop='-15px'>
							<Box className={styles.label}>Search Project</Box>
							{projectAutoCompleteInput()}
						</Box>
						<ThemeProvider theme={dateTimePickerTheme}>
							<Box display='flex'>
								<ErrorTooltip
									title={startDateError ? START_DATE_ERROR : ''}
									placement='top'
									arrow={true}
								>
									<Box width='188px' marginLeft='20px'>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												variant='inline'
												onChange={(date: any) => {
													setStartDate(date);
													if (dayjs(date).isAfter(dayjs(endDate))) {
														setStartDateError(true);
													} else {
														setStartDateError(false);
													}
												}}
												autoOk={true}
												value={startDate}
												format={dateFormat}
												label='Start Date'
												InputLabelProps={{ shrink: true }}
												InputProps={{
													endAdornment: (
														<InputAdornment position='start'>
															<IconButton>
																<EventIcon />
															</IconButton>
														</InputAdornment>
													),
												}}
												error={startDateError}
											/>
										</MuiPickersUtilsProvider>
									</Box>
								</ErrorTooltip>
								<Box width='188px'>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											variant='inline'
											onChange={(date: any) => {
												setEndDate(date);
												if (dayjs(startDate).isAfter(dayjs(date))) {
													setStartDateError(true);
												} else {
													setStartDateError(false);
												}
											}}
											autoOk={true}
											value={endDate}
											format={dateFormat}
											label='End Date'
											InputLabelProps={{ shrink: true }}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton>
															<EventIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</Box>
						</ThemeProvider>
					</Box>
				</Box>
				<Box display='flex'>
					<CountCard
						label='Total(hrs)'
						value={
							projectId && accumulatedTotalHours?.totalHours
								? isFloat(Number(accumulatedTotalHours?.totalHours))
									? formatDecimal(Number(accumulatedTotalHours?.totalHours), 1)
									: accumulatedTotalHours?.totalHours
								: '--'
						}
					/>
					<CountCard
						label='Billable(hrs)'
						value={
							projectId && accumulatedTotalHours?.billableHours
								? isFloat(Number(accumulatedTotalHours?.billableHours))
									? formatDecimal(
											Number(accumulatedTotalHours?.billableHours),
											1
									  )
									: accumulatedTotalHours?.billableHours
								: '--'
						}
					/>
					<div>
						<Box className={style.reducedValueContainer}>
							<Box className={classes.countLabel}>{'Non-billable(hrs)'}</Box>
							<Box className={classes.countValue}>
								{projectId && accumulatedTotalHours?.nonBillableHours
									? isFloat(Number(accumulatedTotalHours?.nonBillableHours))
										? formatDecimal(
												Number(accumulatedTotalHours?.nonBillableHours),
												1
										  )
										: accumulatedTotalHours?.nonBillableHours
									: '--'}
							</Box>
						</Box>
					</div>
				</Box>
			</Box>
			{projectId && startDate && endDate && (
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<Box>
							<Form
								onSubmit={() => {}}
								initialValues={{
									name: 'Total Hours',
									operator: totalHoursFilterOperator,
									value: totalHoursFilterValue,
									timesheet_type: timesheetType,
								}}
							>
								{({ form }) => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<Box className={commonStyle.header}>
												<Box display='flex'>
													{featureFlag?.userTeams && (
														<Box
															className={commonStyle.autoCompleteInputContainer}
														>
															<Typography className={commonStyle.label}>
																User Team
															</Typography>
															<AutocompleteSearch
																placeholder={'Search Team'}
																option={
																	userTeams && userTeams?.user_team
																		? userTeams?.user_team?.map(
																				(userTeam: {
																					id: string;
																					name: string;
																				}) => {
																					return {
																						id: userTeam?.id,
																						name: userTeam?.name,
																					};
																				}
																		  ) || []
																		: []
																}
																onChange={(id) => {
																	if (!id) {
																		setSelectedResourceIdList([]);
																		setSelectedResourceOptionValues([]);
																		resetTeamResourceFilter();
																		setSelectedUserTeam(null);
																		setUserTeamId(null);
																		return;
																	}
																	setUserTeamId(id);
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);
																	const currentTeam =
																		userTeams?.user_team?.find(
																			(team: {
																				id: string;
																				full_name: string;
																			}) => team?.id === id
																		);
																	setSelectedUserTeam(currentTeam);
																}}
																name={'team_id'}
																value={selectedUserTeam}
															/>
														</Box>
													)}
													<Box
														display='block'
														width='180px'
														marginRight={'13px'}
													>
														<Box className={styles.label}>Choose resource</Box>
														<Box
															className={commonStyle.autoCompleteInputContainer}
														>
															<Autocomplete
																multiple
																limitTags={0}
																value={
																	selectedResourceOptionValue &&
																	selectedResourceOptionValue?.length > 0
																		? selectedResourceOptionValue
																		: []
																}
																selectOnFocus={true}
																disablePortal
																fullWidth={false}
																onChange={(event, value, reason) => {
																	setPage(0);
																	if (!value) {
																		return;
																	}
																	const selectedIds = value
																		?.filter(
																			(resource: {
																				id: string;
																				name: string;
																			}) => resource.id !== 'All'
																		)
																		.map(
																			(resource: {
																				id: string;
																				name: string;
																			}) => resource?.id
																		);
																	if (
																		value.find(
																			(option) => option.id === 'All'
																		) &&
																		reason === 'select-option'
																	) {
																		setSelectedResourceOptionValues([
																			{ id: 'All', name: 'All' },
																			...assigneeRecommendations,
																		]);
																		const allResourceIds =
																			assigneeRecommendations?.map(
																				(resource: any) => resource?.id
																			);
																		setSelectedResourceIdList(allResourceIds);
																		return;
																	}
																	if (
																		value.find(
																			(option) => option.id === 'All'
																		) &&
																		reason === 'remove-option' &&
																		assigneeRecommendations?.length !==
																			selectedIds?.length
																	) {
																		const currentOptions = value?.filter(
																			(resource: {
																				id: string;
																				name: string;
																			}) => resource?.id !== 'All'
																		);
																		setSelectedResourceOptionValues(
																			currentOptions
																		);
																		const currentIds = currentOptions?.map(
																			(resource: {
																				id: string;
																				name: string;
																			}) => resource?.id
																		);
																		setSelectedResourceIdList(currentIds);
																		return;
																	}
																	if (
																		selectedIds?.length ===
																			assigneeRecommendations?.length &&
																		reason === 'select-option'
																	) {
																		setSelectedResourceOptionValues([
																			{ id: 'All', name: 'All' },
																			...assigneeRecommendations,
																		]);
																		const reporteeIds =
																			assigneeRecommendations?.map(
																				(resource: any) => resource?.id
																			);
																		setSelectedResourceIdList(reporteeIds);
																		return;
																	}
																	if (
																		selectedResourceOptionValue.find(
																			(option: { id: string; name: string }) =>
																				option?.id === 'All'
																		) &&
																		reason === 'remove-option'
																	) {
																		setSelectedResourceIdList([]);
																		setSelectedResourceOptionValues([]);
																		return;
																	}
																	const selectedId = value?.map(
																		(resource) => resource?.id
																	);
																	setSelectedResourceIdList(selectedId);
																	setSelectedResourceOptionValues(value);
																}}
																options={
																	assigneeRecommendations &&
																	assigneeRecommendations?.length > 0
																		? [
																				{ id: 'All', name: 'All' },
																				...assigneeRecommendations,
																		  ]
																		: []
																}
																disableCloseOnSelect={true}
																getLimitTagsText={getResourceFilterLimits}
																ChipProps={{ style: { display: 'none' } }}
																renderInput={(params) => (
																	<ThemeProvider
																		theme={customAutoCompleteSearchBarTheme}
																	>
																		<TextComponent {...params} label='' />
																	</ThemeProvider>
																)}
																renderOption={(option, { selected }) => (
																	<>
																		<Checkbox
																			icon={
																				<CheckBoxOutlineBlankIcon fontSize='small' />
																			}
																			checkedIcon={
																				<CheckBoxIcon fontSize='small' />
																			}
																			style={{ marginRight: 8 }}
																			checked={selected}
																			color='primary'
																		/>
																		<Tooltip
																			title={`${option?.name || '- -'}`}
																			placement='right'
																		>
																			<Typography
																				className={`${ellipsis.ellipsis}`}
																				style={{ fontSize: '12px' }}
																			>
																				{`${option?.name || '- -'}`}
																			</Typography>
																		</Tooltip>
																	</>
																)}
																getOptionLabel={(option) => `${option?.name}`}
																getOptionSelected={(option, value) =>
																	option.id === value.id
																}
															/>
														</Box>
													</Box>
													<Box
														display='flex'
														marginTop='24px'
														marginLeft='10px'
													>
														<Box width='90px'>
															<TextInput
																min={0}
																label={''}
																source='name'
																disabled
																onChange={(event) => {}}
															/>
														</Box>
														<Box
															width='101px'
															marginLeft='4px'
															marginRight='4px'
														>
															<ThemeProvider theme={filterMetricInputTheme}>
																<SelectInput
																	source='operator'
																	label={false}
																	fullWidth
																	choices={[
																		{ id: '>=', name: '(gte) >=' },
																		{ id: '<=', name: '(lte) <=' },
																	]}
																	onChange={(event) => {
																		if (!event) {
																			return;
																		}
																		setTotalHoursFilterOperator(
																			event?.target?.value
																		);
																		setPage(0);
																	}}
																/>
															</ThemeProvider>
														</Box>
														<Box width='80px'>
															<NumberInput
																min={0}
																label={''}
																source='value'
																onChange={(event) => {
																	if (!event) {
																		setTotalHoursFiltervalue(null);
																		return;
																	}
																	setTotalHoursFiltervalue(
																		event?.target?.value
																	);
																	setPage(0);
																}}
																validate={minValue(0)}
															/>
														</Box>
													</Box>
													{isOvertimeEnabled && (
														<Box display='block' marginLeft='4px'>
															<Box className={styles.label}>Task type</Box>
															<Box>
																<SelectInput
																	key={timesheetType}
																	source='timesheet_type'
																	label=''
																	choices={timesheetTypes}
																	onChange={(e: any) => {
																		setTimesheetType(e?.target?.value);
																		setPage(0);
																	}}
																/>
															</Box>
														</Box>
													)}
													{isTrackDayTypeEnabled && (
														<Box display='block' marginLeft='4px' width='150px'>
															<Box className={styles.label}>Day type</Box>
															<Box>
																<form
																	onSubmit={(event) => event.preventDefault()}
																>
																	<Autocomplete
																		multiple
																		limitTags={0}
																		value={
																			selectedDayTypeOptionValue &&
																			selectedDayTypeOptionValue?.length > 0
																				? selectedDayTypeOptionValue
																				: []
																		}
																		selectOnFocus={true}
																		disablePortal
																		fullWidth={false}
																		onChange={(event, value, reason) => {
																			setPage(0);
																			if (!value) {
																				return;
																			}
																			const selectedIds = value
																				?.filter(
																					(resource: {
																						id: string;
																						name: string;
																					}) => resource.id !== 'All'
																				)
																				.map(
																					(resource: {
																						id: string;
																						name: string;
																					}) => resource?.id
																				);
																			if (
																				value.find(
																					(option) => option.id === 'All'
																				) &&
																				reason === 'select-option'
																			) {
																				setSelectedDayTypeOptionValues([
																					{ id: 'All', name: 'All' },
																					...dayTypeOptions,
																				]);
																				const allResourceIds =
																					dayTypeOptions?.map(
																						(resource: any) => resource?.id
																					);
																				setSelectedDayTypeIdList(
																					allResourceIds
																				);
																				return;
																			}
																			if (
																				value.find(
																					(option) => option.id === 'All'
																				) &&
																				reason === 'remove-option' &&
																				dayTypeOptions?.length !==
																					selectedIds?.length
																			) {
																				const currentOptions = value?.filter(
																					(resource: {
																						id: string;
																						name: string;
																					}) => resource?.id !== 'All'
																				);
																				setSelectedDayTypeOptionValues(
																					currentOptions
																				);
																				const currentIds = currentOptions?.map(
																					(resource: {
																						id: string;
																						name: string;
																					}) => resource?.id
																				);
																				setSelectedDayTypeIdList(currentIds);
																				return;
																			}
																			if (
																				selectedIds?.length ===
																					dayTypeOptions?.length &&
																				reason === 'select-option'
																			) {
																				setSelectedDayTypeOptionValues([
																					{ id: 'All', name: 'All' },
																					...dayTypeOptions,
																				]);
																				const reporteeIds = dayTypeOptions?.map(
																					(resource: any) => resource?.id
																				);
																				setSelectedDayTypeIdList(reporteeIds);
																				return;
																			}
																			if (
																				selectedDayTypeOptionValue.find(
																					(option: {
																						id: string;
																						name: string;
																					}) => option?.id === 'All'
																				) &&
																				reason === 'remove-option'
																			) {
																				setSelectedDayTypeIdList([]);
																				setSelectedDayTypeOptionValues([]);
																				return;
																			}
																			const selectedId = value?.map(
																				(resource) => resource?.id
																			);
																			setSelectedDayTypeIdList(selectedId);
																			setSelectedDayTypeOptionValues(value);
																		}}
																		options={
																			dayTypeOptions &&
																			dayTypeOptions?.length > 0
																				? [
																						{ id: 'All', name: 'All' },
																						...dayTypeOptions,
																				  ]
																				: []
																		}
																		disableCloseOnSelect={true}
																		getLimitTagsText={getDayTypeFilterLimits}
																		ChipProps={{ style: { display: 'none' } }}
																		renderInput={(params) => (
																			<ThemeProvider
																				theme={customAutoCompleteSearchBarTheme}
																			>
																				<TextComponent {...params} label='' />
																			</ThemeProvider>
																		)}
																		renderOption={(option, { selected }) => (
																			<>
																				<Checkbox
																					icon={
																						<CheckBoxOutlineBlankIcon fontSize='small' />
																					}
																					checkedIcon={
																						<CheckBoxIcon fontSize='small' />
																					}
																					style={{ marginRight: 8 }}
																					checked={selected}
																					color='primary'
																				/>
																				<Box
																					style={{ fontSize: '12px' }}
																				>{`${option?.name}`}</Box>
																			</>
																		)}
																		getOptionLabel={(option) =>
																			`${option?.name}`
																		}
																		getOptionSelected={(option, value) =>
																			option.id === value.id
																		}
																	/>
																</form>
															</Box>
														</Box>
													)}
													{isDraftTimesheetEnabled && (
														<Box display='block' marginLeft='4px' width='170px'>
															<Box className={styles.label}>
																Timesheet status
															</Box>
															<Box>
																<form
																	onSubmit={(event) => event.preventDefault()}
																>
																	<Autocomplete
																		multiple
																		limitTags={0}
																		value={
																			selectedTimesheetStatusOptionValue &&
																			selectedTimesheetStatusOptionValue?.length >
																				0
																				? selectedTimesheetStatusOptionValue
																				: []
																		}
																		selectOnFocus={true}
																		disablePortal
																		fullWidth={false}
																		onChange={(event, value, reason) => {
																			setPage(0);
																			if (!value) {
																				return;
																			}
																			const selectedIds = value
																				?.filter(
																					(resource: {
																						id: string;
																						name: string;
																					}) => resource.id !== 'All'
																				)
																				.map(
																					(resource: {
																						id: string;
																						name: string;
																					}) => resource?.id
																				);
																			if (
																				value.find(
																					(option) => option.id === 'All'
																				) &&
																				reason === 'select-option'
																			) {
																				setSelectedTimesheetStatusOptionValues([
																					{ id: 'All', name: 'All' },
																					...timesheetStatusOptions,
																				]);
																				const allResourceIds =
																					timesheetStatusOptions?.map(
																						(resource: any) => resource?.id
																					);
																				setSelectedTimesheetStatusIdList(
																					allResourceIds
																				);
																				return;
																			}
																			if (
																				value.find(
																					(option) => option.id === 'All'
																				) &&
																				reason === 'remove-option' &&
																				timesheetStatusOptions?.length !==
																					selectedIds?.length
																			) {
																				const currentOptions = value?.filter(
																					(resource: {
																						id: string;
																						name: string;
																					}) => resource?.id !== 'All'
																				);
																				setSelectedTimesheetStatusOptionValues(
																					currentOptions
																				);
																				const currentIds = currentOptions?.map(
																					(resource: {
																						id: string;
																						name: string;
																					}) => resource?.id
																				);
																				setSelectedTimesheetStatusIdList(
																					currentIds
																				);
																				return;
																			}
																			if (
																				selectedIds?.length ===
																					timesheetStatusOptions?.length &&
																				reason === 'select-option'
																			) {
																				setSelectedTimesheetStatusOptionValues([
																					{ id: 'All', name: 'All' },
																					...timesheetStatusOptions,
																				]);
																				const reporteeIds =
																					timesheetStatusOptions?.map(
																						(resource: any) => resource?.id
																					);
																				setSelectedTimesheetStatusIdList(
																					reporteeIds
																				);
																				return;
																			}
																			if (
																				selectedTimesheetStatusOptionValue.find(
																					(option: {
																						id: string;
																						name: string;
																					}) => option?.id === 'All'
																				) &&
																				reason === 'remove-option'
																			) {
																				setSelectedTimesheetStatusIdList([]);
																				setSelectedTimesheetStatusOptionValues(
																					[]
																				);
																				return;
																			}
																			const selectedId = value?.map(
																				(resource) => resource?.id
																			);
																			setSelectedTimesheetStatusIdList(
																				selectedId
																			);
																			setSelectedTimesheetStatusOptionValues(
																				value
																			);
																		}}
																		options={
																			timesheetStatusOptions &&
																			timesheetStatusOptions?.length > 0
																				? [
																						{ id: 'All', name: 'All' },
																						...timesheetStatusOptions,
																				  ]
																				: []
																		}
																		disableCloseOnSelect={true}
																		getLimitTagsText={
																			getTimesheetStatusFilterLimits
																		}
																		ChipProps={{ style: { display: 'none' } }}
																		renderInput={(params) => (
																			<ThemeProvider
																				theme={customAutoCompleteSearchBarTheme}
																			>
																				<TextComponent {...params} label='' />
																			</ThemeProvider>
																		)}
																		renderOption={(option, { selected }) => (
																			<>
																				<Checkbox
																					icon={
																						<CheckBoxOutlineBlankIcon fontSize='small' />
																					}
																					checkedIcon={
																						<CheckBoxIcon fontSize='small' />
																					}
																					style={{ marginRight: 8 }}
																					checked={selected}
																					color='primary'
																				/>
																				<Box
																					style={{ fontSize: '12px' }}
																				>{`${option?.name}`}</Box>
																			</>
																		)}
																		getOptionLabel={(option) =>
																			`${option?.name}`
																		}
																		getOptionSelected={(option, value) =>
																			option.id === value.id
																		}
																	/>
																</form>
															</Box>
														</Box>
													)}
												</Box>
												<Box className={style.exportButtonDiv}>
													<ExportButtonWithDropDown
														onChange={(index: number) => {
															generateCSV(index);
														}}
														isDisabled={!timeSheetTableList?.length}
														options={exportTimesheetReportUsersOptions}
													/>
												</Box>
											</Box>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Box>
						{!isGeneralAndProjectTimesheetEntries &&
						!isGeneralAndProjectTimesheetOvertimeEntries &&
						!isGeneralAndProjectUnsubmittedAndDraftStatusEntries ? (
							<CardContent>
								<Box className={commonStyle.tableContainer}>
									{timeSheetTableList && timeSheetTableList?.length > 0 ? (
										<TableContainer>
											<Table stickyHeader>
												<TableHead>
													<TableRow>
														<CustomTableCell className={commonStyle.headerCell}>
															PROJECT NAME
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															RESOURCE
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															TOTAL HOURS
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															BILL RATE
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															TOTAL BILL RATE
														</CustomTableCell>
													</TableRow>
												</TableHead>

												<TableBody>
													{timeSheetTableList &&
														timeSheetTableList?.length > 0 &&
														timeSheetTableList
															?.slice(
																page * rowsPerPage,
																page * rowsPerPage + rowsPerPage
															)
															.map(
																(timeSheet: any) =>
																	timeSheetTableList?.length > 0 && (
																		<TableRow hover={true} key={timeSheet?.id}>
																			<CustomTableCell>
																				<Tooltip
																					title={`${timeSheet?.projectName}`}
																				>
																					<Typography
																						className={`${style.dataCell} ${ellipsis.ellipsis}`}
																					>
																						{timeSheet?.projectName}
																					</Typography>
																				</Tooltip>
																			</CustomTableCell>
																			<CustomTableCell>
																				<Tooltip
																					title={`${timeSheet?.resourceName}`}
																				>
																					<Typography
																						className={`${style.dataCell} ${ellipsis.ellipsis}`}
																					>
																						{timeSheet?.resourceName}
																					</Typography>
																				</Tooltip>
																			</CustomTableCell>
																			<CustomTableCell>
																				{isFloat(timeSheet?.totalHours)
																					? Number(
																							timeSheet?.totalHours
																					  )?.toFixed(1)
																					: timeSheet?.totalHours || '--'}
																			</CustomTableCell>
																			<CustomTableCell>
																				{`${
																					timeSheet?.billRate
																						? timeSheet?.currencySymbol || ''
																						: ''
																				}${
																					formatDecimal(
																						timeSheet?.billRate,
																						2
																					) || '--'
																				}`}
																			</CustomTableCell>
																			<CustomTableCell>
																				{`${
																					timeSheet?.totalBillRate
																						? timeSheet?.currencySymbol || ''
																						: ''
																				}${
																					formatDecimal(
																						timeSheet?.totalBillRate,
																						2
																					) || '--'
																				}`}
																			</CustomTableCell>
																		</TableRow>
																	)
															)}
												</TableBody>
											</Table>
											<Box display='flex' justifyContent='flex-end'>
												<TablePagination
													rowsPerPageOptions={[5, 10, 25]}
													count={timeSheetTableList?.length || 0}
													rowsPerPage={rowsPerPage}
													page={page}
													onPageChange={handleChangePage}
													onChangeRowsPerPage={handleChangeRowsPerPage}
												/>
											</Box>
										</TableContainer>
									) : (
										<Box className={commonStyle.noData}>
											<Typography className={commonStyle.noDataLabel}>
												No data found
											</Typography>
										</Box>
									)}
								</Box>
							</CardContent>
						) : (
							<Loading />
						)}
					</Card>
				</ThemeProvider>
			)}
		</>
	);
};
